import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../Context/UserContext';
import axios from 'axios';
import HeaderAcc from './HeaderAcc';
import DataByCategoryHook from '../../Logic/Categories/DataByCategoryHook';
import { Link } from 'react-router-dom';

export default function HeaderDropdownOne({ openHeaderTwo, setOpenHeaderTwo ,closeDp}) {

    const { baseURL } = useContext(UserContext);
    const [categories, setCategories] = useState([]);
    const [hoveredCategory, setHoveredCategory] = useState(false);
    const [checkChildren, setCheckChildern] = useState(null);
    const [hoveredImg, setHoveredImg] = useState(null);

    useEffect(() => {

        axios.get(`${baseURL}/categories?lang=ar`)
            .then((response) => {
                setCategories(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })



    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            setHoveredCategory(categories[0]);
        }
    }, [categories]);

    const closeWrapper = () => {
        setOpenHeaderTwo(false);
        document.querySelector(".overlayLight").style.display = "none";

    }
    let head;
    if(localStorage.getItem("headerRoute")){
         head=JSON.parse(localStorage.getItem("headerRoute"));
    }
    // const headsRef = useRef([]);
    
   
    
    // useEffect(() => {
    
    //     headsRef.current.forEach((el) => {
    //         console.log(el);
    //         if (el?.id == head[0]) {
    //             el.classList.add("activeHead")
    //         }
    //     });
    // }, [openHeaderTwo]);
    const headsRef = useRef([]);

    useEffect(()=>{

        // categories.map((cate)=>{
        //     if(cate.id==hoveredCategory.id){
        //         console.log("cate",cate);
        //     }
            
        // })
        headsRef.current.forEach((el) => {
            console.log(el.id);
            if (el?.id == hoveredCategory.id) {
                headsRef.current.forEach((el) =>el.classList.remove("activeHead"))
                el.classList.add("activeHead")
                const img = el.querySelector("img"); // get the <img> inside this element
                img.src = hoveredCategory.image_icon_hover;

            }
        });
        if(hoveredCategory.childern){
            setCheckChildern(true)
        }else{
            setCheckChildern(false)

        }
        console.log(hoveredCategory);
    },[openHeaderTwo,hoveredCategory])
    return (
        <>
            {openHeaderTwo && (
                <div className="header-bottom-right-dropInfo" onMouseLeave={()=>closeDp()}>
                    <div className="header-bottom-right-dropInfo-left" >

                        {hoveredCategory && hoveredCategory.id&&(
                            <>
                            {/* <p className='header-bottom-right-dropInfo-topic-head'>   {hoveredCategory.name} </p> */}
                            
                           

                        </>
                        )}

                        {hoveredCategory && <HeaderAcc categories={hoveredCategory} closeWrapper={closeWrapper} />}
                        {
                            hoveredCategory.children?.length > 0?<a href={`/category/${hoveredCategory.id}`} className='header-bottom-right-dropInfo-topic-headunderline ' >عرض الكل</a> :""
                        }
                        

                    </div>




                    <div className="header-bottom-right-dropInfo-right">
                        {categories && categories.map((category,index) => (

                            <Link className="header-bottom-right-dropInfo-topic" key={category.id}
                            id={category.id}
                                to={`/category/${category.id}`}
                                onMouseEnter={() => {
                                    setHoveredCategory(category);
                                    setHoveredImg(true);
                                }}
                                onClick={() => {
                                    closeWrapper();
                                    document.querySelector(".overlayLight").style.display = "none";
                                }}
                                ref={(el) => {
                                    if (el) {
                                      headsRef.current[index] = el;
                                    }
                                  }}
                                >
                                <p className=''>{category.name}</p>
                                <img   src={hoveredCategory?.id === category.id ? category.image_icon_hover : category.image_icon}
 alt="" className='header-bottom-right-img' />
                            </Link>

                        ))}

                    </div>
                </div>
            )}
        </>
    )
}
