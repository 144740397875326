import axios from 'axios';
import React, { createContext, useState,useEffect } from 'react';

export const UserContext = createContext();

export default function UserProvider({ children }) {
    const baseURL = 'https://popeshenoudatest.msol.dev/api';
    const [categoryId, setCategoryId] = useState({
        id: '',
        name: ''
    });
    const [audioURL, setAudioURL] = useState({
        url: '',
        image: '',
        name: '',
        bookmarkshow: '',
        id: ''

    });
    const [audioId, setAudioId] = useState(null);
    const token = localStorage.getItem('token');
    const [recent, setRecent] = useState('');


    const [sideMenu, setSideMenu] = useState('bookmark');

    const fallbackImage = `${process.env.PUBLIC_URL}/assets/header-papa.png`;

    // Set fallback image if image fails to load
    const handleImageError = (e) => {
      e.target.src = fallbackImage;
    };

    const [pag2, setPag2] = useState(null);
    const [page2, setPage2] = useState(1);

    const fetchAudio = () => {
        axios.get(`${baseURL}/get-latest-audio?lang=ar&page=${page2}`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                setRecent(response.data.data);
                setPag2(response.data.pagination);

                const currentAudio = response.data.data.find(audio => audio.id === audioURL.id);
                if (currentAudio) {
                    setAudioURL(prevAudioURL => ({
                        ...prevAudioURL,
                        bookmarkshow: currentAudio.bookmarkshow
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(()=>{
        fetchAudio()
    },[page2]);

  
    const [visibleItems2, setVisibleItems2] = useState([]); 

    const [currentPageLately, setCurrentPageLately] = useState(1); // Page for "lately"
    const [currentPageBookmarks, setCurrentPageBookmarks] = useState(1); // Page for "bookmarks"
    const [hasMoreLately, setHasMoreLately] = useState(true);
    const [hasMoreBookmarks, setHasMoreBookmarks] = useState(true);

    const fetchbook = () => {
        // Reset existing data
        // setVisibleItems2([]);
        setCurrentPageBookmarks(1);
      
        let allItems = [];
        let currentPage = 1;
      
        const fetchAllPages = () => {
          axios
            .get(`${baseURL}/bookmarks`, {
              params: { media_type: '5', paginate: 'true', page: currentPage },
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              const newItems = response.data.bookmarks || [];
              const totalPages = response.data.pagination.totalPages || 1;
      
              // Add new items to the aggregated list
              allItems = [...allItems, ...newItems];
      
              // Check if more pages need to be fetched
              if (currentPage < totalPages) {
                currentPage += 1;
                fetchAllPages(); // Fetch the next page
              } else {
                // Once all pages are fetched, update the state
                setVisibleItems2(allItems);
                setHasMoreBookmarks(false); // Disable infinite scroll
              }
            })
            .catch((error) => {
              console.error("Error fetching bookmarks:", error);
            });
        };
      
        fetchAllPages(); // Start fetching all pages
      };
      
    
    const [visibleItems, setVisibleItems] = useState([]);

    const fetchlast = () => {
        // Reset existing data
        // setVisibleItems([]);
        setCurrentPageLately(1);
      
        let allItems = [];
        let currentPage = 1;
      
        const fetchAllPages2 = () => {
          axios
            .get(`${baseURL}/last_listings`, {
              params: { paginate: 'true', page: currentPage },
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              const newItems = response.data.last_listings || [];
              const totalPages = response.data.pagination?.totalPages || 1;
      
              // Add new items to the aggregated list
              allItems = [...allItems, ...newItems];
      
              // Check if more pages need to be fetched
              if (currentPage < totalPages) {
                currentPage += 1;
                fetchAllPages2(); // Fetch the next page
              } else {
                // Once all pages are fetched, update the state
                setVisibleItems(allItems);
                setHasMoreLately(false); // Disable infinite scroll
              }
            })
            .catch((error) => {
              console.error("Error fetching last listings:", error);
            });
        };
      
        fetchAllPages2(); // Start fetching all pages
      };
      




    const [clicked, setClicked] = useState('');
    const [clickedFliter, setClickedFilter] = useState('');

    const [pag, setPag] = useState(null);
    const [page, setPage] = useState(1);

    const getClickedCategory = (p) => {
            if(localStorage.getItem("filterIds")){
              let fi=JSON.parse(localStorage.getItem("filterIds"))
              let fb=JSON.parse(localStorage.getItem("filterValues"))
              getAudioFilter(fi,fb,1)
            }else{
              console.log("paaaaaaaaaaa",page,categoryId.id);
              axios.get(`${baseURL}/get-audio-by-category?lang=ar&page=${p}&category_id=${categoryId.id}`,
              { headers: { 'Authorization': `Bearer ${token}` }}
          )
              .then((response) => {
                  setClicked(response.data.data);
                  setPag(response.data.pagination);
                  setClickedFilter(response.data);
                  const currentAudio2 = response.data.data.media.find(audio => audio.id === audioURL.id);
                  if (currentAudio2) {
                      setAudioURL(prevAudioURL => ({
                          ...prevAudioURL,
                          bookmarkshow: currentAudio2.bookmarkshow
                      }));
                  }
              })
              .catch((error) => {
                  console.log(error);
              });
            }

        }
    



   
    // useEffect(() => {
    //     if (categoryId.id) {
    //       console.log("ccc",categoryId.id);
    //     getClickedCategory(1);
    // }
    // }, [categoryId]);



const getAudioFilter = (id,e,page)=>{
  console.log("page:",page);

    axios.get(`${baseURL}/get-audio-by-category?page=${page}&lang=ar&category_id=${categoryId.id}`,{
        params:{filter_ids:id,values:e},
         headers: { 'Authorization': `Bearer ${token}` }
    }
    )
        .then((response) => {
          setClicked(response.data.data);
          setPag(response.data.pagination);
           
        })
        .catch((error) => {
            console.log(error);
        });
}


    const [audioList, setAudioList] = useState([]);
    const [currentAudioIndex, setCurrentAudioIndex] = useState(null);

    const [isUserInitiated, setIsUserInitiated] = useState(false); // Tracks if audio was clicked by user


    const SendAudio = (url, id, image, name, book, audioList, index) => {
        setAudioURL({
          url: url,
          image: image,
          name: name,
          bookmarkshow: book,
          id: id
        });

        setIsUserInitiated(true);
        sendAudioId(id)
        setAudioId(id);
        setAudioList(audioList);
        setCurrentAudioIndex(index);
      }
    
      const sendAudioId = (id) => {
        const token = localStorage.getItem('token');
        axios.post(`${baseURL}/last_listings`,
          { media_id: id }, { headers: { 'Authorization': `Bearer ${token}` } })
          .then((response) => {
            fetchlast();
            
          })
          .catch((error) => {
            console.log(error);
          })
      }

    return (
        <UserContext.Provider value={{ baseURL, categoryId, setCategoryId,setClicked, audioURL, setAudioURL, audioId, setAudioId, fetchAudio, recent, handleImageError, sideMenu, setSideMenu, fetchbook ,clicked,getClickedCategory,visibleItems2,setVisibleItems2,audioList, setAudioList,currentAudioIndex, setCurrentAudioIndex,SendAudio,visibleItems,fetchlast,setVisibleItems,clickedFliter,getAudioFilter,isUserInitiated, setIsUserInitiated,pag, setPag ,setPage,pag2, setPag2 ,setPage2,hasMoreBookmarks,hasMoreLately
        }}>
            {children}
        </UserContext.Provider>
    );
}
