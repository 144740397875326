import React, { useState } from 'react'
import { ReactReader } from 'react-reader';
import { Link } from 'react-router-dom';

export default function OneBook({ info, src, book ,book_id}) {
    const [showReader, setShowReader] = useState(false);
 
    const openReader = () => {
        if (book) {
            setShowReader(true);
        } else {
            alert("No book URL provided.");
        }
    };
    const getBook = () => {
        if (book) {
            const bookData = {
                info: info,
                book: book,
                book_id:book_id
            };
    
            // Store the book data in localStorage as a JSON string
            localStorage.setItem("book", JSON.stringify(bookData));
        }
    };
    // const getBook = () => {
    //     if (book && book_id) {
    //         const bookData = {
    //             info: info,
    //             book: book,
    //             book_id: book_id
    //         };
    //         localStorage.setItem("book", JSON.stringify(bookData));
    //     } else {
    //         console.error("Cannot store book data: book or book_id is missing", { book, book_id });
    //         alert("Book cannot be opened due to missing data.");
    //     }
    // };
    const sampleBookUrl = "https://s3.amazonaws.com/moby-dick/moby-dick.epub";


    return (
        <div className="books-section-one-books-info">
            <div className="books-section-one-info">
                <div className="books-section-one-stars">
                    <img src="/assets/star.png" alt="" />
                    <img src="/assets/star.png" alt="" />
                    <img src="/assets/star.png" alt="" />
                </div>
                <div className='books-section-one-save'><p><Link to="/displayBook" onClick={getBook} style={{ cursor:"pointer" }}>{info}</Link></p></div>
                <p className='books-section-one-save2'><Link to="/displayBook" onClick={getBook} style={{ cursor:"pointer" }}>{info}</Link> </p>
                <div className="book-links">
                     <Link to="/displayBook" onClick={getBook} 
                     className="download-link books-section-one-download books-section-one-download-two">
                        قراءة 
                    </Link>
                    <a href={book} className="download-link books-section-one-download"> تحميل </a>
                 
                </div>
                {/* <div className="book-links">
                    {book_id ? (
                        <Link to="/displayBook" onClick={getBook} className="download-link books-section-one-download books-section-one-download-two">
                            قراءة 
                        </Link>
                    ) : (
                        <span className="download-link disabled">قراءة (غير متاح)</span>
                    )}
                    <a href={book} className="download-link books-section-one-download"> تحميل </a>
                </div> */}


            </div>
            <Link to="/displayBook">
            <img
                src={src}
                alt=""
                className="books-book"
                onClick={getBook}
                onError={(e) => {
                    e.target.onerror = null; // Prevents looping if default image also fails
                    e.target.src = "/assets/default/books/Book - inside.png"; // Replace with your fallback image path
                }}
                style={{ cursor: "pointer" }}
                />            
            </Link>


            {showReader && (
                <div className="reader-container">
                       <button onClick={() => setShowReader(false)}>Close Reader</button>
                    <ReactReader
                        url={book} // Replace `book` temporarily with `sampleBookUrl`
                        title={info}
                        showToc={true}
                        getRendition={(rendition) => {
                            rendition.themes.default({
                                '::selection': {
                                    background: 'rgba(255,255,0, 0.3)'
                                }
                            });
                        }}
                        onClose={() => setShowReader(false)}
                    />
                </div>
            )}
        </div>
    )
}
