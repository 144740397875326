import React from 'react'
import { Link } from 'react-router-dom'

export default function GalleryImg({src,info,children}) {
    return (
        <Link 
        to="/displaygallery"
        state={{ extraContent: children, name:info}} 
        className="gallery-img-cont"
    >
            <img src={src} alt="gallery img" className='gallery-img' />
            <p className='gallery-img-p'>{info}</p>
        </Link>
    )
}
