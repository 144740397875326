import React, { useEffect, useRef, useState } from 'react'
import { toPng } from "html-to-image";
import { OverlayPanel } from 'primereact/overlaypanel';
import SayingWriteCardOverlay from './SayingWriteCardOverlay';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SayingShareContainer from './SayingShareContainer';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';

export default function SayingWriteCard({ img, desc, name, cate ,checked}) {
  const op = useRef(null);


  // get current path
   const location = useLocation();
   const currentPath = `${window.location.origin}${location.pathname}${location.search}`;
  
  // get image from on Drive
  function convertOneDriveLink(onedriveUrl) {
    try {
      const url = new URL(onedriveUrl);
      const fileId = url.searchParams.get('id');
      if (!fileId) {
        console.error("Invalid OneDrive URL or 'id' parameter missing.");
        return null;
      }
      const directLink = `https://popeshenoudaacademy-my.sharepoint.com/personal/msoul_zvf-eg_org/_layouts/15/download.aspx?SourceUrl=${encodeURIComponent(
        fileId
      )}`;
      return directLink;
    } catch (error) {
      console.error("Error processing OneDrive URL:", error.message);
      return null;
    }
  }
  const directLink = img ? convertOneDriveLink(img) : null;


  // Copy Data
  const copyData = async () => {
    try {
        const plainTextDesc = desc.replace(/<[^>]+>/g, ""); 
        const message = `\n\n"${plainTextDesc}"\n\n- ${name} (${cate})`;
        await navigator.clipboard.writeText(message);
        toast.success("تم النسخ بنجاح");
    } catch (error) {
        toast.error("عذرا ..قم بالمحاولة لاحقا");
        console.error("Failed to copy:", error);
    }
};
  // Download Card
  const cardRef = useRef(null);
  const buttonDownload = useRef();
  const generateImageWithoutButton = () => {
    if (cardRef.current) {
      // Hide the button
  
      // Generate the image
      toPng(cardRef.current)
        .then((dataUrl) => {
          // Create a link element to download the image
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${name || "card"}.png`;
          link.click();
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        })
        .finally(() => {
          // Ensure the button is re-displayed
        });
    }
  };

  // more to read 
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descRef = useRef(null);
  const [isOverlayCard, setOverlayCard] = useState(false);
  const [isOverlayDots, setIsOverlayDots] = useState(false);
  const [isOverlayBlockShare, setOverlayBlockShare] = useState(false);

  const handleCardOverlay=()=>{
    setOverlayCard(true)
    const header = document.querySelector('.header');
    header.style.zIndex = '1000';
    // op.current.toggle(false);


  }
  const handleCardOverlayClose=()=>{
    setOverlayCard(false)
  }

  useEffect(() => {
    // Check if the description is overflowing
    if (descRef.current) {
      setIsOverflowing(
        descRef.current.scrollHeight > descRef.current.clientHeight
      );
    }
  }, [desc]);

  
  const handleOverlayToggle = (e) => {
      op.current.toggle(e);
    setIsOverlayDots((prev) => !prev);
    const header = document.querySelector('.header');
    header.style.zIndex = '10000';
    setTimeout(() => {
      const overlay = op.current?.getElement?.();
      if (overlay) {
        const currentTop = parseFloat(overlay.style.top) || overlay.offsetTop;
        const currentLeft = parseFloat(overlay.style.left) || overlay.offsetLeft;
    
        overlay.style.top = `${currentTop + 20}px`;
        overlay.style.left = `${currentLeft - 11}px`;
        overlay.classList.add("shareDp");
      }
    }, 0); // wait for the DOM update after toggle

};

const openShareBlock = () => {
    document.body.classList.add("no-scroll");
    setOverlayBlockShare(true);
    setIsOverlayDots(false)
    op.current.toggle(false);


};
const closeShareBlock=()=>{
  document.body.classList.remove("no-scroll");
  setOverlayBlockShare(false)
}

  return (
    <div className="sayingWriteCard" ref={cardRef}   >
      {isOverlayDots&&<div className='dots overlay' onClick={handleOverlayToggle} ></div>}
      <div className="sayingWriteCardImg">
      <img src={checked?img:"/assets/default/written/insideWritten.png"} />
      </div>
      <div className="sayingWriteCardContent">
        <img
          className="signQuote saywImg"
          src="/assets/media/writings/Sayings/q1.png"
          alt="img"
        />
       <div>
      <p
        ref={descRef}
        className={`sayingWriteCardDesc ${isExpanded ? "expanded" : ""}`}
        dangerouslySetInnerHTML={{ __html: desc }}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "unset" : 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      ></p>
      <div style={{clear:"both"}}></div>

      {
          <div className='moreWriteCard'>
          <img src='/assets/moreWC.png' alt='writeCard' onClick={handleCardOverlay} />
          {
            isOverlayCard&& <SayingWriteCardOverlay closeOverlay={handleCardOverlayClose} img={checked?img:"/assets/default/written/insideWritten.png"} desc={desc} name={name} cate={cate}/>
          }
        </div>
      }
   
    </div>
        <div style={{ clear: "both" }}></div>
        <div className="sayingWriteCardSign">
            <div>
              <h3 className="sayingWriteCardSignName"> {name} </h3>
              <span className="sayingWriteCardCategory">{cate}</span>
            </div>
            <img  src='/assets/sign.png' className='sayingWriteCardSignImg'/>
        </div>
        <img src="/assets/dots.png" alt=""   onClick={handleOverlayToggle} className='dotsWrite' />
        <OverlayPanel ref={op}>
         <button className="shareButton btnAction" onClick={openShareBlock} >
          <span>مشاركة</span>
          <img src='/assets/Share.png'/>
        </button> 
        <img src='/assets/line.png' alt='line'/>
        <button className="shareButton btnAction" onClick={copyData}>
           <span>نسخ</span>
          <img src='/assets/copy.png'/>
        </button> 
        {/* <img src='/assets/line.png' alt='line'/>
         <button className="#downloadImage downloadButton btnAction" onClick={generateImageWithoutButton} ref={buttonDownload} style={{cursor:"pointer"}}>
          <span>تحميل</span>
          <img src='/assets/download.png'/>
        </button>   */}
        </OverlayPanel>
        {
          isOverlayBlockShare&& 
          ReactDOM.createPortal(
            <SayingShareContainer closeOverlay={closeShareBlock} currentPath={currentPath} img={img} desc={desc} name={name} cate={cate} height="70%"  />,
            document.getElementById('portal-root')
        )
         
        }

      </div>
    </div>
  );
}
