import React, { useEffect, useState } from 'react'

export default function TopicRight({data}) {
  const [shouldContain, setShouldContain] = useState(false);
  
  useEffect(() => {
      if (data[0]?.image) {
          const img = new Image();
          img.src = data[0].image;
          img.onload = () => {
              if (img.width < 900) {
                  setShouldContain(true);
              }
          };
      }
  }, [data[0]]);
  console.log(data);
  return (
    <div className="topic-right-side">
        <img src={data[0]&&data[0].sharepoint_image?data[0].image:"/assets/default/happen/outSide.png"}  alt="" className={`visits-head-img ${shouldContain ? 'vistis-object-fit-contain' : ''}`} />
        {shouldContain && (
                                <img className="visits-head-img-blur"src={data[0].sharepoint_image?data[0].image:"/assets/default/happen/outSide.png"}  />
                            )}
        <div className="topic-right-ps" dangerouslySetInnerHTML={{__html:data[0]?data[0].event:""}}></div>
  
    </div>
  )
}
