import React from 'react'
import SayingWriteCard from '../SayingWriteCard'
import MediaHeader from '../../../MediaHeader'
import './SayingFilterContainer.css'
import SayingsPhotoCard from '../SayingsPhotoCard'
import { useParams } from 'react-router-dom'
import SayingsDetailsHook from '../../../../../Logic/Media/Writings/Sayings/SayingsDetailsHook'
import Paginate from '../../../../Utility/Paginate'
import Spinner from '../../../../Utility/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
export default function SayingFilterContainer() {
  const {id}=useParams();
  const   [sayingsWrittenData,sayingsPhotoData,sayingsWrittenFilter,sayingsPhotoFilter,pageCountSP,pageCountSW,sayingsPhotoAttrs,sayingsWrittenAttrs,handleChangePageSW,handleChangePageSP,getData,notFound,notFoundWrite,activePage]=SayingsDetailsHook();
  return (
    <div className='sayingFilterContainer'>
      {
        id==11?
        <>
          <div className='Container'>   
                <MediaHeader title="اقوال مكتوبة" />
                <div className='sayingsWriteItems'>
                  {
             sayingsWrittenData[0]?sayingsWrittenData[0].map((item)=>{
                return(
                  <SayingWriteCard img={item.image}  desc={item.quote} name="البابا شنوده الثالث" cate={item.name} key={item.id} checked={item.sharepoint_image} />
              )
            }):<Spinner />
          }
                </div>
                <p  className='noResult' ref={notFoundWrite} style={{display:"none"}}>لا يوجد نتائج</p> 

          </div>
         { pageCountSW!=0&&<Paginate pageCount={pageCountSW} onPress={handleChangePageSW} activePage={activePage}/>}
        </>    
        :
        <>

          <div className='Container'>
            <MediaHeader title="صور اقوال" />
            <div className='sayingsPhotoItems'>
                {
                 sayingsPhotoData && Array.isArray(sayingsPhotoData[0])?sayingsPhotoData[0].map((item)=>{
                  return(
                    <SayingsPhotoCard src={item.image?item.image:'/assets/media/writings/Sayings/1.png'} key={item.id}/>
                  )
                }):<Spinner />
              }
            </div>
            <p  className='noResult' ref={notFound} style={{display:"none"}}>لا يوجد نتائج</p>

          </div>
          {pageCountSP!=0&&<Paginate pageCount={pageCountSP} onPress={handleChangePageSP} activePage={activePage}/>}
        </>
      }
   
            
        
          
      <ToastContainer />
       
      </div>

  )
}
