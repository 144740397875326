import React from 'react'
import './SayingsWrite.css'
import '../../Responsive/SayingsWrittenRes.css'

import SayingWriteCard from './SayingWriteCard'
import MediaHeader from '../../MediaHeader'
import SayingsHook from '../../../../Logic/Media/Writings/Sayings/SayingsHook'
import Spinner from '../../../Utility/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
export default function SayingsWriteContainer() {

  const [sayingsWrittenDataLimited,sayingsPhotoDataLimited]=SayingsHook();
  return (
    <div className='sayingsWriteContainer' >
      <div className='Container'>
       <MediaHeader title="اقوال مكتوبة"  src="/media/11"/>
        <div className='sayingsWriteItems'>
          {
            sayingsWrittenDataLimited && Array.isArray(sayingsWrittenDataLimited[0])?sayingsWrittenDataLimited[0].map((item)=>{
              return(
                <SayingWriteCard img={item.image}  desc={item.quote} name={item.ref} cate={item.name} key={item.id} checked={item.sharepoint_image}  />
              )
            }):<Spinner />
          }

        </div>
      </div>
      <ToastContainer /> 

    </div>
  )
}
