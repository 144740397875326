
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllArticlesForOneAction, getArticlesAttrForOneAction, getArticlesFilterForOneAction } from '../../../../redux/actions/Media/Writings/ArticlesActions';
export default function ArticlesForOneHook(newspaper) {
    const dispatch=useDispatch();
    const notFound=useRef()
    const [activePage,setActivePage]=useState(1);


//authReducer
const getData=async()=>{
    setActivePage(1)
    if (localStorage.getItem("articlesNewsAttr")) {
        const cateID = JSON.parse(localStorage.getItem("articlesNewsAttr"));
        await dispatch(getArticlesAttrForOneAction(newspaper,4,1,cateID))
    } else {
        await dispatch(getAllArticlesForOneAction(newspaper,4,1));
    }

    
}
useEffect(()=>{
    getData();
},[localStorage.getItem("articlesNewsAttr")])
useEffect(()=>{
    let allPages=document.querySelectorAll(".paginateGeneral .page-item")
    if(allPages[1]){
        allPages[1].classList.add("active");

    }

},[localStorage.getItem("articlesNewsAttr")])
// useEffect(()=>{
// getData();

// },[localStorage.getItem("articlesNewsAttr")])
const res=useSelector(state=> state.ArticlesReducer.articlesForOneData);
const resFilter=useSelector(state=> state.ArticlesReducer.articlesAttrForOne);

let articlesForOneData=[];
let filterGroup=[]
let attrGroup=[]
try {
    if(localStorage.getItem("articlesNewsAttr")){
        if(resFilter){
            if(resFilter.data){
                articlesForOneData.push(resFilter.data)
                if(resFilter.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(resFilter.addetionals){
                filterGroup=[...resFilter.addetionals.filterable_categories]
                attrGroup=[...resFilter.addetionals.filterable_attrebutes]
            }
        }

    }else{
        if(res){
            if(res.data){
                articlesForOneData.push(res.data)
                if(res.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(res.addetionals){
                filterGroup=[...res.addetionals.filterable_categories]
                attrGroup=[...res.addetionals.filterable_attrebutes]
            }
        }
    }

} catch (e) {
    console.log(e);
}

let pageCount=0;

try {
    if(localStorage.getItem("articlesNewsAttr")){
        if(resFilter){
            if(resFilter.pagination){
                pageCount=Math.ceil(resFilter.pagination.total/resFilter.pagination.per_page)
            }
        }
    }else{
        if(res){
            if(res.pagination){
                pageCount=Math.ceil(res.pagination.total/res.pagination.per_page)
            }
        }
    }

} catch (error) {}


const handleChangePage= async(count)=>{
    setActivePage(count)
    if(localStorage.getItem("articlesNewsAttr")){
        let cateID=JSON.parse(localStorage.getItem("articlesNewsAttr"))
        await dispatch(getArticlesAttrForOneAction(newspaper,4,count,cateID))
    }else{
        await dispatch(getAllArticlesForOneAction(newspaper,4,count))

    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 
}

return [articlesForOneData,pageCount,filterGroup,attrGroup,handleChangePage,getData,notFound,activePage]
}


