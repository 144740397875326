import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getHomeDataAction } from '../../redux/actions/Home/HomeActions';
export default function HomeHook() {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [loading,setLoading]=useState(true);

//authReducer
const getData=async()=>{
    setLoading(true)
    await dispatch(getHomeDataAction())
    setLoading(false)

}
useEffect(()=>{
    getData();
},[])
const res=useSelector(state=> state.HomeReducer.homeData);

let mediaData=[];
let total;
try {
    if(res.data){
        if(res.data){
            mediaData.push(res.data.media);
            total=res.data.count_of_written;
        }
    }
} catch (e) {
    console.log(e);
}

let meditationsData =[];
try {
    if(res.data){
        if(res.data.video){
            meditationsData.push(res.data.video)
        }
    }
} catch (e) {
    console.log(e);
}

let sayingsData =[];
try {
    if(res.data){
        if(res.data.quotes){
            sayingsData.push(res.data.quotes)
        }
    }
} catch (e) {
    console.log(e);
}

let eventsData =[];
try {
    if(res.data){
        if(res.data.on_this_day){
            eventsData.push(res.data.on_this_day)
        }
    }
} catch (e) {
    console.log(e);
}
return [mediaData,meditationsData,sayingsData,eventsData,total]
}


