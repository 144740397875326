
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSearchMoreFilterAction, getSearchResultMoreAction } from '../../redux/actions/Search/SearchActions';
export default function SearchMoreHook(word,id) {
    const dispatch=useDispatch();
    const [activePage,setActivePage]=useState(1);
    const [loading, setLoading] = useState(true);

const getData=async()=>{
    setActivePage(1);
    if(id==10 || id==16){
        if (localStorage.getItem("filterSearch")) {
            const cateID = JSON.parse(localStorage.getItem("filterSearch"));
            await dispatch(getSearchMoreFilterAction(word,id,cateID.id,8,1))
          
        } else {
            setLoading(true);
            await dispatch(getSearchResultMoreAction(word,8, 1,id));
            setLoading(false);

        }
    }else{
        if (localStorage.getItem("filterSearch")) {
            const cateID = JSON.parse(localStorage.getItem("filterSearch"));
            setLoading(true);
            await dispatch(getSearchMoreFilterAction(word,id,cateID.id,6,1))
            setLoading(false);

        } else {
            setLoading(true);
            await dispatch(getSearchResultMoreAction(word,6, 1,id));
            setLoading(false);

        }

    }


}
useEffect(()=>{
    getData();
    
},[localStorage.getItem("filterSearch")])
let res,resFilter;
     res=useSelector(state=> state.SearchReducer.searchResultMore);
     resFilter=useSelector(state=> state.SearchReducer.searchResultMoreFilter);



let searchMoreData=[];
try {
    if(localStorage.getItem("filterSearch")){
        if(resFilter){
            if(resFilter.data){
                searchMoreData.push(resFilter.data)
            }
            
        
    }
    }else{
        if(res){
            if(res.data){
                searchMoreData.push(res.data)
            }
        }
}
} catch (e) {
    console.log(e);
}

let pageCount=0;

try {
    if(localStorage.getItem("filterSearch")){
        if(resFilter){
            if(resFilter.pagination){
                pageCount=Math.ceil(resFilter.pagination.total/resFilter.pagination.per_page)
            }
        }

    }else{
        if(res){
            if(res.pagination){
                pageCount=Math.ceil(res.pagination.total/res.pagination.per_page)
            }
        }
    }
 
} catch (error) {}


const handleChangePage= async(count)=>{
    setActivePage(count)
    if(id==10 || id==16){
        if (localStorage.getItem("filterSearch")) {
            const cateID = JSON.parse(localStorage.getItem("filterSearch"));
            setLoading(true);
            await dispatch(getSearchMoreFilterAction(word,id,cateID.id,8,count))
            setLoading(false);

          
        } else {
            setLoading(true);
            await dispatch(getSearchResultMoreAction(word,8, count,id));
            setLoading(false);

        }
    }else{
        if (localStorage.getItem("filterSearch")) {
            const cateID = JSON.parse(localStorage.getItem("filterSearch"));
            setLoading(true);
            await dispatch(getSearchMoreFilterAction(word,id,cateID.id,6,count))
            setLoading(false);

          
        } else {
            setLoading(true);
            await dispatch(getSearchResultMoreAction(word,6, count,id));
            setLoading(false);

        }

    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 
}

return [searchMoreData,pageCount,handleChangePage,getData,activePage,loading]
}


