import React, { useState, useRef, useContext, useEffect } from 'react'
import './CategoryContainer.css'
import CategoryHeader from './CategoryHeader'
import DataByCategoryHook from '../../Logic/Categories/DataByCategoryHook'
import { Link, useLocation, useParams } from 'react-router-dom'
import SayingWriteCard from '../Media/Writings/Sayings/SayingWriteCard'
import OneBook from '../Books/OneBook'
import SayingsPhotoCard from '../Media/Writings/Sayings/SayingsPhotoCard'
import GalleryImg from '../Gallery/GalleryImg'
import ArticlesCard from '../Media/Writings/Articles/ArticlesCard'
import VisitsImg from '../Visits/VisitsImg'
import EventCard from '../Home/EventCard'
import PoemsCard from '../Media/Writings/Poems/PoemsCard'
import CategoryVideoCard from './Categories/CategoryVideoCard'
import QuestionCard from '../Media/Writings/Questions/QuestionCard'
import DocsCard from '../Media/Writings/Docs/DocsCard'
import CategorySoundCard from './Categories/CategorySoundCard'
import CatagorySayingWriteCard from './Categories/CatagorySayingWriteCard'
import VisitsOverlay from '../Visits/VisitsOverlay'
import Spinner from '../Utility/Spinner'
import DocOverlay from '../Media/Writings/Docs/DocOverlay'
import { UserContext } from '../Context/UserContext'
import axios from 'axios'
import CategoryVisitOverlay from './CategoryVisitOverlay'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SayingShareContainer from '../Media/Writings/Sayings/SayingShareContainer'
import { toPng } from 'html-to-image'
import { OverlayPanel } from 'primereact/overlaypanel'
import SayingWriteCardOverlay from '../Media/Writings/Sayings/SayingWriteCardOverlay'
export default function CategoryContainer({ categoryBookData, categoryGalleryData, categoryVideoData, categoryArticleData, categorySoundData, categorySayingsData, categoryPhotoSayingsData, categoryPoemsData, categoryQuestionsData, categoryHappenData, categoryDocsData, handleImageClick, all, check, ele, loading,cate }) {
    // variables
    const { id } = useParams()
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { baseURL } = useContext(UserContext);
    const [visitsImgs, setVisitsImgs] = useState(null)
    const [ID,setID]=useState()
    const fetchVisits = (id) => {
        axios.get(`${baseURL}/get-images/${id}?lang=ar`)
            .then((response) => {
                setVisitsImgs(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })

    }
    const handleImageClicked = (index) => {
        setCurrentIndex(index);
        setOverlayVisible(true);

    };

    const closeOverlay = () => {
        setOverlayVisible(false);

    };
    const [isOverlayVisibleDocs, setOverlayVisibleDocs] = useState(false);

    const [clickedImage, setClickedImage] = useState(null);
    const [clickedImageIndex, setClickedImageIndex] = useState(0);
    const [overlayImgs, setOverlayImgs] = useState([]);
    const [clickedCategory, setClickedCategory] = useState('');
    const handleImageClickDocs = (imgUrl, index, media, categoryValue) => {
        setClickedImage(imgUrl);
        setClickedImageIndex(index);
        setOverlayImgs(media);
        setClickedCategory(categoryValue);
        setOverlayVisibleDocs(true);
        document.body.style.overflow = "hidden";

    };
    

    const closeOverlayDocs = () => {
        setOverlayVisibleDocs(false);
        setClickedImage(null);
        document.body.style.overflow = "auto"; 

    };


    const playersRef = useRef([]); // Array of refs for all players
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

    const handlePlay = (index) => {
        // Pause any currently playing audio
        if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
            const previousPlayer = playersRef.current[currentPlayingIndex];
            if (previousPlayer && previousPlayer.audio.current) {
                previousPlayer.audio.current.pause();
            }
        }

        // Set the current playing index
        setCurrentPlayingIndex(index);
    };

    const handlePrev = (index) => {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            playersRef.current[prevIndex].audio.current.play();
            handlePlay(prevIndex);
        }
    };

    const handleNext = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < categorySoundData[0].value.length) { // Ensure it doesn't exceed the length
            playersRef.current[nextIndex].audio.current.play();
            handlePlay(nextIndex);
        }
    };
        // State to detect if mobile view is active
        const [isMobile, setIsMobile] = useState(false);

        // Handle resizing to determine mobile view
        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 667);
            };
    
            handleResize(); // Check initial state
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        // sayings card ***********************************************
        const op=useRef();

        // get current path
         const location = useLocation();
         const currentPath = `${window.location.origin}${location.pathname}${location.search}`;
        
        // get image from on Drive
        function convertOneDriveLink(onedriveUrl) {
          try {
            const url = new URL(onedriveUrl);
            const fileId = url.searchParams.get('id');
            if (!fileId) {
              console.error("Invalid OneDrive URL or 'id' parameter missing.");
              return null;
            }
            const directLink = `https://popeshenoudaacademy-my.sharepoint.com/personal/msoul_zvf-eg_org/_layouts/15/download.aspx?SourceUrl=${encodeURIComponent(
              fileId
            )}`;
            return directLink;
          } catch (error) {
            console.error("Error processing OneDrive URL:", error.message);
            return null;
          }
        }
        // const directLink = img ? convertOneDriveLink(img) : null;
      
      
        // Copy Data
        const copyData = async (item) => {
            setSelectedItem(item)
            try {
              const plainTextDesc = selectedItem.quote.replace(/<[^>]+>/g, "");
              const message = `\n\n"${plainTextDesc || ""}"\n\n- ${selectedItem.name || ""} ${selectedItem.ref?`(${selectedItem.ref})`:""}`;
              await navigator.clipboard.writeText(message); 
              toast.success("تم النسخ بنجاح");
            } catch (error) {
              toast.error("عذرا ..قم بالمحاولة لاحقا");
              console.error("Failed to copy:", error);
            }
          };
        // Download Card
        const cardRefs = useRef(new Map());
        const buttonDownload = useRef();
        const generateImageWithoutButton = (name) => {
            const cardElement = cardRefs.current.get(name); 
          
            if (cardElement) {
              toPng(cardElement)
                .then((dataUrl) => {
                  const link = document.createElement("a");
                  link.href = dataUrl;
                  link.download = `${name || "card"}.png`;
                  link.click();
                })
                .catch((error) => {
                  console.error("Error generating image:", error.message, error);
                });
            } else {
              console.error("Card element not found for:", name);
            }
          };
      
        // more to read 
        const [isExpanded, setIsExpanded] = useState(false);
        const [isOverflowing, setIsOverflowing] = useState(false);
        const descRef = useRef(null);
        const [isOverlayCard, setOverlayCard] = useState(false);
        const [isOverlayDots, setIsOverlayDots] = useState(false);
        const [isOverlayBlockShare, setOverlayBlockShare] = useState(false);
        const [selectedItem, setSelectedItem] = useState(null);

        const handleCardOverlay=(item)=>{
            setSelectedItem(item);
          document.body.classList.add("no-scroll");
          setOverlayCard(true)
          const header = document.querySelector('.header');
          header.style.zIndex = '1000';



        }
        const handleCardOverlayClose=()=>{
            setSelectedItem(null);
          document.body.classList.remove("no-scroll");
          setOverlayCard(false)
 
        }
      
        const handleOverlayToggle = (e, item) => {
            setSelectedItem(item); 
            op.current.toggle(e);
            setIsOverlayDots((prev) => !prev); 
            const header = document.querySelector('.header');
            header.style.zIndex = '10000';
        }
      const openShareBlock=(item)=>{
        document.body.classList.add("no-scroll");
        setOverlayBlockShare(true)
      }
      const closeShareBlock=()=>{
        document.body.classList.remove("no-scroll");
        setOverlayBlockShare(false)
        setIsOverlayDots(false)
      }

      const swiperRef = useRef(null);

useEffect(() => {
    if (swiperRef.current?.swiper) {
        swiperRef.current.swiper.update(); // Updates Swiper instance
    }
}, [categoryDocsData]);
    return (
        <div className='categoryArticles'>
            {
                all.length != 0 ?
                    <div className='Container'>
                        {
                            categorySayingsData[0] ?
                                <>
                                    <CategoryHeader cate={categorySayingsData[0] && categorySayingsData[0].key} count={categorySayingsData[0].count}  src={`/category/${id}/more/${categorySayingsData[0].media_type_id}`} />
                                    <div className="categoryRow">
                                        <div className=' sayingsWriteItems'>
                                            {
                                                categorySayingsData[0].value && categorySayingsData[0].value.slice(0, 2).map((item) => {
                                                    return (
                                                        <SayingWriteCard img={item.image != null ? item.image : `/assets/media/writings/Sayings/img1.png`} desc={item.quote} name={item.ref} cate={item.name} key={item.id}  checked={item.sharepoint_image}/>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='sayingsWriteItemsCateRes' style={{display:"none"}} dir="rtl">
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={categorySayingsData[0].value.length > 1 ? 1.3:1}

                                            pagination={{
                                                clickable: true,
                                                el: '.swiper-pagination',
                                            }}
                                            modules={[ Autoplay, Pagination]}
                                            speed={2000}
                                            >
                                            {
                                                  categorySayingsData[0].value ? categorySayingsData[0].value.slice(0, 2).map((item,index)=>{
                                                    return(
                                                        <SwiperSlide   key={index}>
                                     
                                                            <div className="sayingWriteCard" ref={(el) => {
                                                                if (el) {
                                                                cardRefs.current.set(item.name, el); 
                                                                } else {
                                                                cardRefs.current.delete(item.name);
                                                                }
                                                            }}   >
                                                                                                                
                                                            <div className="sayingWriteCardImg">
                                                            {convertOneDriveLink(item.image) ? (
                                                            <img src={convertOneDriveLink(item.image)} alt="Image from OneDrive" />
                                                        ) : item.image ? (
                                                            <img src={item.image} alt="Image" />
                                                        ) : (
                                                            <img src="/assets/media/writings/Sayings/img1.png" alt="Placeholder Image" />
                                                        )}
                                                        </div>
                                                            <div className="sayingWriteCardContent">
                                                                <img
                                                                className="signQuote saywImg"
                                                                src="/assets/media/writings/Sayings/q1.png"
                                                                alt="img"
                                                                />
                                                            <div>
                                                            <p
                                                            ref={descRef}
                                                            className={`sayingWriteCardDesc ${isExpanded ? "expanded" : ""}`}
                                                            dangerouslySetInnerHTML={{ __html: item.quote }}
                                                            style={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: isExpanded ? "unset" : 3,
                                                                WebkitBoxOrient: "vertical",
                                                                overflow: "hidden",
                                                            }}
                                                            ></p>                                        
                                                       
                                                            <div className="moreWriteCard">
                                                                <img
                                                                src="/assets/moreWC.png"
                                                                alt="writeCard"
                                                                onClick={() => handleCardOverlay(item)}
                                                                />
                                                            </div>
                                                            
                                                            </div>
                                                                <div style={{ clear: "both" }}></div>
                                                                <div className="sayingWriteCardSign">
                                                                    <div>
                                                                    <h3 className="sayingWriteCardSignName"> {item.ref?item.ref:""} </h3>
                                                                    <span className="sayingWriteCardCategory">{item.name}</span>
                                                                    </div>
                                                                    <img  src='/assets/sign.png' className='sayingWriteCardSignImg'/>
                                                                </div>
                                                                <img src="/assets/dots.png" alt=""   onClick={(e)=>handleOverlayToggle(e,item)} className='dotsWrite' />
                                                                <OverlayPanel ref={op}>
                                                                <button className="shareButton btnAction" onClick={()=>openShareBlock(item)} >
                                                                <span>مشاركة</span>
                                                                <img src='/assets/Share.png'/>
                                                                </button> 
                                                                <img src='/assets/line.png' alt='line'/>
                                                                <button className="shareButton btnAction" onClick={()=>copyData(item)}>
                                                                <span>نسخ</span>
                                                                <img src='/assets/copy.png'/>
                                                                </button> 
                                                                <img src='/assets/line.png' alt='line'/>
                                                                {/* <button className="#downloadImage downloadButton btnAction" onClick={()=>generateImageWithoutButton(selectedItem.name)} ref={buttonDownload} style={{cursor:"pointer"}}>
                                                                <span>تحميل</span>
                                                                <img src='/assets/download.png'/>
                                                                </button>   */}
                                                                </OverlayPanel>
                                 


                                                            </div>
                                                            </div>

                                                        </SwiperSlide>
                                                    )
                                                }):""
                                            }

                                                <div className="swiper-pagination"></div>


                                            </Swiper>

                                        </div>
                                        {isOverlayDots&&<div className='dots overlay' onClick={(e) => handleOverlayToggle(e, selectedItem)} 
                                            ></div>}
                                            {isOverlayCard && selectedItem && (
                                                <SayingWriteCardOverlay
                                                    closeOverlay={handleCardOverlayClose}
                                                    img={selectedItem.image}
                                                    desc={selectedItem.quote}
                                                    name={selectedItem.ref}
                                                    cate={selectedItem.name}
                                                />
                                                )}

                                        {

                                            isOverlayBlockShare&& <SayingShareContainer closeOverlay={closeShareBlock} currentPath={currentPath}  desc={selectedItem.quote} name={selectedItem.name} cate={selectedItem.ref} />
                                         }
                                          
                                        <Link to={`/category/${id}/more/${categorySayingsData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>




                                : ""
                        }
                        {
                            categoryBookData[0] ?
                                <div className='cateBook'>
                                    <CategoryHeader cate={categoryBookData[0] && categoryBookData[0].key} count={categoryBookData[0].count} src={`/category/${id}/more/${categoryBookData[0].media_type_id}`}  />
                                    <div className="categoryRow">
                                        <div className=' categoryBooksData'>
                                            {
                                                categoryBookData[0].value && categoryBookData[0].value.slice(0, 3).map((item) => {
                                                    return (
                                                        <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                                    )
                                                })
                                            }
                                            <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />

                                        </div>
                                        <div className=' categoryBooksDataResIpad' style={{display:"none"}}>
                                            {
                                                categoryBookData[0].value && categoryBookData[0].value.slice(0, 2).map((item) => {
                                                    return (
                                                        <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                                    )
                                                })
                                            }
                                            <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />

                                        </div>
                                        <div className='categoryBooksDataCateRes' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={70}
                                                slidesPerView={categoryBookData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                     categoryBookData[0].value ? categoryBookData[0].value.slice(0, 3).map((item)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                 <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                                                <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />
                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }
                                                <div className="swiper-pagination"></div>


                                            </Swiper>
                                        </div>
                                        <Link to={`/category/${id}/more/${categoryBookData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                    
                                </div>





                                : ""
                        }

                        {
                            categoryPhotoSayingsData[0] ?
                                <>
                                    <CategoryHeader cate={categoryPhotoSayingsData[0] && categoryPhotoSayingsData[0].key} count={categoryPhotoSayingsData[0].count} src={`/category/${id}/more/${categoryPhotoSayingsData[0].media_type_id}`}/>
                                    <div className="categoryRow">
                                        <div className='sayingsPhotoItems categoryPhotoSayingsData'>
                                            {
                                                categoryPhotoSayingsData[0].value && categoryPhotoSayingsData[0].value.slice(0, 4).map((item) => {
                                                    return (
                                                        <>
                                                            <SayingsPhotoCard src={item.image} key={item.id} />
                                                        </>
                                         
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='sayingsPhotoItemsCateRes' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryPhotoSayingsData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryPhotoSayingsData[0].value ? categoryPhotoSayingsData[0].value.slice(0, 4).map((item)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                <SayingsPhotoCard src={item.image} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>


                                                </Swiper>
                                            </div>
                                        <Link to={`/category/${id}/more/${categoryPhotoSayingsData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>




                                : ""
                        }
                        {
                            categoryGalleryData[0] ?

                            <>
                            <CategoryHeader cate={categoryGalleryData[0] && categoryGalleryData[0].key} count={categoryGalleryData[0].count} src={`/category/${id}/more/${categoryGalleryData[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='visits-imgs-cont categoryGalleryData'>
                            {
                                 categoryGalleryData[0].value&&categoryGalleryData[0].value.slice(0,3).map((item,i)=>{
                                    return(
    
                                        <VisitsImg info={item.description} src={item.image!=null?item.image:`/assets/gallery-1.png`} key={item.id} handleImageClick={() => handleImageClicked(i)} 
                                        />                                              
                                        )
                                })
                            }
                                </div>
                                <div className='categoryGalleryDataResCate' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryGalleryData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryGalleryData[0].value ? categoryGalleryData[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                           <VisitsImg 
                                                                info={item.description} 
                                                                src={item.image != null ? item.image : '/assets/gallery-1.png'} 
                                                                key={item.id} 
                                                                handleImageClick={() => handleImageClicked(i)} 
                                                            />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                </div>
                                {isOverlayVisible && (
                                            <CategoryVisitOverlay closeOverlay={closeOverlay} visitsImgs={categoryGalleryData[0]} currentIndex={currentIndex}  />
                                            )}
                                    <Link to={`/category/${id}/more/${categoryGalleryData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


                                : ""


                        }

                        {
                            categoryArticleData[0] ?
                                <>
                                    <CategoryHeader cate={categoryArticleData[0] && categoryArticleData[0].key} count={categoryArticleData[0].count} src={`/category/${id}/more/${categoryArticleData[0].media_type_id}`} />
                                    <div className="categoryRow">
                                        <div className='articleItems categoryArticleData'>

                                            {
                                                categoryArticleData[0].value && categoryArticleData[0].value.slice(0, 2).map((item) => {
                                                    return (

                                                        <ArticlesCard img={item.image != null ? item.image : `/assets/media/writings/article/articleItem.png`} title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />

                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='categoryArticleDataResCate' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryArticleData[0].value.length > 1 ? 1.3:1}
                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryArticleData[0].value ? categoryArticleData[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                <ArticlesCard img={item.image != null ? item.image : `/assets/media/writings/article/articleItem.png`} title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                        </div>
                                        <Link to={`/category/${id}/more/${categoryArticleData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>


                                : ""

                        }

                        {
                            categoryHappenData[0] ?

                                <>
                                    <CategoryHeader cate={categoryHappenData[0] && categoryHappenData[0].key} count={categoryHappenData[0].count} src={`/category/${id}/more/${categoryHappenData[0].media_type_id}`} />
                                    <div className="categoryRow">
                                        <div className='eventsItems categoryHappenData'>

                                            {
                                                categoryHappenData[0].value && categoryHappenData[0].value.slice(0, 2).map((item) => {
                                                    return (

                                                        <EventCard date={item.date} title={item.name} desc={item.event} img={item.image != null ? item.image : `/assets/media/writings/article.png`} key={item.id} />

                                                    )
                                                })
                                            }
                                        </div>
                                        <Link to={`/category/${id}/more/${categoryHappenData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>



                                : ""
                        }

                        {
                            categoryPoemsData[0] ?

                                <>
                                    <CategoryHeader cate={categoryPoemsData[0] && categoryPoemsData[0].key} count={categoryPoemsData[0].count} src={`/category/${id}/more/${categoryPoemsData[0].media_type_id}`}  />
                                    <div className="categoryRow">
                                        <div className='PoemsItems categoryPoemsData'>

                                            {
                                                categoryPoemsData[0].value && categoryPoemsData[0].value.slice(0, 3).map((item) => {
                                                    return (

                                                        <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />

                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='categoryPoemsDataResCate PoemsItems' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryPoemsData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryPoemsData[0].value ? categoryPoemsData[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                    <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                            </div>
                                        <Link to={`/category/${id}/more/${categoryPoemsData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>




                                : ""
                        }
                        {
                            categoryDocsData[0] ?

                                <>
                                    <CategoryHeader cate={categoryDocsData[0] && categoryDocsData[0].key} count={categoryDocsData[0].count} src={`/category/${id}/more/${categoryDocsData[0].media_type_id}`} />
                                    <div className="categoryRow">
                                        <div className='docsItems categoryDocsData'>

                                            {
                                                categoryDocsData[0].value && categoryDocsData[0].value.slice(0, 4).map((item,index) => {
                                                    return (

                                                        <React.Fragment key={item.id}>
                                                            <DocsCard src={item.image != null ? item.image : `/assets/media/writings/docs/docs.png`} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index, categoryDocsData[0].value, cate)} />
                                                             
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                                   {isOverlayVisibleDocs && (
                                                        
                                                                <DocOverlay
                                                                closeOverlay={closeOverlayDocs}
                                                                img={clickedImage}
                                                                cate={clickedCategory}
                                                                imgs={overlayImgs}
                                                                initialSlide={clickedImageIndex}
                                                              /> 
                                                            )}
                                        </div>
                                        <div className='categoryDocsDataRes docsItems' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                ref={swiperRef}

                                                spaceBetween={20}
                                                slidesPerView={categoryDocsData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryDocsData[0].value ? categoryDocsData[0].value.slice(0, 3).map((item,index)=>{
                                                        return(
                                                            <>
                                                                <SwiperSlide >
                                                    
                                                                <DocsCard src={item.image != null ? item.image : "/assets/media/writings/docs/docs.png"} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index, categoryDocsData[0].value, cate)} />
                                                                <p style={{ color:"#F8F3ED" }}> doc</p>
                                                                </SwiperSlide>
                                                                
                                                           
                                                            </>

                                                        )
                                                    }):""
                                                }
                                                           
                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                            </div>
                                            {isOverlayVisibleDocs && (
                                                                          <DocOverlay
                                                                          closeOverlay={closeOverlayDocs}
                                                                          img={clickedImage}
                                                                          cate={clickedCategory}
                                                                          imgs={overlayImgs}
                                                                          initialSlide={clickedImageIndex}
                                                                          
                                                                        /> 
                                                                )}
                                        <Link to={`/category/${id}/more/${categoryDocsData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>




                                : ""
                        }


                        {
                            categoryVideoData[0] ?
                                <>
                                    <CategoryHeader cate={categoryVideoData[0] && categoryVideoData[0].key} count={categoryVideoData[0].count} src={`/category/${id}/more/${categoryVideoData[0].media_type_id}`}  />
                                    <div className="categoryRow">
                                        <div className='categoryVideoData'>

                                            {
                                                categoryVideoData[0].value && categoryVideoData[0].value.slice(0, 3).map((item) => {
                                                    return (

                                                        <CategoryVideoCard url={item.url} desc={item.name} src={item.id} key={item.id} />

                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='categoryVideoDataRes' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryVideoData[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryVideoData[0].value ? categoryVideoData[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                <CategoryVideoCard url={item.url} desc={item.name} src={item.id} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                            </div>
                                        <Link to={`/category/${id}/more/${categoryVideoData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>

                                : ""


                        }


                        {
                            categoryQuestionsData[0] ?

                                <>
                                    <CategoryHeader cate={categoryQuestionsData[0] && categoryQuestionsData[0].key} count={categoryQuestionsData[0].count} src={`/category/${id}/more/${categoryQuestionsData[0].media_type_id}`} />
                                    <div className="categoryRow">
                                        <div className='questionsItems categoryQuestionsData'>

                                            {
                                                categoryQuestionsData[0].value && categoryQuestionsData[0].value.slice(0, 3).map((item) => {
                                                    return (

                                                        <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id} checkAudio={item.sharepoint_uodated}/>

                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='categoryQuestionsDataRes' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryQuestionsData[0].value.length > 1 ? 1.3:1}
                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryQuestionsData[0].value ? categoryQuestionsData[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                              <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id} checkAudio={item.sharepoint_uodated}/>
                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                            </div>
                                        <Link to={`/category/${id}/more/${categoryQuestionsData[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                    </div>
                                </>





                                : ""
                        }




                        {
                            categorySoundData[0] ?

                            <>
                            {categorySoundData[0] && (
                                <>
                                    <CategoryHeader
                                        cate={categorySoundData[0].key}
                                        count={categorySoundData[0].count}
                                        src={`/category/${id}/more/${categorySoundData[0].media_type_id}`}
                                    />
                
                                    <div className="categoryRow">
                                        {/* Desktop or Default View */}
                                        {!isMobile && (
                                            <div className="categorySoundData">
                                                {categorySoundData[0].value &&
                                                    categorySoundData[0].value.slice(0, 3).map((item, index) => (
                                                        <CategorySoundCard
                                                            key={item.id}
                                                            index={index}
                                                            audio={item.url}
                                                            title={item.name}
                                                            isPlaying={currentPlayingIndex === index}
                                                            hasPrev={index > 0} // Only show prev if there's a previous item
                                                            hasNext={index < categorySoundData[0].value.slice(0, 3).length - 1} // Ensure next is calculated after slice
                                                            onPlay={handlePlay}
                                                            onPrev={handlePrev}
                                                            onNext={handleNext}
                                                            ref={(el) => (playersRef.current[index] = el)} // Store refs
                                                        />
                                                    ))}
                                            </div>
                                        )}
                
                                        {/* Mobile View */}
                                        {isMobile && (
                                            <div className="categorySoundDataRes" dir="rtl">
                                                <Swiper
                                                    direction="horizontal"
                                                    spaceBetween={20}
                                                    slidesPerView={categorySoundData[0].value.length > 1 ? 1.3 : 1}
                                                    pagination={{ clickable: true, el: '.swiper-pagination' }}
                                                    modules={[Autoplay, Pagination]}
                                                    speed={2000}
                                                >
                                                    {categorySoundData[0].value?.slice(0, 3).map((item, index) => (
                                                        <SwiperSlide key={item.id}>
                                                            <CategorySoundCard
                                                                index={index}
                                                                audio={item.url}
                                                                title={item.name}
                                                                isPlaying={currentPlayingIndex === index}
                                                                hasPrev={index > 0}
                                                                hasNext={index < categorySoundData[0].value.slice(0, 3).length - 1}
                                                                onPlay={handlePlay}
                                                                onPrev={handlePrev}
                                                                onNext={handleNext}
                                                                ref={(el) => (playersRef.current[index] = el)}
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                            </div>
                                        )}
                
                                        {/* More Button */}
                                        <Link to={`/category/${id}/more/${categorySoundData[0].media_type_id}`} className='more_btn'>
                                            المزيد
                                        </Link>
                                    </div>
                                </>
                            )}
                        </>
                                : ""



                        }



                    </div>
                    : ""
            }

            {loading ? (
                <p style={{ textAlign: "center" }}>
                    <Spinner />
                </p>
            ) : all.length === 0 && check ? (
                <p className="noResult" style={{ textAlign: "center" }} ref={ele}>
                    لا يوجد نتائج
                </p>
            ) : null}
        <ToastContainer />

        </div>
    )
}
