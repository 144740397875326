import React, { useEffect } from 'react'
import './OnePoemPage.css'
import OnePoemContainer from '../../../../components/Media/Writings/Poems/OnePoemContainer'
import PoemsFilter from '../../../../components/Media/Writings/Poems/PoemsFilter'
export default function OnePoemPage() {
  window.addEventListener('hashchange', function (e) {
    e.preventDefault();
});

window.onload = function () {
    window.history.replaceState(null, null, ' '); // Clear hash if any
};
useEffect(() => {
  window.scrollTo(0, 0); // Ensure scroll starts at the top
}, []);
  return (
    <div className='onePoemPage'>
            <OnePoemContainer />
    </div>
  )
}
