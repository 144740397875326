import React from 'react'
import './PoemsContainer.css'
import '../../Responsive/PoemsRes.css'
import MediaHeader from '../../MediaHeader'
import PoemsCard from './PoemsCard'
import PoemsHook from '../../../../Logic/Media/Writings/Poems/PoemsHook'
import Paginate from '../../../Utility/Paginate'
import Spinner from '../../../Utility/Spinner'
export default function PoemsContainer() {
  
  const[poemsData,pageCount,filterGroup,attrGroup,handleChangePage,getData,notFound,activePage]=PoemsHook();
 
  return (
    <div className='PoemsContainer'>
        <div className='Container'>
               <MediaHeader title="قصائد" />
                <div className='PoemsItems'>
                  {
                    poemsData && Array.isArray(poemsData[0])?poemsData[0].map((item)=>{
                      return(
                        <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />
                      )
                    }):<Spinner />
                  }



                </div>
        </div>
        {
          pageCount >0&&  <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage}/>
        }
        <p  className='noResult' ref={notFound} style={{display:"none"}}>لا يوجد نتائج</p>
    </div>
  )
}
