import React from 'react'

export default function VisitsImg({ info, src ,handleImageClick}) {
    return (
        <div className="visits-img-re" onClick={handleImageClick}>
            <img src={src} alt="" className='visits-img' />
            <div className="visits-img-abs">
                <p className='visits-img-abs-p' dangerouslySetInnerHTML={{__html:info}}></p>
            </div>
        </div>
    )
}
