import React from 'react'
import './MediaGallery.css'
import './Responsive/HomeRes.css'
import SectionHeader from '../Utility/SectionHeader'
import MediaGalleryCard from './MediaGalleryCard'
import HomeHook from '../../Logic/Home/HomeHook'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import MediaGalleryCardRes from './MediaGalleryCardRes'


export default function MediaGallery() {
  const[mediaData,meditationsData,sayingsData,eventsData,total]=HomeHook();
  return (
    <div className='mediaGallery'  >
        <SectionHeader title="معـــــــــرض" subtitle="المــــــــــــــــــــــــــيديا" />
        <div className='Container'>
          <div className='mediaGalleryItems'>
              {
                mediaData[0]?
                mediaData[0].map((item)=>{
                  return(
                    <MediaGalleryCard img={item.image} cate={item.name} count={item.count} desc={item.description} cardIcon={item.image_icon} src={item.id==4?`/media/7`:`/media/${item.id}`} key={item.id} />
                  )
                })
    
                :""
              }
                <MediaGalleryCard img="assets/MediaGallery/1.png" cate="مقالات وكتابات أخرى" count={total} desc=" مقالات وكتابات أخرى للبابا شنوده الثالث "  cardIcon={`assets/MediaGallery/icon5.png`} src={`/media/7`}  />

          </div>
        </div>

        <div className='bgMediaGallery'></div>
        <div className='bgMediaGallery two'></div>
        <div className='mediaGalleryRes' style={{display:"none"}}>
        <Swiper

            spaceBetween={20}
            slidesPerView={1.2}

            pagination={{
                clickable: true,
                el: '.swiper-pagination',
            }}
            modules={[ Autoplay, Pagination]}
            speed={2000}
            >
            {
                mediaData[0]?mediaData[0].map((item)=>{
                    return(
                        <SwiperSlide >
                            <MediaGalleryCardRes img={item.image} cate={item.name} count={item.count} desc={item.description} cardIcon={item.image_icon} src={item.id==4?`/media/7`:`/media/${item.id}`} key={item.id} />

                        </SwiperSlide>
                    )
                }):""
            }
        <SwiperSlide >
          <MediaGalleryCardRes img="assets/MediaGallery/1.png" cate="مقالات وكتابات اخرى"  count={total} desc="مقالات وكتابات اخرى "  cardIcon={`assets/MediaGallery/icon5.png`} src={`/media/7`}  />
        </SwiperSlide>

        <div className="swiper-pagination"></div>

          </Swiper>
        </div>
   

    </div>
  )
}
