import React, { useEffect, useState, useContext } from 'react'
import SoundsHeader from './SoundsHeader'
import ChooseCard from './soundsOptionsCard'
import './SoundsOptionsContainer.css'
import axios from 'axios'
import { UserContext } from '../../Context/UserContext'



export default function ChooseContainer() {
  const [showMore, setShowMore] = useState(false);
  const { baseURL, setCategoryId } = useContext(UserContext);
  const [media, setMedia] = useState('');
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    axios.get(`${baseURL}/getmedia/5`)
      .then((response) => {
        setMedia(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })

  }, []);
  const SendCategoryId = (id,name) => {
    setCategoryId({id,name});
  }
  return (
    <>
        <div className='soundsOptionsContainer'>
      <SoundsHeader title="أبرز الاختيارات" />

      <div className="soundsOptionsRed">
        <div className='soundsOptionsItems'>
          {media && media.slice(0, 5).map((med, index) => (
            <ChooseCard img={med.sharepoint_image?med.image:"/assets/default/sounds/Audio-Highlights - DF.png"} title={med.name} key={index} desc="قداسة البابا شنوده الثالث" onClick={() => {
              SendCategoryId(med.id,med.name);
              setShowMore(false);

              window.scrollTo({
                top: 500, 
                behavior: "smooth"
              });            
            }} />
          ))}

          {showMore && (
            <>
            { media&& media.slice(5).map((med, index) => (
                <ChooseCard img={med.sharepoint_image?med.image:"/assets/default/sounds/Audio-Highlights - DF.png"}  title={med.name} key={index} desc="قداسة البابا شنوده الثالث"  onClick={() => {
                  SendCategoryId(med.id,med.name);
                  setShowMore(false);
    
                  window.scrollTo({
                    top: 500, 
                    behavior: "smooth"
                  });            
                }}
               />
            ))}
            </>
          )}

        </div>
        <p className="soundsOptionsMore" onClick={handleShowMore}>{showMore?"عرض اقل":"المزيد"}</p>
      </div>
    </div>


      <div className="soundsOptionsRedRes" style={{display:"none"}}>
        <div className='soundsOptionsItems'>
          {media && media.slice(0, 4).map((med, index) => (
            <ChooseCard img={med.sharepoint_image?med.image:"/assets/default/sounds/Audio-Highlights - DF.png"}  title={med.name} key={index} desc="قداسة البابا شنوده الثالث"  onClick={() => {
              SendCategoryId(med.id,med.name);
              setShowMore(false);

              window.scrollTo({
                top: 500, 
                behavior: "smooth"
              });            
            }} />
          ))}

          {showMore && (
            <>
            { media&& media.slice(5).map((med, index) => (
                <ChooseCard img={med.sharepoint_image?med.image:"/assets/default/sounds/Audio-Highlights - DF.png"}  title={med.name} key={index} desc="قداسة البابا شنوده الثالث"  onClick={() => {
                  SendCategoryId(med.id,med.name);
                  setShowMore(false);
    
                  window.scrollTo({
                    top: 500, 
                    behavior: "smooth"
                  });            
                }} />
            ))}
            </>
          )}

        </div>
        <p className="soundsOptionsMore" onClick={handleShowMore}>{showMore?"عرض اقل":"المزيد"}</p>
      </div>
    
    
    </>

  )
}
