import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useParams } from 'react-router-dom'


export default function ArticlesCard({img , title , desc , src}) {

  return (
    <div className='articleCard'>
        <div className='articleCardImg'>
             <Link to={src}>
               <img src={img} alt='article' />
            </Link>
        </div>
        <div className='articleCardContent'>
            <Link to={src}>
              <h2 className='articleCardTitle'>{title}</h2>
              <p className='articleCardDesc'  dangerouslySetInnerHTML={{__html:desc}}></p>
              <button className='articleCardBtn'>اقرأ المزيد<FontAwesomeIcon icon={faAnglesLeft} className='moreIcon'/>  </button>
            </Link>

        </div>
    </div>
  )
}


