import React, { useRef, useState } from 'react'
import { OverlayPanel } from 'primereact/overlaypanel';
import VideosHooks from '../../Logic/Media/Videos/VideosHooks';
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomModal from './CustomModal';
import MultimediaShare from '../Utility/MultimediaShare';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function OneVideo({ src, time, info, date, id, index,img,bookmarkshow,secondurl }) {
    const [visibleModal, setVisibleModal] = useState(null);  // Manage modal visibility
    const [copiedProductIds, setCopiedProductIds] = useState({});  // Manage copied status for each video
    const[baseURL,videos,categories,attrs,cateID,navigate,pag,page,op,token,,fetchVideos,handlePageChange,,,bookMarkRef,,,setCategories,setVideos,notFound,notFoundRes,activePage]=VideosHooks();

    // Handle the click on the dots
    const handleShareClick = (id) => {
        document.querySelectorAll(".custom-menu6").forEach((item) => {
            item.style.display = "none";
        });
        setVisibleModal(id);  // Show the modal for the selected video
        
    }

    // Handle copy action
    const handleCopy = (id) => {
        setCopiedProductIds(prevState => ({
            ...prevState,
            [id]: true,  // Set copied to true for the specific video
        }));
        setVisibleModal(null);  // Hide the modal immediately after copying
        setTimeout(() => {
            setCopiedProductIds(prevState => ({
                ...prevState,
                [id]: false,  // Reset copied after 2 seconds
            }));
        }, 2000);
    }
    const [isOverlayBlockMediaShare, setOverlayBlockMediaShare] = useState(false);
    const [videoID, setVideoID] = useState();
    const openMediaShareBlock = () => {
        document.body.classList.add("no-scroll");
        setOverlayBlockMediaShare(true);
    };
    const closeMediaShareBlock=()=>{
      document.body.classList.remove("no-scroll");
      document.body.style.overflow = "visible"; 
      setOverlayBlockMediaShare(false)
    }
    const [localBookmark, setLocalBookmark] = useState(bookmarkshow);

     let AddToBook = (media_id, isBooked) => {
        console.log("media_id",media_id);
        // 1️⃣ Optimistic UI: Update UI immediately
        setLocalBookmark(!isBooked);
 
        // 2️⃣ API Request to update server
        const axiosRequest = isBooked
            ? axios.delete(`${baseURL}/bookmarks`, {
                data: { media_id },
                headers: { Authorization: `Bearer ${token}` },
            })
            : axios.post(
                `${baseURL}/bookmarks`,
                { media_id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
 
            axiosRequest
            .then(() => {
                console.log("added")
                fetchVideos();
            })
            .catch((error) => {
                console.log(error.message);

            });
    };
    let checkLogin=localStorage.getItem("token")||"";
    const [status, setStatus] = useState(null);

    const checkVideo = (video) => {
        console.log("ss", video);
      
        // Extract YouTube video ID
        const idMatch = video.match(/(?:\/embed\/|v=)([^?&]+)/);
        if (!idMatch || !idMatch[1]) {
          console.log("Invalid video URL");
          setStatus(false);
          return;
        }
      
        const videoId = idMatch[1];
        const thumbUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      
        const img = new Image();
      
        img.onload = function () {
          if (img.width === 120 && img.height === 90) {
            setStatus(false); // Default placeholder thumbnail
          } else {
            setStatus(true);
          }
        };
      
        img.onerror = function () {
          setStatus(false);
        };
      
        img.src = thumbUrl;
      };
      useEffect(() => {
       checkVideo(src);
       console.log(status);
      }, [id]);
    return (
        <>
         <div style={{ position: 'relative', width: '31.5%', height: '343px' }} className={`oneVideo ${status}`} >
            {
                status?
                <iframe
                className='video-video-frame'
                src={src}
                title={`YouTube video player ${info}`}
                frameBorder="0"
                allow="encrypted-media"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen

            />
                :
                <video  controls className='second_video'>
                    <source src={secondurl} type="video/mp4" />
                </video>

            }

            <div className="videos-video-bottom-section">
                <div className="videos-video-bottom">

                    <img src="/assets/dots.png" alt="" className='videos-video-dots' onClick={() => handleShareClick(id)}  />
                    <div className="videos-video-archive3">
                    <p className='videos-video-bottom-p'>
                        <Link
                            to={`/displayvideo/${id}`}
                            onClick={() => {
                            setTimeout(() => {
                                window.scrollTo(0, 0);
                            }, 500); 
                            }}
                        >
                            {info}
                        </Link>
                        </p>
                        {
                            checkLogin?
                            <img 
                            src={localBookmark? "/assets/bookmark-yellow.png" : "/assets/archive.png"}
                            alt="" 
                            className='videos-video-archive'
                            onClick={() => AddToBook(id,localBookmark)}
                          />
                            :""
                        }
            
                    </div>

                    {/* <OverlayPanel ref={op}>
                    <p>Share</p>
                </OverlayPanel> */}
                    <CustomModal
                        key={index}
                        isVisible={visibleModal === id}
                    
                    >
                    
                            <p className='video-share' onClick={
                                ()=>{
                                    
                                    setOverlayBlockMediaShare(true);
                                    document.body.style.overflow = "hidden"; 
                                
                                }}>مشاركة</p>
                            
                    </CustomModal>

               
                </div>



                {/* <p className='videos-video-date'>{date}</p> */}
            </div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: '240px',
                background: 'rgba(255, 255, 255, 0)',
                cursor: 'pointer'
            }}
            onClick={() => {
                navigate(`/displayvideo/${id}`)
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 2000); 
                }}

            >

            </div>
             
        </div>
          {/* MultimediaShare is now rendered OUTSIDE the main component using React Portals */}
          {isOverlayBlockMediaShare &&
                ReactDOM.createPortal(
                    <MultimediaShare closeOverlay={closeMediaShareBlock} text={`https://popeshenoudasitetest.msol.dev/displayvideo/${id}`} />,
                    document.getElementById('portal-root')
                )}
        </>
       
    )
}
