import React, { forwardRef, useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import '../CategoryContainer.css'
const rewindimg = "/assets/rewind.png";
const rewindimg2 = "/assets/rewind2.png";
const rewindimg3 = "/assets/play.png";

const CategorySoundCard = forwardRef(
  ({ index, audio, title, isPlaying, hasPrev, hasNext, onPlay, onPrev, onNext }, ref) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 662);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 662);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Define custom prev and next buttons
    const customPrevIcon = hasPrev && (
      <button onClick={() => onPrev(index)} className="audio-control-button">
        <img src={rewindimg2} alt="prev" />
      </button>
    );
    
    const customNextIcon = hasNext && (
      <button onClick={() => onNext(index)} className="audio-control-button2">
        <img src={rewindimg} alt="next" />
      </button>
    );

    const customPlayIcon = <img src={rewindimg3} alt="play" className="play-btn" />;
    
    // console.log('hasPrev:', hasPrev, 'hasNext:', hasNext); // Log for debugging

    return (
      <div className="categorySoundCard">
        <div className="categorySoundCardImg">
          <img src="/assets/wav.png" alt="audio" />
        </div>
        <p className="categorySoundCardHead">{title}</p>
        <li className="categorySoundCardPlayIcon">
          <AudioPlayer
            style={{ 'rhap-download-progress-display': 'none' }} // not native, just example
            ref={ref} // Forward ref to the parent component
            src={audio}
            customAdditionalControls={[customPrevIcon, customNextIcon]} // Add custom prev/next buttons here
            customVolumeControls={[]} // Hide volume controls
            showJumpControls={false} // Hide the default jump controls
            customIcons={{
              play: customPlayIcon, // Custom play button
            }}
            onPlay={() => onPlay(index)} // Inform the parent about the currently playing track
          />
        </li>
      </div>
    );
  }
);

export default CategorySoundCard;
