
import React, { useState,useRef } from 'react'
import './SearchMoreContainer.css'
import { Link, useParams } from 'react-router-dom';
import CategoryHeader from '../Category/CategoryHeader';
import CatagorySayingWriteCard from '../Category/Categories/CatagorySayingWriteCard';
import OneBook from '../Books/OneBook';
import './SearchContainer.css'
import '../../Pages/Search/Responsive/SearchResponsive.css'

import SectionHeader from '../Utility/SectionHeader';
import SearchMoreHook from '../../Logic/Search/SearchMoreHook';
import Paginate from '../Utility/Paginate';
import SayingsPhotoCard from '../Media/Writings/Sayings/SayingsPhotoCard';
import VisitsImg from '../Visits/VisitsImg';
import ArticlesCard from '../Media/Writings/Articles/ArticlesCard';
import EventCard from '../Home/EventCard';
import PoemsCard from '../Media/Writings/Poems/PoemsCard';
import CategoryVideoCard from '../Category/Categories/CategoryVideoCard';
import QuestionCard from '../Media/Writings/Questions/QuestionCard';
import DocsCard from '../Media/Writings/Docs/DocsCard';
import CategorySoundCard from '../Category/Categories/CategorySoundCard';
import SayingWriteCard from '../Media/Writings/Sayings/SayingWriteCard';
import VisitsOverlay from '../Visits/VisitsOverlay';
import SearchMoreFilter from './SearchMoreFilter';
import DocOverlay from '../Media/Writings/Docs/DocOverlay';
import CategoryVisitOverlay from '../Category/CategoryVisitOverlay';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from '../Utility/Spinner';
export default function SearchMoreContainer() {
    let word="";
    if(localStorage.getItem("search")){
        word=localStorage.getItem("search");
    }
const {id}=useParams();
const   [searchMoreData,pageCount,handleChangePage,getData,activePage,loading]=SearchMoreHook(word,id)
const [isOverlayVisible, setOverlayVisible] = useState(false);

 const handleImageClick = () => {
    setOverlayVisible(true);
};

const closeOverlay = () => {
    setOverlayVisible(false);
};
const [isOverlayVisibleDocs, setOverlayVisibleDocs] = useState(false);

const [clickedImage, setClickedImage] = useState(null);
const [clickedImageIndex, setClickedImageIndex] = useState(0);
const [overlayImgs, setOverlayImgs] = useState([]);
const [clickedCategory, setClickedCategory] = useState('');

const handleImageClickDocs = (imgUrl, index, media, categoryValue) => {
    setClickedImage(imgUrl);
    setClickedImageIndex(index);
    setOverlayImgs(media);
    setClickedCategory(categoryValue);
    setOverlayVisibleDocs(true);
    document.body.style.overflow = "hidden";

};


const closeOverlayDocs = () => {
    setOverlayVisibleDocs(false);
    setClickedImage(null);
    document.body.style.overflow = "auto"; 

};
const [currentIndex, setCurrentIndex] = useState(0);
const handleImageClicked = (index) => {
    setCurrentIndex(index);
    setOverlayVisible(true);
    
};



const playersRef = useRef([]); // Array of refs for all players
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

    const handlePlay = (index) => {
        // Pause any currently playing audio
        if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
            const previousPlayer = playersRef.current[currentPlayingIndex];
            if (previousPlayer && previousPlayer.audio.current) {
                previousPlayer.audio.current.pause();
            }
        }

        // Set the current playing index
        setCurrentPlayingIndex(index);
    };

    const handlePrev = (index) => {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            playersRef.current[prevIndex].audio.current.play();
            handlePlay(prevIndex);
        }
    };

    const handleNext = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < searchMoreData[0][0].value.length) { // Ensure it doesn't exceed the length
            playersRef.current[nextIndex].audio.current.play();
            handlePlay(nextIndex);
        }
    };



  return (
    <div className='searchMoreContainer'>
   <div className='Container'>
     <SearchMoreFilter cates={searchMoreData[0]?searchMoreData[0][0]&&searchMoreData[0][0].filterable_categories&&searchMoreData[0][0].filterable_categories:""} />
            
     {
            loading ? (
                <Spinner />
            ) : 
            (
            <>
            {
                id=="8"?
                (
                    <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}   count={searchMoreData[0][0]&&searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                            <div className='sayingsWriteItems'>
                                {
                                    searchMoreData[0][0].value&&searchMoreData[0][0].value.map((item)=>{
                                        return(
                                            <SayingWriteCard img={item.image}  desc={item.quote} name={item.ref} cate={item.name}  key={item.id} checked={item.sharepoint_image}/>
                                            )
                                    })
                                }
                            </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />
                        
                    </div>
                </>
                )
                :""
            }

            {
                id=="1"?
                (
                <>
                <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}   count={searchMoreData[0][0]&&searchMoreData[0][0].media_count}/>

                <div className="categoryBooksData">
                    <div key={`book-${Math.random() * 10}`} className="">

                    {searchMoreData[0][0] && 
                        Array.from({ length: Math.ceil(searchMoreData[0][0].value.length / 3) }, (_, i) => (
                        <div key={`book-group-${i}`} style={{position:"relative"}}>
                            <div>               
                            {searchMoreData[0][0].value.slice(i * 3, i * 3 + 3).map((subbook, subIndex) => (
                        <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                            ))}
                            <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                            </div>
            


                        </div>
                        ))
                    }

                    </div>
                </div>
                <div className=' categoryBooksDataMoreResIpad' style={{display:"none"}}>
                    <div key={`book-${Math.random() * 10}`} className="">
                        { searchMoreData[0][0] && 
                            Array.from({ length: Math.ceil( searchMoreData[0][0].value.length / 2) }, (_, i) => (
                            <div key={`book-group-${i}`} style={{position:"relative"}}>
                                <div className='items'>               
                                { searchMoreData[0][0].value.slice(i * 2, i * 2+ 2).map((subbook, subIndex) => (
                                <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                                ))}

                        <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                    </div>




                    </div>
                    ))
                }

                </div>

                </div>
                <div className=' categoryBooksDataMoreResPhone' style={{display:"none"}}>
                    <div key={`book-${Math.random() * 10}`} className="">
                        { searchMoreData[0][0] && 
                            Array.from({ length: Math.ceil( searchMoreData[0][0].value.length / 1) }, (_, i) => (
                            <div key={`book-group-${i}`} style={{position:"relative"}}>
                                <div className='items'>               
                                { searchMoreData[0][0].value.slice(i * 1, i * 1+ 1).map((subbook, subIndex) => (
                                <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                                ))}

                        <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                    </div>




                        </div>
                        ))
                    }

                    </div>

                </div>

                <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage}/>
                </>
                ) :""
            }

            {
                id=="3"?
                <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0]&&searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className='categoryVideoData'>
                    {
                        searchMoreData[0][0]&&searchMoreData[0][0].value.map((item)=>{
                            return(
                                <CategoryVideoCard  url={item.url} desc={item.name} src={item.id} key={item.id} />         
                            )
                        })
                    }
                        </div>

                        <Paginate pageCount={pageCount} onPress={handleChangePage} />
                    </div>
                </>
                :""
            }

            {
                id=="2"?
                <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0]&&searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className='visits-imgs-cont categoryGalleryData'>
                    {
                        searchMoreData[0][0]&&searchMoreData[0][0].value.map((item,i)=>{
                            return(


                                <VisitsImg info={item.description} src={item.image!=null?item.image:`/assets/gallery-1.png`} key={item.id} handleImageClick={() => handleImageClicked(i)}/>                                            
                                )
                        })
                    }
                        </div>
                        {isOverlayVisible && (
                            <CategoryVisitOverlay closeOverlay={closeOverlay} visitsImgs={searchMoreData[0][0]} currentIndex={currentIndex}  />
                        )}
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

                    </div>
                </>
                :""
            }

            {
                id=="4"?
                <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0]&&searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className='articleItems categoryArticleData'>
                            
                    {
                        searchMoreData[0][0]&&searchMoreData[0][0].value.map((item)=>{
                            return(

                                <ArticlesCard  img={item.image!=null?item.image:`/assets/media/writings/article/articleItem.png`} title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />                     
                                
                                )
                        })
                    }
                        </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

                    </div>
                </>
                :""
            }

            {
            id=="5"?
                <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className=' categorySoundData'>
                                
                                {
                                searchMoreData[0][0].value&&searchMoreData[0][0].value.map((item,index)=>{
                                        return(
                                        
                                            <CategorySoundCard
                                            key={item.id}
                                            index={index}
                                            audio={item.url}
                                            title={item.name}
                                            isPlaying={currentPlayingIndex === index}
                                            hasPrev={index > 0} // Only show prev if there's a previous item
                                            hasNext={index < searchMoreData[0][0].value.length - 1} // Ensure next is calculated after slice
                                            onPlay={handlePlay}
                                            onPrev={handlePrev}
                                            onNext={handleNext}
                                            ref={(el) => (playersRef.current[index] = el)} // Store refs
                                        />        
                                                        
                                            )
                                    })
                                }
                            </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

                    </div>
                </>
            :""
            }

            {
            id=="10"?
                <>
                <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0].media_count}/>
                <div className="categoryRow">
                    <div className='sayingsPhotoItems categoryPhotoSayingsData'>
                        
                {
                    searchMoreData[0][0].value&&searchMoreData[0][0].value.map((item)=>{
                        return(

                            item.sharepoint_image&& <SayingsPhotoCard src={item.image!=null?item.image:`/assets/media/writings/Sayings/img1.png`} key={item.id}/>                               )
                    })
                }
                    </div>
                    <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

                </div>
                </>
                :""
            }

            {
                id=="12"?
                <>
                        <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}  count={searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className=' PoemsItems categoryPoemsData'>
                            
                    {
                        searchMoreData[0][0].value&&searchMoreData[0][0].value.map((item)=>{
                            return(

                                <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />                                            
                                )
                        })
                    }
                        </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage}/>

                    </div>
                </>
                :""
            }

            {
                id=="14"?
                <>
                        <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}   count={searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className='questionsItems categoryQuestionsData'>
                            
                    {
                        searchMoreData[0][0].value&&searchMoreData[0][0].value.slice(0,3).map((item)=>{
                            return(

                                <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id} checkAudio={item.sharepoint_uodated} />     
                                    
                                )
                        })
                    }
                        </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

                    </div>
                </>
                :""
            }

            {
                id=="15"?
                <>
                    <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}   count={searchMoreData[0][0].media_count}/>
                    <div className="categoryRow">
                        <div className='eventsItems categoryHappenData'>
                                    
                                    {
                                    searchMoreData[0][0].value&&searchMoreData[0][0].value.slice(0,2).map((item)=>{
                                            return(
                                                <EventCard date={item.date} title={item.name} desc={item.event} img={item.image!=null?item.image:`/assets/media/writings/article.png`}  key={item.id}/>            
                                                
                                                )
                                        })
                                    }
                            </div>
        
                        <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage}/>

                    </div>
                </>
            :""
            }

            {
            id=="16"?
            <>
                <CategoryHeader cate={searchMoreData[0][0]&&searchMoreData[0][0].key}   count={searchMoreData[0][0].media_count}/>
            <div className="categoryRow">
                <div className='docsItems categoryDocsData'>
                            
                            {
                                searchMoreData[0][0].value&&searchMoreData[0][0].value.map((item,index)=>{
                                    return(
                                        <React.Fragment key={item.id}>
                                        <DocsCard src={item.image!=null?item.image:`/assets/media/writings/docs/docs.png`} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index,searchMoreData[0][0].value , localStorage.getItem("search"))}  />
                                        {isOverlayVisibleDocs && (
                                        <DocOverlay
                                        closeOverlay={closeOverlayDocs}
                                        img={clickedImage}
                                        cate={clickedCategory}
                                        imgs={overlayImgs}
                                        initialSlide={clickedImageIndex}
                                    />                                                
                                    )}
                                    </React.Fragment>                                                    
                                        )
                                })
                            }
                    </div>
                <Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />

            </div>
            </>
            :""
            }

            </>
                 
)  }                      
              
                    
               
            
        </div>
        <ToastContainer />

    </div>
  )
}
