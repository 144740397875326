import React, { useState } from 'react'
import './Header.scss'
import './Header-res.scss'

import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';  
import 'primeicons/primeicons.css';  
import 'primeflex/primeflex.css';

import HeaderTop from './HeaderTop';
import HeaderDropdownOne from './HeaderDropdownOne';
import HeaderDropdownTwo from './HeaderDropdownTwo';
import HeaderContent from './HeaderContent';
import HeaderRes from './HeaderRes';
export default function Header() {

  window.addEventListener('scroll', () => {
    const header = document.querySelector('.header-bottom');
    if (window.scrollY > 100) {
      header.classList.add('fixed');
    } else {
      header.classList.remove('fixed');
    }
  });

    const [openHeaderOne, setOpenHeaderOne] = useState(false);
    const [openHeaderTwo, setOpenHeaderTwo] = useState(false);
    function openHeader1() {
        setOpenHeaderOne(!openHeaderOne);
        setOpenHeaderTwo(false);
    }
    function openHeader2() {
        setOpenHeaderTwo(!openHeaderTwo);
        setOpenHeaderOne(false);
        

    }
    const closeWrapper = () =>{
        setOpenHeaderOne(false);
        setOpenHeaderTwo(false);
        if(document.querySelector(".overlayLight")){
            document.querySelector(".overlayLight").style.display = "none";

        }


    }
       
    return (
        <header className='header'>

            <HeaderTop closeDp={closeWrapper}/>


            <div className="header-bottom">

                <div className="header-bottom-wrapper">
                    <HeaderDropdownOne  openHeaderTwo={openHeaderTwo} setOpenHeaderTwo={setOpenHeaderTwo} closeDp={closeWrapper}/>
                    <HeaderDropdownTwo  openHeaderOne={openHeaderOne} setOpenHeaderOne={setOpenHeaderOne} closeDp={closeWrapper}/>
                    <HeaderContent openHeader1={openHeader1} openHeader2={openHeader2} setOpenHeaderTwo={setOpenHeaderTwo} setOpenHeaderOne={setOpenHeaderOne} openHeaderOne={openHeaderOne} openHeaderTwo={openHeaderTwo} closeDp={closeWrapper} />
                    <HeaderRes/>
                </div>
            </div>
        </header>
    )
}
