import { faPlay, faPlus ,faMinus,faPause} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'

export default function QuestionCard({ques,answer,audio,id,x,checkAudio}) {
    const sound = useRef();
    const imgs = useRef();
    const quesDP = useRef();
    const iconDisplay = useRef();
    const question = useRef();
    const [iconShow, setIconShow] = useState(faPlus); 
    const [isPlaying, setIsPlaying] = useState(false); 
    const onToggleQuestion = () => {
      const isExpanded = quesDP.current.style.height == "140px";

      if (isExpanded) {
        quesDP.current.style.height = "0";
        quesDP.current.style.overflow = "hidden";
        iconDisplay.current.style.backgroundColor = "#fff";
        iconDisplay.current.style.color = "#000";
        setIconShow(faPlus);
        sound.current.pause();
        setIsPlaying(false);
    
        if (window.innerWidth <= 662) {
    
                
          question.current.classList.remove("expanded");
        }
    
    } else {
        quesDP.current.style.height = "140px";
        quesDP.current.style.overflowY = "scroll";
        iconDisplay.current.style.backgroundColor = "#000";
        iconDisplay.current.style.color = "#fff";
        setIconShow(faMinus);
    
        if (window.innerWidth <= 662) {
    
              question.current.classList.add("expanded");

        }
    }
    };
    const onHandlePlay = () => {
        const allAudios = document.querySelectorAll(".audioQ");
      
        // إيقاف جميع الأصوات الأخرى
        allAudios.forEach((aud) => {
          if (aud !== sound.current) {
            aud.pause();
            aud.currentTime = 0; 
            aud.closest(".questionCard")?.querySelector(".playIcon")?.setAttribute("src", "/assets/playing.png");
          }
        });
      
        if (!sound.current.paused) {
          sound.current.pause();
          setIsPlaying(false);
          imgs.current.src = "/assets/playing.png"; 
        } else {
          sound.current.play();
          setIsPlaying(true);
          imgs.current.src = "/assets/pauser.png"; 
          quesDP.current.style.height = "140px";
          iconDisplay.current.style.backgroundColor = "#000";
          quesDP.current.style.overflowY = "scroll";
          iconDisplay.current.style.color = "#fff";
        setIconShow(faMinus);

        }
      };


  
    return (
      <div className="questionCard">
        <div className="questionCardContent">
       
          <div className="questionCardContentRight" onClick={onToggleQuestion}>
            {
              checkAudio?
                <img
                src={isPlaying ? "/assets/pauser.png" : "/assets/playing.png"}
                alt="iconRun"
                className="playIcon"
                ref={imgs}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent toggle when clicking play/pause
                  onHandlePlay();
                }}
              />
              :
              ""
            }
           
            <p className="questionCardQues" ref={question}>{ques}</p>
          </div>
        
          <FontAwesomeIcon
            icon={iconShow}
            className="PlusIcon"
            ref={iconDisplay}
            onClick={onToggleQuestion}
          />
        </div>
        <div className="questionCardDropdown" ref={quesDP} >
          <p className='scrolled'>{answer}</p>
          <audio controls ref={sound} className="audioQ">
            <source src={audio} type="audio/ogg" />
            <source src={audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    );
  }
