import React, { useState, useContext, useEffect } from 'react'
import './DisplayGallery.scss'
import { Link, useLocation, useParams } from 'react-router-dom'
import { UserContext } from '../Context/UserContext';
import axios from 'axios';

export default function DisplayGallery() {
    const { baseURL } = useContext(UserContext);
    const [displayGallery, setDisplayGallery] = useState('');
    const location = useLocation();
    const extraContent = location.state?.extraContent;
    const name = location.state?.name;

    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });
    
    // window.onload = function () {
    //     window.history.replaceState(null, null, ' ');
    // };
    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div className='display-gallery'>
            <div className="display-gallery-wrapper">
                <p className='display-gallery-about'>{name}</p>


                <div className="display-gallery-imgs-cont">


                    <div className="display-gallery-left-imgs">
                        {extraContent && extraContent.map((image, index) => {

                            if (image.children.length === 0) {
                                return (
                                    <Link to={`/gallerymedia/${image.id}`} className="display-gallery-img-cont4" key={index}>

                                        <img src={image.image} alt="" className='display-gallery-img4' />
                                        <p className='display-gallery-visits'> {image.name}</p>
                                    </Link>
                                );
                            }
                            else {
                                return(
                                <Link to={`/displaygallery`} state={{ extraContent: image.children }}  className="display-gallery-img-cont4" key={index}>

                                    <img src={image.image} alt="" className='display-gallery-img4' />
                                    <p className='display-gallery-visits'> {image.name}</p>
                                </Link>
                                )
                            }
                        })}
                        {/* <Link to='/visits' className="display-gallery-img-cont4">

                            <img src="./assets/d-g-6.png" alt="" className='display-gallery-img4' />
                            <p className='display-gallery-visits'> زيارات البابا الرعوية
                                الداخلية</p>
                        </Link>
                        <Link to='/visits' className="display-gallery-img-cont4">

                            <img src="./assets/d-g-6.png" alt="" className='display-gallery-img4' />
                            <p className='display-gallery-visits'> زيارات البابا الرعوية
                                الداخلية</p>
                        </Link> */}

                    </div>

                </div>

            </div>
        </div>
    )
}
