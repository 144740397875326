import { faPlay, faPause, faForwardStep, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState, useContext, useEffect } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import PlayRightSide from './play/PlayRightSide';
import PlayLeftSide from './play/PlayLeftSide';
import { UserContext } from '../Context/UserContext';

const rewindimg = '/assets/left1.png';
const rewindimg2 = '/assets/right1.png';
const rewindimg3 = '/assets/play-black.png';
const repeat = '/assets/repeat-single.png';
const repeat2 = '/assets/shape.png';


export default function Play() {
    const { audioURL, audioList, setAudioList, currentAudioIndex, setCurrentAudioIndex, SendAudio, visibleItems2,sideMenu,visibleItems,setAudioURL,isUserInitiated, setIsUserInitiated } = useContext(UserContext);
    const playerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [checked, setChecked] = useState(true);
    const [url, setUrl] = useState(currentAudioIndex);
    const customPrevIcon = <FontAwesomeIcon icon={''} />;
    const customNextIcon = <FontAwesomeIcon icon={faForwardStep} />;
    const customForwardIcon2 = <img src={rewindimg2} alt="rewind" />;
    const customForwardIcon3 = <img src={rewindimg} alt="rewind" />;
    const customForwardIcon4 = <img src={rewindimg3} alt="rewind" className='play-btn' />;
    const customRepeat = <img src={repeat} alt="rewind" className='play-btn' />;
    const customRepeat2 = <img src={repeat2} alt="rewind" className='play-btn' />;



useEffect(()=>{
    if(currentAudioIndex != null){
        setChecked(true);
    }
    console.log(currentAudioIndex);
},[currentAudioIndex])
    const [isLoop, setIsLoop] = useState(false);
    const handleLoopToggle = () => {
        setIsLoop(!isLoop);
    };

    const [isMuted, setIsMuted] = useState(false);
    const handleMuteToggle = () => {
        if (playerRef.current) {
            playerRef.current.audio.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    const playNextAudio = () => {
        if (audioList && audioList.length > 0 && currentAudioIndex < audioList.length - 1) {
          const nextIndex = currentAudioIndex + 1;
          const nextAudio = audioList[nextIndex];
          setCurrentAudioIndex(nextIndex);
          SendAudio(nextAudio.url, nextAudio.id, nextAudio.image?nextAudio.image:"/assets/default/sounds/Audio-DF.png", nextAudio.name, nextAudio.bookmarkshow, audioList, nextIndex);
        }
      };
      
      const playPrevAudio = () => {
        if (audioList && audioList.length > 0 && currentAudioIndex > 0) {
          const prevIndex = currentAudioIndex - 1;
          const prevAudio = audioList[prevIndex];
          setCurrentAudioIndex(prevIndex);
          SendAudio(prevAudio.url, prevAudio.id, prevAudio.image?prevAudio.image:"/assets/default/sounds/Audio-DF.png", prevAudio.name, prevAudio.bookmarkshow, audioList, prevIndex);
        }
      };
     




      useEffect(() => {
        // Load audioURL from sessionStorage only once on mount
        const savedAudio = sessionStorage.getItem('audioURL');
        const currentAudioIndex = sessionStorage.getItem('currentAudioIndex');
        const audioList = sessionStorage.getItem('audioList');

        if (savedAudio) {
            setAudioURL(JSON.parse(savedAudio));
            setCurrentAudioIndex(JSON.parse(currentAudioIndex));
            setAudioList(JSON.parse(audioList));
            setIsUserInitiated(false);
        }
    }, []); // Empty dependency array

    useEffect(() => {
        // Save audioURL to sessionStorage whenever it changes
        if (audioURL.url) {
            sessionStorage.setItem('audioURL', JSON.stringify(audioURL));
            sessionStorage.setItem('currentAudioIndex', JSON.stringify(currentAudioIndex));
            sessionStorage.setItem('audioList', JSON.stringify(audioList));

        }
    }, [audioURL]);

    return (
        <>
            {audioURL.url && checked&& (

                <div className='play-complex-play'>
                    <div className="play-complex-play-cont">

                      

                        <div className='play-play-cont'>

                            <AudioPlayer
                                key={audioURL.id}
                                src={audioURL.url}
                                ref={playerRef}
                                autoPlay={isUserInitiated}
                                customAdditionalControls={[
                                    <div onClick={handleLoopToggle} key="loop-toggle" className='play-loop'>
                                        {isLoop ? customRepeat : customRepeat2}
                                    </div>,
                                    <div key="prev" onClick={playNextAudio} className='play-prev'>
                                        {customForwardIcon3} {/* Your custom icon for previous */}
                                    </div>,
                                    <div key="next" onClick={playPrevAudio} className='play-next'>
                                        {customForwardIcon2} {/* Your custom icon for next */}
                                    </div>

                                ]}
                                customVolumeControls={[]}
                                showJumpControls={false}
                                showDownloadProgress={false}
                                showFilledProgress={true}

                                customIcons={{
                                    play: customForwardIcon4,
                                    prev: "", 
                                    next: "", 
                                    rewind: "", 
                                    forward: "", 
                                }}
                                onPlay={() => setIsPlaying(true)}
                                onPause={() => setIsPlaying(false)}
                                onEnded={() => {
                                    if (isLoop) {
                                        playerRef.current.audio.current.play();
                                    }
                                }}
                            />
                        </div>
                        <PlayRightSide handleMuteToggle={handleMuteToggle} isMuted={isMuted} setIsMuted={setIsMuted} audioURL={audioURL} />
                        <img src="/assets/share/close.png" alt="" className='sharedCancel' style={{ 
                        position:"absolute",
                        top: window.innerWidth < 768 ? "50%" : "35%", // Adjust top dynamically based on screen width
                        right:"30px",
                        width:"20px",
                        cursor:"pointer",


                        }} onClick={()=>{
                            setChecked(false);
                            setUrl(null);
                        }} />
                        
                    </div>
                </div>
            )}
        </>
    )
}
