
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../Context/UserContext';

export default function RecentlySoundsCard({ img, title, desc, onClick,id,bookmarkshow,fetchAudio }) {
    const token = localStorage.getItem("token");
    const { baseURL,handleImageError,fetchbook,getClickedCategory } = useContext(UserContext);

   // Use local state for fast UI update
   const [localBookmark, setLocalBookmark] = useState(bookmarkshow);
   useEffect(() => {
    setLocalBookmark(bookmarkshow || false);
}, [bookmarkshow]);
   const AddToBook = (media_id, isBooked) => {
       // 1️⃣ Optimistic UI: Update UI immediately
       setLocalBookmark(!isBooked);

       // 2️⃣ API Request to update server
       const axiosRequest = isBooked
           ? axios.delete(`${baseURL}/bookmarks`, {
               data: { media_id },
               headers: { Authorization: `Bearer ${token}` },
           })
           : axios.post(
               `${baseURL}/bookmarks`,
               { media_id },
               { headers: { Authorization: `Bearer ${token}` } }
           );

       axiosRequest
           .then(() => {
               console.log("Bookmark updated successfully");
               fetchAudio(); // Refresh audio list after successful update
               fetchbook(); // Refresh bookmarks globally
               getClickedCategory();
           })
           .catch((error) => {
               console.error("Error updating bookmark:", error);
               
               // 3️⃣ Revert UI if API request fails
               setLocalBookmark(isBooked);
           });
   };
    const fallbackImage = `${process.env.PUBLIC_URL}/assets/header-papa.png`;

    useEffect(()=>{
        fetchAudio();
        fetchbook();
        getClickedCategory();
    },[])
  
    // Toggle bookmark instantly
    return (
        <div className='recentlySoundsCard'>
            <div className='recentlySoundsCardImg' onClick={onClick}>
                <img  src={img || fallbackImage} alt='recentlySoundImg' onError={handleImageError}/>
                <div className='recentlySoundImgOverlay'>
                    <div className='recentlySoundImgOverlayContent'>
                        <FontAwesomeIcon icon={faPlay} className='playSoundIcon' />
                    </div>
                </div>
            </div>
            <h4 className='recentlySoundTitle'>{title}</h4>
            <p className='recentlySoundsCardDesc'>{desc}</p>

            {token&&(
            <div className='soundSettingImg'>
                      <img
                        src={localBookmark ? `${process.env.PUBLIC_URL}/assets/bookmark-yellow.png` : `${process.env.PUBLIC_URL}/assets/archive.png`}
                        alt="Bookmark Icon"
                        className='videos-video-archive'
                        onClick={() => AddToBook(id, localBookmark)}
                    />
            </div>
            )}
        </div>
    )
}
