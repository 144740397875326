import React from 'react'
import WritingsCard from './WritingsCard'
import './WritingsContainer.css'
import '../Responsive/WritingsContainerRes.css'
import WritingsHook from '../../../Logic/Media/Writings/WritingsHook'
export default function WritingsContainer() {
  const [writingsData]=WritingsHook()
  return (
    <div className='writingsContainer'>
        <div className='Container'>
            <div className='writingsItems'>
              {
               writingsData && Array.isArray(writingsData[0])?writingsData[0].map((item)=>{
                  return (
                    <WritingsCard  img={item.image} title={item.name} desc={item.description} src={`/media/${item.id}`} key={item.id} />
                  )
                }):""
              }

            </div>
        </div>
    </div>
  )
}
