import React, { useContext,useState,useEffect } from 'react'
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from '../../Context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import BookmarkHook from '../../../Logic/Bookmark/BookmarkHook';
import MultimediaShare from '../../Utility/MultimediaShare';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";;
export default function PlayRightSide({ handleMuteToggle, isMuted, audioURL,setIsMuted }) {
    const navigate = useNavigate();
    const { baseURL, fetchAudio, fetchbook, handleImageError,getClickedCategory } = useContext(UserContext);
    const token = localStorage.getItem('token');
    const  [data,videosFavData,soundsFavData,pageCount,handleChangePage,handleDeleteFav,handleChooseFav,getHeaders,headFav,fav,ele,check]= BookmarkHook();
    // const AddToBook = (media_id, isBooked) => {
    //     const axiosRequest = isBooked
    //         ? axios.delete(`${baseURL}/bookmarks`, {
    //             data: { media_id },
    //             headers: { 'Authorization': `Bearer ${token}` }
    //         })

    //          : axios.post(`${baseURL}/bookmarks`,
    //             { media_id },
    //             { headers: { 'Authorization': `Bearer ${token}` } }
    //         );

    //     axiosRequest
    //         .then(() => {
    //             console.log("zqqqqqqqqqqq");
    //             fetchAudio();
    //             fetchbook();

    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //         if(axiosRequest == isBooked){
    //             handleChooseFav(5)
    //         }
    // };
     // Use local state for fast UI update
     const [localBookmark, setLocalBookmark] = useState(audioURL?.bookmarkshow || false);
 // Sync `localBookmark` with `audioURL.bookmarkshow` when `audioURL` changes
useEffect(() => {
    setLocalBookmark(audioURL?.bookmarkshow || false);
    getClickedCategory(1);
     fetchAudio(); // Refresh audio list
            fetchbook(); // Refresh bookmarks globally
            handleChooseFav(5);
    console.log("yeee");
}, [audioURL?.bookmarkshow]);

const AddToBook = (media_id, isBooked) => {
    // Optimistic UI update
    setLocalBookmark(!isBooked);

    // API Request
    const axiosRequest = isBooked
        ? axios.delete(`${baseURL}/bookmarks`, {
            data: { media_id },
            headers: { Authorization: `Bearer ${token}` },
        })
        : axios.post(
            `${baseURL}/bookmarks`,
            { media_id },
            { headers: { Authorization: `Bearer ${token}` } }
        );

    axiosRequest
        .then(() => {
            console.log("Bookmark updated successfully");
            fetchAudio(); // Refresh audio list
            fetchbook(); // Refresh bookmarks globally
            handleChooseFav(5);
        })
        .catch((error) => {
            console.error("Error updating bookmark:", error);
            setLocalBookmark(isBooked); // Revert UI on failure
        });
        if(axiosRequest == isBooked){
            handleChooseFav(5)
        }
};
    const fallbackImage = `${process.env.PUBLIC_URL}/assets/header-papa.png`;
    const currentUrl = window.location.pathname;
    const ShareHandler = (audio) => {

      

        // Add the id to the URL
        navigate(`${currentUrl}?id=${audio.id}`, { state: { audio } });
    };
    const [showMessage, setShowMessage] = useState(false);

    const handleCopy = () => {
        setShowMessage(true); // Show the message
        setTimeout(() => setShowMessage(false), 3000); // Hide the message after 3 seconds
    };

useEffect(()=>{
    setIsMuted(false);
},[audioURL])

// sound Share
const [isOverlayBlockMediaShare, setOverlayBlockMediaShare] = useState(false);
const openMediaShareBlock = () => {
    document.body.classList.add("no-scroll");
    setOverlayBlockMediaShare(true);
};
const closeMediaShareBlock=()=>{
  document.body.classList.remove("no-scroll");
  setOverlayBlockMediaShare(false)
}
    return (
        <div className="play-info-cont">
            <div className="play-info-flex3">
                {/* <CopyToClipboard text={`https://popeshenoudasitetest.msol.dev/media/5?id=${audioURL.id}`} onCopy={handleCopy} >
                <img
                    src={`${process.env.PUBLIC_URL}/assets/info-share.png`}
                    alt=""
                    className='play-info-love'
                  
                />
                </CopyToClipboard> */}
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/info-share.png`}
                            alt=""
                            className='play-info-love'
                            onClick={()=>setOverlayBlockMediaShare(true)}
                        />
                        {
                        isOverlayBlockMediaShare&& <MultimediaShare closeOverlay={closeMediaShareBlock}  text={`https://popeshenoudasitetest.msol.dev/media/5?id=${audioURL.id}`}/>
                        }  
                {/* {showMessage && (
                <div
                    style={{
                        position: "absolute",
                        top: "-30px",
                        backgroundColor: "#4caf50",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                        zIndex: 10,
                    }}
                >
                    Link Copied!
                </div>
            )} */}
                {token && (
                          <img
                          src={localBookmark 
                              ? `${process.env.PUBLIC_URL}/assets/bookmark-yellow.png` 
                              : `${process.env.PUBLIC_URL}/assets/archive.png`} 
                          alt="" 
                          className='footer-video-archive'
                          onClick={() => AddToBook(audioURL.id, localBookmark)}
                      />
                )}
                {isMuted ? (
                    <FontAwesomeIcon
                        icon={faVolumeMute}
                        className="volume-img"
                        onClick={handleMuteToggle}
                    />
                ) : (
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/volume-btn.png`}
                        alt="volume"
                        className="volume-img"
                        onClick={handleMuteToggle}
                    />
                )}
            </div>

            <ToastContainer /> 

            <div className="play-info-flex2">
                <div className="play-info-flex">
                    <p className='play-info-p-1'>{audioURL.name}</p>
                    <p className='play-info-p-2'>قداسة البابا شنوده الثالث</p>
                </div>
                <img src={audioURL.image || fallbackImage} alt="" className='play-info-img' onError={() => handleImageError} />

            </div>
     


        </div>
    )
}
