//Authentication
export const LOGIN_USER="LOGIN_USER"
export const CREATE_USER="CREATE_USER"
export const LOGOUT_USER="LOGOUT_USER"
export const FORGET_PASSWORD="FORGET_PASSWORD"
export const REST_PASSWORD="REST_PASSWORD"
export const GET_USER_DATA="GET_USER_DATA"
export const LOGIN_WITH_GOOGLE="LOGIN_WITH_GOOGLE"
export const DELETE_USER="DELETE_USER"
//Home
export const GET_HOME_DATA="GET_HOME_DATA"

//Media

//Writings
export const GET_ALL_WRITINGS="GET_ALL_WRITINGS"

//Poems
export const GET_ALL_POEMS="GET_ALL_POEMS"
export const GET_ONE_POEM="GET_ONE_POEM"
export const GET_FILTER_POEMS="GET_FILTER_POEMS"
export const GET_Attr_POEMS="GET_Attr_POEMS"

//Sayings
export const GET_ALL_SAYINGS_WRITTEN="GET_ALL_SAYINGS_WRITTEN"
export const GET_ALL_SAYINGS_PHOTO="GET_ALL_SAYINGS_PHOTO"
export const GET_FILTER_SAYINGS_WRITTEN="GET_FILTER_SAYINGS_WRITTEN"
export const GET_FILTER_SAYINGS_PHOTO="GET_FILTER_SAYINGS_PHOTO"
export const GET_ATTR_SAYINGS_WRITTEN="GET_ATTR_SAYINGS_WRITTEN"
export const GET_ATTR_SAYINGS_PHOTO="GET_ATTR_SAYINGS_PHOTO"

//Articles
export const GET_ALL_ARTICLES="GET_ALL_ARTICLES"
export const GET_ALL_ARTICLES_FOR_ONE="GET_ALL_ARTICLES_FOR_ONE"
export const GET_ONE_ARTICLE="GET_ONE_ARTICLE"
export const GET_FILTER_ARTICLES="GET_FILTER_ARTICLES"
export const GET_FILTER_ARTICLES_FOR_ONE="GET_FILTER_ARTICLES_FOR_ONE"
export const GET_ATTR_ARTICLES="GET_ATTR_ARTICLES"
export const GET_ATTR_ARTICLES_FOR_ONE="GET_ATTR_ARTICLES_FOR_ONE"


//Happen
export const GET_ALL_HAPPENS="GET_ALL_HAPPENS"
export const GET_ALL_HAPPENS_FOR_ONE="GET_ALL_HAPPENS_FOR_ONE"
export const GET_ONE_HAPPEN="GET_ONE_HAPPEN"
export const GET_FILTER_HAPPEN="GET_FILTER_HAPPEN"

//Questions
export const GET_ALL_QUESTIONS="GET_ALL_QUESTIONS"
export const GET_FILTER_QUESTIONS="GET_FILTER_QUESTIONS";
export const GET_Attr_QUESTIONS="GET_Attr_QUESTIONS";

//Docs
export const GET_ALL_DOCS="GET_ALL_DOCS"
export const GET_FILTER_DOCS="GET_FILTER_DOCS"
export const GET_ATTR_DOCS="GET_ATTR_DOCS"
export const GET_DOCS_Details="GET_DOCS_Details"
export const GET_DOCS_ATTR_Details="GET_DOCS_ATTR_Details"

//Videos
export const GET_FILTER_VIDEOS="GET_FILTER_VIDEOS";
export const GET_ATTR_VIDEOS="GET_ATTR_VIDEOS";

// Category
export const GET_DATA_BY_CATEGORY="GET_DATA_BY_CATEGORY"
export const GET_DATA_BY_CATEGORY_MORE="GET_DATA_BY_CATEGORY_MORE"
export const GET_CATEGORY_Filter_DATA="GET_CATEGORY_Filter_DATA"


//Search
export const GET_SEARCH_RESULT="GET_SEARCH_RESULT"
export const GET_SEARCH_MORE="GET_SEARCH_MORE"
export const GET_SEARCH_Filter_DATA="GET_SEARCH_Filter_DATA"

//Profile
export const GET_USER_INFO="GET_USER_INFO"
export const UPDATE_USER_INFO="UPDATE_USER_INFO"
export const UPDATE_USER_IMG="UPDATE_USER_IMG"
export const DELETE_USER_IMG="DELETE_USER_IMG"

//Bookmark
export const GET_BOOKMARK_DATA="GET_BOOKMARK_DATA"
export const GET_ONE_BOOKMARK_DATA="GET_ONE_BOOKMARK_DATA"
export const ADD_BOOKMARK_DATA="ADD_BOOKMARK_DATA"
export const DELETE_BOOKMARK_DATA="DELETE_BOOKMARK_DATA"

//Random Qoute
export const GET_RANDOM_QUOTE="GET_RANDOM_QUOTE"
