import React from 'react'
import './Spinner.css'
export default function Spinner() {
  return (
<div class="loading-dots">
  <div></div>
  <div></div>
  <div></div>
</div>
  )
}
