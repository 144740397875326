import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPoemsAction, getPoemsAttrAction, getpoemsAttrAction } from '../../../../redux/actions/Media/Writings/PoemsActions';
export default function PoemsHook() {
    const dispatch=useDispatch();
    const notFound=useRef()
    const [activePage,setActivePage]=useState(1);


//authReducer
const getData=async()=>{
    setActivePage(1)
    if (localStorage.getItem("poemsAttr")) {
        const cateID = JSON.parse(localStorage.getItem("poemsAttr"));
         await dispatch(getPoemsAttrAction(6,1,cateID));
          
    } else {
       
        await dispatch(getAllPoemsAction(6,1));
   
    }
}

useEffect(()=>{
    getData();
},[localStorage.getItem("poemsAttr")])
const res=useSelector(state=> state.PoemsReducer.poemsData);
const resFilter=useSelector(state=> state.PoemsReducer.attrPoems);

let poemsData=[];
let filterGroup=[];
let attrGroup=[]
try {
    if(localStorage.getItem("poemsAttr")){
        if(resFilter){
            if(resFilter.data.data){
                poemsData.push(resFilter.data.data)
                if(resFilter.data.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(resFilter.addetionals.filterable_categories){
                filterGroup=[...resFilter.addetionals.filterable_categories]
                attrGroup=[...resFilter.addetionals.filterable_attrebutes]
            }
        }
    }else{
        if(res){
            if(res.data.data){
                poemsData.push(res.data.data)
                if(res.data.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(res.addetionals.filterable_categories){
                filterGroup=[...res.addetionals.filterable_categories]
                attrGroup=[...res.addetionals.filterable_attrebutes]

            }
        }
    }

} catch (e) {
    console.log(e);
}

let pageCount=0;

try {
    if(localStorage.getItem("poemsAttr")){
        if(resFilter){
            if(resFilter.data.pagination){
                pageCount=Math.ceil(resFilter.data.pagination.total/resFilter.data.pagination.per_page)
                
            }
        }
    }else{
        if(res){
            if(res.data.pagination){
                pageCount=Math.ceil(res.data.pagination.total/res.data.pagination.per_page)
            }
        }
    }

} catch (error) {}


const handleChangePage= async(count)=>{
    setActivePage(count)
    if(localStorage.getItem("poemsAttr")){
        let cateID=JSON.parse(localStorage.getItem("poemsAttr"))
        await dispatch(getPoemsAttrAction(6,count,cateID))
    }else{
        await dispatch(getAllPoemsAction(6,count))
    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 

}

return [poemsData,pageCount,filterGroup,attrGroup,handleChangePage,getData,notFound,activePage]
}


