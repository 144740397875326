import React from 'react'
import './CategoryHeader.css'
import { Link } from 'react-router-dom'
export default function CategoryHeader({cate,count,src}) {
  return (
    <div className='categoryHeader'>
        <div className='categoryRight'>
          <span className='categoryHeaderCounts'>({count})</span>
          <h2 className='categoryHeaderTitle'>{cate}</h2>
        </div>
        <Link to={src} className='media_more_btn_res' style={{display:"none"}}>عرض المزيد</Link>

    </div>
  )
}
