import React, { useEffect } from 'react'
import './DocsPage.css'
import DocsContainer from '../../../../components/Media/Writings/Docs/DocsContainer'
import DocsFilter from '../../../../components/Media/Writings/Docs/DocsFilter'
export default function DocsPage() {
  window.addEventListener('hashchange', function (e) {
    e.preventDefault();
});

window.onload = function () {
    window.history.replaceState(null, null, ' '); // Clear hash if any
};
useEffect(() => {
  window.scrollTo(0, 0); // Ensure scroll starts at the top
}, []);
  return (
    <div className='docsPage'>
        <DocsContainer />
    </div>
  )
}
