import React, { useEffect } from 'react'
import './ArticlesPage.css'

import ArticlesFilter from '../../../../components/Media/Writings/Articles/ArticlesFilter'
import ArticlesContainer from '../../../../components/Media/Writings/Articles/ArticlesContainer'
import ArticlesHook from '../../../../Logic/Media/Writings/Articles/ArticlesHook'
import Spinner from '../../../../components/Utility/Spinner'
export default function ArticlesPage() {
  const  [articlesData,filterGroup,attrGroup,notFound,getData]=ArticlesHook();
  window.addEventListener('hashchange', function (e) {
    e.preventDefault();
});

window.onload = function () {
    window.history.replaceState(null, null, ' '); // Clear hash if any
};
useEffect(() => {
  window.scrollTo(0, 0); // Ensure scroll starts at the top
}, []);
  return (
    <div className='articlesPage'>
       <div className='Container'>
        <ArticlesFilter  cates={filterGroup} attrs={attrGroup} />
       </div>
        {
          articlesData && Array.isArray(articlesData[0])?articlesData[0].map((item)=>{
            return(
              <ArticlesContainer header={item.title} data={item.articles} key={item.id} />
            )
          }):<Spinner />
        }
         <p  className='noResult' ref={notFound} style={{display:"none"}}>لا يوجد نتائج</p>
    </div>
  )
}
