import React, { useContext, useEffect, useState, useRef } from 'react';
import './Videos.scss'
import './Videos-res.scss'
import './Responsive/VideosRes.css'
import VideosFIlter from './VideosFIlter';
import axios from 'axios';
import { UserContext } from '../Context/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Paginate from '../Utility/Paginate';
import { OverlayPanel } from 'primereact/overlaypanel';
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomModal from './CustomModal';
import VideosHooks from '../../Logic/Media/Videos/VideosHooks';
import Spinner from '../Utility/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import MultimediaShare from '../Utility/MultimediaShare';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";;
export default function Videos() {
    const[baseURL,videos,categories,attrs,cateID,navigate,pag,page,op,token,visibleModal,fetchVideos,handlePageChange,AddToBook,copiedProductIds,bookMarkRef,handleCopy,handleShareClick,setCategories,setVideos,notFound,notFoundRes,activePage,setVisibleModal]=VideosHooks();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true);
        }, 1000);
        setLoading(false)
        return () => clearTimeout(timer);
    }, []);

    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });
    
    window.onload = function () {
        window.history.replaceState(null, null, ' '); // Clear hash if any
    };
    useEffect(() => {
      window.scrollTo(0, 0); // Ensure scroll starts at the top
    }, []);   
    const [bookmarkedVideos, setBookmarkedVideos] = useState({});

    useEffect(() => {
        if (videos) {
            const initialBookmarks = {};
            videos.media.forEach(mediaItem => {
                mediaItem.media.forEach(video => {
                    initialBookmarks[video.id] = video.bookmarkshow; 
                });
            });
            setBookmarkedVideos(initialBookmarks);
        }
    }, [videos]); 

    // Toggle bookmark instantly
    const toggleBookmark = (video) => {
        const newStatus = !bookmarkedVideos[video.id];
        setBookmarkedVideos((prevState) => ({
            ...prevState,
            [video.id]: newStatus, 
        }));

        AddToBook(video.id, video.bookmarkshow);
    };
    const location = useLocation();

    // useEffect(()=>{
    //     if(location.pathname=="/media/3"){
    //         if(document.querySelector(".p-multiselect-panel")){
    //             document.querySelector(".p-multiselect-panel").style.width="100px";

    //         }
    //       }
    // },[location.pathname])

    const [isOverlayBlockMediaShare, setOverlayBlockMediaShare] = useState(false);
    const [videoID, setVideoID] = useState();
    const openMediaShareBlock = () => {
        document.body.classList.add("no-scroll");
        setOverlayBlockMediaShare(true);
    };
    const closeMediaShareBlock=()=>{
      document.body.classList.remove("no-scroll");
      setOverlayBlockMediaShare(false)
    }
    
    let checkLogin=localStorage.getItem("token")||"";

    const [availability, setAvailability] = useState({}); // Store availability by video URL

    const checkVideo = (videoUrl) => {
      const idMatch = videoUrl.match(/(?:\/embed\/|v=|youtu\.be\/)([^?&]+)/);
      if (!idMatch || !idMatch[1]) {
        setAvailability((prev) => ({ ...prev, [videoUrl]: false }));
        return;
      }
  
      const videoId = idMatch[1];
      const thumbUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  
      const img = new Image();
      img.onload = function () {
        const exists = !(img.width === 120 && img.height === 90);
        setAvailability((prev) => ({ ...prev, [videoUrl]: exists }));
      };
      img.onerror = function () {
        setAvailability((prev) => ({ ...prev, [videoUrl]: false }));
      };
      img.src = thumbUrl;
    };
  
    useEffect(() => {
      if (!videos) return;
      videos.media.forEach((mediaItem) => {
        mediaItem.media.forEach((video) => {
          if (!(video.url in availability)) {
            checkVideo(video.url);
          }
        });
      });
    }, [videos]);
    const modalRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
          const clickedOutsideModal = modalRef.current && !modalRef.current.contains(event.target);
          const clickedShareCard = event.target.closest('.shareCard');
          if (clickedOutsideModal && !clickedShareCard) {
            setVisibleModal(null); // Close the modal
            setOverlayBlockMediaShare(false); // Close the share if needed
          }
        }
      
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    return (
        <div className='videos'>
            <div className="videos-wrapper">
                <div className="videos-top-head">
                    <img src="/assets/videos-2.png" alt="" className='videos-top-img' />
                    <p className='videos-top-p'>فيديوهات مركز معلم الاجيال</p>
                    <img src="/assets/videos-1.png" alt="" className='videos-top-img' />
                </div>
                <div className="videos-top-head-res" style={{display:"none"}}>
                    <img src="/assets/videoBgLeft.png" alt="" className='videos-top-img' />
                    <p className='videos-top-p'>فيديوهات مركز معلم الاجيال</p>
                    <img src="/assets/videoBgRight.png" alt="" className='videos-top-img' />
                </div>
                <div className="videos-videos-big-cont fullVideos">
                <VideosFIlter cates={categories} attrs={attrs} fetchVideos={fetchVideos} notFound={notFound} />
                    <div className=''>
                                {videos && videos.media.map((mediaItem, index) => (
                                    <>
                                    <div key={index} className='videosContainer'>
                                        <p className='videos-about'>{mediaItem.value}</p>
                                        <div className="videos-videos-cont">
                                            {mediaItem.media.map((video, subIndex) => {

                                                let videoID = "";
                                                let videoSrc = "";
                                                let isAvailable = false;
                                                if (video?.url) {
                                                const match = video.url.match(/(?:youtu\.be\/|v=)([^&/]+)/);
                                                if (match && match[1]) {
                                                    videoID = match[1];
                                                    videoSrc = `https://www.youtube.com/embed/${videoID}`;
                                                    isAvailable = availability?.[video.url];
                                                }
                                                }

                                                return (
                                                    <div className='video-video-width' style={{ position: 'relative',  height: '343px' }} key={subIndex}>
                                                              {
                                                             isAvailable === undefined ? (
                                                                <p>Checking...</p>
                                                              ) : isAvailable ? (
                                                                <iframe
                                                                  className="video-video-frame"
                                                                  src={videoSrc}
                                                                  title={`YouTube video player ${video.name}`}
                                                                  frameBorder="0"
                                                                  allow="encrypted-media"
                                                                  allowFullScreen
                                                                />
                                                              ) : (
                                                                <video  controls  className='second_video_videos'>
                                                                <source src={video.second_url} type="video/mp4" />
                                                                </video>
                                                              )

                                                            }
                                                
                                                        <div className="videos-video-bottom-section">
                                                            <div className="videos-video-bottom">

                                                                <img src="/assets/dots.png" alt="" className='videos-video-dots' onClick={() => handleShareClick(video.id)} />
                                                                <div className="videos-video-archive3">
                                                                    <p className='videos-video-bottom-p'><Link to={`/displayvideo/${video.id}`}>{video.name}</Link></p>
                                                                    {
                                                                        checkLogin?
                                                                        <img   src={bookmarkedVideos[video.id] ? "/assets/bookmark-yellow.png" : "/assets/archive.png"} alt="" className='videos-video-archive'
                                                                        onClick={() => toggleBookmark(video)}
                                                                 />
                                                                        :""
                                                                    }
                                                                  
                                                                </div>

                                                                <CustomModal
                                                                    key={subIndex}
                                                                    isVisible={visibleModal === video.id}
                                                                
                                                                >
                                                                        <div ref={modalRef}>
                                                                            <p className='video-share' onClick={()=>{
                                                                                setOverlayBlockMediaShare(true)
                                                                            }}>مشاركة</p>
                                                                        </div>
                                                                     
                                                                    {
                                                                    isOverlayBlockMediaShare&& <MultimediaShare closeOverlay={closeMediaShareBlock}  text={`https://popeshenoudasitetest.msol.dev/displayvideo/${video.id}`}/>
                                                                     }           
                                                                </CustomModal>

                                                                {copiedProductIds[video.id] && (
                                                                    <span className='details-link'>Link copied!</span>
                                                                )}
                                                                                                                                                                                               
                                                            </div>



                                                            {/* <p className='videos-video-date'>{video.created_at}</p> */}
                                                        </div>
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                height: '240px',
                                                                background: 'rgba(255, 255, 255, 0)',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => navigate(`/displayvideo/${video.id}`)}
                                                        >

                                                        </div>
                                                  
                                                    </div>
                                                );

                                            })}
                                        </div>
                                        
                                    </div>
                            
                        
                                    </>
            
                                ))}
                        {pag &&pag.total_pages > 0 ? 
                                    <Paginate pageCount={pag.total_pages} onPress={handlePageChange} activePage={activePage} />
                        :""}
                        {
                            !loading?< Spinner /> :""

                        }
                    </div>

                <p  className='noResult' ref={notFound} style={{display:"none"}}>لا يوجد نتائج</p>

                </div>
                <div className="videos-videos-big-cont resVideos" style={{display:"none"}} dir='rtl'>
                <VideosFIlter cates={categories} attrs={attrs} fetchVideos={fetchVideos} notFound={notFound} />
                    <div className='' >
                    {videos && videos.media.map((mediaItem, index) => (
                        <>
            
                                <div className='videosContaninerRes'  key={index}>
                                    <p className='videos-about'>{mediaItem.value}</p>  
                                    <div className="videos-videos-conts">
                                        <Swiper

                                            spaceBetween={20}
                                            slidesPerView={1.25}

                                            pagination={{
                                                clickable: true,
                                                el: '.swiper-pagination',
                                            }}
                                            modules={[ Autoplay, Pagination]}
                                            speed={2000}
                                            >
                                            {
                                            mediaItem.media.map((video, subIndex)=>{
                                                let videoID = "";
                                                let videoSrc = "";
                                                let isAvailable = false;
                                                if (video?.url) {
                                                const match = video.url.match(/(?:youtu\.be\/|v=)([^&/]+)/);
                                                if (match && match[1]) {
                                                    videoID = match[1];
                                                    videoSrc = `https://www.youtube.com/embed/${videoID}`;
                                                    isAvailable = availability?.[video.url];
                                                }
                                                }
                                         
                                                    return(
                                                        <SwiperSlide >
                                                            <div  style={{ position: 'relative',  height: '343px'}} key={subIndex}>
                                                                <iframe
                                                                    className='video-video-frame'
                                                                    src={videoSrc}
                                                                    title={`YouTube video player ${video.name}`}
                                                                    frameBorder="0"
                                                                    allow="encrypted-media"
                                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                                    allowFullScreen

                                                                />
                                                                <div className="videos-video-bottom-section">
                                                                    <div className="videos-video-bottom">

                                                                        <img src="/assets/dots.png" alt="" className='videos-video-dots' onClick={() => handleShareClick(video.id)} />
                                                                        <div className="videos-video-archive3">
                                                                        <p className='videos-video-bottom-p'><Link to={`/displayvideo/${video.id}`}>{video.name}</Link></p>                                                                            {
                                                                                checkLogin?
                                                                                <img src={video.bookmarkshow ? `/assets/bookmark-yellow.png` : `/assets/archive.png`} alt="" className='videos-video-archive' 
                                                                                onClick={() => AddToBook(video.id, video.bookmarkshow)}
                                                                                 />
                                                                                :""
                                                                            }
                                                                         
                                                                        </div>

                                                                        <CustomModal
                                                                            key={subIndex}
                                                                            isVisible={visibleModal === video.id}
                                                                        
                                                                        >
                                                                            <p 
                                                                            className="video-share" 
                                                                            onClick={() => {
                                                                                setOverlayBlockMediaShare(true);
                                                                                setVideoID(video.id);

                                                                            }}
                                                                            >
                                                                            مشاركة
                                                                            </p>
                                                                           
                                                                        </CustomModal>

                                                                        {copiedProductIds[video.id] && (
                                                                            <span className='details-link'>Link copied!</span>
                                                                        )}
                                                                    
                                                                    </div>



                                                                    {/* <p className='videos-video-date'>{video.created_at}</p> */}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        height: '200px',
                                                                        background: 'rgba(255, 255, 255, 0)',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => navigate(`/displayvideo/${video.id}`)}
                                                                >

                                                                </div>

                                                            </div>

                                                        </SwiperSlide>
                                                    )
                                                })
                                            }


                                            <div className="swiper-pagination"></div>

                                            </Swiper>
                                    </div>
                                    {
                                           isOverlayBlockMediaShare&& <MultimediaShare closeOverlay={closeMediaShareBlock}  text={`https://popeshenoudasitetest.msol.dev/displayvideo/${videoID}`}/>
                                    }
                                </div>
                    
                                </>
        
                            ))}
                    {pag &&pag.total_pages > 0 ? 
                                <Paginate pageCount={pag.total_pages} onPress={handlePageChange}  activePage={activePage} />
                    :""}
                    {
                        !loading?< Spinner /> :""
                    }
                    </div>


                    <p  className='noResult' ref={notFoundRes} style={{display:"none"}}>لا يوجد نتائج</p>

                </div>
            </div>
            <ToastContainer /> 

        </div>
    )
}
