import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../../components/Context/UserContext';


export default function VideosHooks() {
    const notFound=useRef()
    const notFoundRes=useRef()
    const { baseURL } = useContext(UserContext);
    const [videos, setVideos] = useState(null);
    const [categories, setCategories] = useState(null);
    const [attrs, setAttrs] = useState(null);
    const navigate = useNavigate();
    const [cateID, setCateID] = useState(null);
    const [page, setPage] = useState(1);
    const [pag, setPag] = useState(null);
    const [total, setTotal] = useState(null);
    const [totalF, setFTotal] = useState(null);
    const op = useRef(null);
    const token = localStorage.getItem("token");
    const [visibleModal, setVisibleModal] = useState(null);
    const [imgbm, setImgbk] = useState(null);
    const bookMarkRef=useRef();
    const [activePage,setActivePage]=useState(1);

    const fetchVideos = async () => {
        const cate = localStorage.getItem("videosAttr") ? JSON.parse(localStorage.getItem("videosAttr")) : null;
    
        if (cate) {
            setCateID(cate);
   
    
            try {
                const response = await axios.get(
                    `${baseURL}/getmedia/3?lang=ar&page=1&${cate}`
                );
    
                const { data, pagination, addetionals } = response.data;
    
                setVideos(data);
                setPag(pagination);
                setFTotal(pagination.total_pages);
                setCategories(addetionals.filterable_categories);
                setAttrs(addetionals.filterable_attrebutes);
    
                if (data.media.length === 0) {
                    notFound.current.style.display = "block ";
                    notFoundRes.current.style.display = "block ";
                } else if (notFound.current) {
                    notFound.current.style.display = "none";
                    notFoundRes.current.style.display = "none ";

                }
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const adjustedPage = Math.min(page || 1, total || 1);
    
                const response = await axios.get(
                    `${baseURL}/getmedia/3?lang=ar&page=${adjustedPage}`,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
    
                const { data, pagination, addetionals } = response.data;
    
                setVideos(data);
                setPag(pagination);
                setTotal(pagination.total_pages);
                setCategories(addetionals.filterable_categories);
                setAttrs(addetionals.filterable_attrebutes);
                if (data.media.length === 0) {
                    notFound.current.style.display = "block ";
                    notFoundRes.current.style.display = "block ";
                } else if (notFound.current) {
                    notFound.current.style.display = "none";
                    notFoundRes.current.style.display = "none ";

                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    
    
    useEffect(() => {
        fetchVideos();

    }, [])
    useEffect(() => {
        fetchVideos();

    }, [page,localStorage.getItem("videosAttr")])

    useEffect(() => {
        if(localStorage.getItem("videosAttr")){
            setActivePage(1);
            setPage(1);
        }

    }, [localStorage.getItem("videosAttr")])
    const handlePageChange = async (selectedPage) => {
        setPage(selectedPage); 
        setActivePage(selectedPage); 
    
        try {
            const response = await axios.get(
                `${baseURL}/getmedia/3?lang=ar&page=${selectedPage}&${cateID || ''}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            const { data, pagination } = response.data;
            setVideos(data);
            setPag(pagination);
        } catch (error) {
            console.error(error);
        }
        window.history.replaceState(null, null, ' ')
        window.scrollTo(0, 0); 
    };

    const AddToBook = (media_id, isBooked) => {
        const axiosRequest = isBooked
            ? axios.delete(`${baseURL}/bookmarks`, {
                data: { media_id },
                headers: { 'Authorization': `Bearer ${token}` }
            })
            
            : axios.post(`${baseURL}/bookmarks`,
                { media_id },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

        axiosRequest
            .then(() => {
                console.log("added")
                fetchVideos();
            })
            .catch((error) => {
                console.log(error);

            });
        
    };
    const [copiedProductIds, setCopiedProductIds] = useState({});
    const handleCopy = (videoId) => {
        // Update copied status
        setCopiedProductIds(prevState => ({
            ...prevState,
            [videoId]: true
        }));
    
        // Hide the modal by setting visibleModal to null
        setVisibleModal(null);
    
        // Reset the copied status after 3 seconds
        setTimeout(() => {
            setCopiedProductIds(prevState => ({
                ...prevState,
                [videoId]: false
            }));
        }, 3000); // 3 seconds delay for "Link copied!" message
    };
    const handleShareClick = (videoId) => {
      
        setVisibleModal(prev => (prev === videoId ? null : videoId)); // Toggle visibility based on current state
      
    };

  return [baseURL,videos,categories,attrs,cateID,navigate,pag,page,op,token,visibleModal,fetchVideos,handlePageChange,AddToBook,copiedProductIds,bookMarkRef,handleCopy,handleShareClick,setCategories,setVideos,notFound,notFoundRes,activePage,setVisibleModal]
}
