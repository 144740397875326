import React, { useState,useRef } from 'react'
import './SearchContainer.css'
import '../../Pages/Search/Responsive/SearchResponsive.css'
import SearchHook from '../../Logic/Search/SearchHook';
import SearchContainerHook from '../../Logic/Search/SearchContainerHook';




import { Link, useLocation, useParams } from 'react-router-dom';
import CategoryHeader from '../Category/CategoryHeader';
import CatagorySayingWriteCard from '../Category/Categories/CatagorySayingWriteCard';
import OneBook from '../Books/OneBook';
import SayingsPhotoCard from '../Media/Writings/Sayings/SayingsPhotoCard';
import VisitsImg from '../Visits/VisitsImg';
import ArticlesCard from '../Media/Writings/Articles/ArticlesCard';
import EventCard from '../Home/EventCard';
import PoemsCard from '../Media/Writings/Poems/PoemsCard';
import CategoryVideoCard from '../Category/Categories/CategoryVideoCard';
import QuestionCard from '../Media/Writings/Questions/QuestionCard';
import DocsCard from '../Media/Writings/Docs/DocsCard';
import CategorySoundCard from '../Category/Categories/CategorySoundCard';
import './SearchContainer.css'
import VisitsOverlay from '../Visits/VisitsOverlay';
import SayingWriteCard from '../Media/Writings/Sayings/SayingWriteCard';
import Spinner from '../Utility/Spinner';
import DocOverlay from '../Media/Writings/Docs/DocOverlay';
import CategoryVisitOverlay from '../Category/CategoryVisitOverlay';
import CategoryFilter from '../Category/CategoryFilter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { toPng } from 'html-to-image';
import { OverlayPanel } from 'primereact/overlaypanel';
import SayingShareContainer from '../Media/Writings/Sayings/SayingShareContainer';
import SayingWriteCardOverlay from '../Media/Writings/Sayings/SayingWriteCardOverlay';
export default function SearchContainer() {
const [allData,check,ele,count,getData]=SearchHook();

const  [categoryBookDataSearch,categoryGalleryDataSearch,categoryVideoDataSearch,categoryArticleDataSearch,categorySoundDataSearch,categorySayingsDataSearch,categoryPhotoSayingsDataSearch,categoryPoemsDataSearch,categoryQuestionsDataSearch,categoryHappenDataSearch,categoryDocsDataSearch]=SearchContainerHook(allData);
console.log(check);
const {id}=useParams()
const [isOverlayVisible, setOverlayVisible] = useState(false);

 const handleImageClick = () => {
    setOverlayVisible(true);
};

const closeOverlay = () => {
    setOverlayVisible(false);
};
const [isOverlayVisibleDocs, setOverlayVisibleDocs] = useState(false);

const [clickedImage, setClickedImage] = useState(null);
const [clickedImageIndex, setClickedImageIndex] = useState(0);
const [overlayImgs, setOverlayImgs] = useState([]);
const [clickedCategory, setClickedCategory] = useState('');

const handleImageClickDocs = (imgUrl, index, media, categoryValue) => {
    setClickedImage(imgUrl);
    setClickedImageIndex(index);
    setOverlayImgs(media);
    setClickedCategory(categoryValue);
    setOverlayVisibleDocs(true);
    document.body.style.overflow = "hidden";

};


const closeOverlayDocs = () => {
    setOverlayVisibleDocs(false);
    setClickedImage(null);
    document.body.style.overflow = "auto"; 

};
const [currentIndex, setCurrentIndex] = useState(0);
const handleImageClicked = (index) => {
    setCurrentIndex(index);
    console.log("asd",index);
    setOverlayVisible(true);
    
};

        // sayings card ***********************************************
        const op=useRef();

        // get current path
         const location = useLocation();
         const currentPath = `${window.location.origin}${location.pathname}${location.search}`;
        
        // get image from on Drive
        function convertOneDriveLink(onedriveUrl) {
          try {
            const url = new URL(onedriveUrl);
            const fileId = url.searchParams.get('id');
            if (!fileId) {
              console.error("Invalid OneDrive URL or 'id' parameter missing.");
              return null;
            }
            const directLink = `https://popeshenoudaacademy-my.sharepoint.com/personal/msoul_zvf-eg_org/_layouts/15/download.aspx?SourceUrl=${encodeURIComponent(
              fileId
            )}`;
            return directLink;
          } catch (error) {
            console.error("Error processing OneDrive URL:", error.message);
            return null;
          }
        }
        // const directLink = img ? convertOneDriveLink(img) : null;
      
      
        // Copy Data
        const copyData = async (item) => {
            setSelectedItem(item)
            try {
              const plainTextDesc = selectedItem.quote.replace(/<[^>]+>/g, "");
              const message = `\n\n"${plainTextDesc || ""}"\n\n- ${selectedItem.name || ""} ${selectedItem.ref?`(${selectedItem.ref})`:""}`;
              await navigator.clipboard.writeText(message); 
              toast.success("تم النسخ بنجاح");
            } catch (error) {
              toast.error("عذرا ..قم بالمحاولة لاحقا");
              console.error("Failed to copy:", error);
            }
          };
        // Download Card
        const cardRefs = useRef(new Map());
        const buttonDownload = useRef();
        const generateImageWithoutButton = (name) => {
            const cardElement = cardRefs.current.get(name); 
          
            if (cardElement) {
              toPng(cardElement)
                .then((dataUrl) => {
                  const link = document.createElement("a");
                  link.href = dataUrl;
                  link.download = `${name || "card"}.png`;
                  link.click();
                })
                .catch((error) => {
                  console.error("Error generating image:", error.message, error);
                });
            } else {
              console.error("Card element not found for:", name);
            }
          };
      
        // more to read 
        const [isExpanded, setIsExpanded] = useState(false);
        const [isOverflowing, setIsOverflowing] = useState(false);
        const descRef = useRef(null);
        const [isOverlayCard, setOverlayCard] = useState(false);
        const [isOverlayDots, setIsOverlayDots] = useState(false);
        const [isOverlayBlockShare, setOverlayBlockShare] = useState(false);
        const [selectedItem, setSelectedItem] = useState(null);

        const handleCardOverlay=(item)=>{
            setSelectedItem(item);
          document.body.classList.add("no-scroll");
          setOverlayCard(true)
          const header = document.querySelector('.header');
          header.style.zIndex = '1000';
        }
        const handleCardOverlayClose=()=>{
            setSelectedItem(null);
          document.body.classList.remove("no-scroll");
          setOverlayCard(false)
        }
      
        const handleOverlayToggle = (e, item) => {
            setSelectedItem(item); 
            console.log("sdddddddd",item);
            op.current.toggle(e); // Execute the toggle logic
            setIsOverlayDots((prev) => !prev); // Toggle overlay state
        };
      const openShareBlock=(item)=>{
        document.body.classList.add("no-scroll");
        setOverlayBlockShare(true)
        const header = document.querySelector('.header');
        header.style.zIndex = '10000';
      }
      const closeShareBlock=()=>{
        document.body.classList.remove("no-scroll");
        setOverlayBlockShare(false)
        setIsOverlayDots(false)
      }
        const playersRef = useRef([]); // Array of refs for all players
        const swiperPlayersRef = useRef([]);
        const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
        const [currentPlayingIndexRes, setCurrentPlayingIndexRes] = useState(null);

    const handlePlay = (index) => {
        // Pause any currently playing audio
        if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
            const previousPlayer = playersRef.current[currentPlayingIndex];
            if (previousPlayer && previousPlayer.audio.current) {
                previousPlayer.audio.current.pause();
            }
        }

        // Set the current playing index
        setCurrentPlayingIndex(index);
    };

    const handlePrev = (index) => {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            playersRef.current[prevIndex].audio.current.play();
            handlePlay(prevIndex);
        }
    };

    const handleNext = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < categorySoundDataSearch[0].value.length) { // Ensure it doesn't exceed the length
            playersRef.current[nextIndex].audio.current.play();
            handlePlay(nextIndex);
        }
    };
    const handlePlayRes = (index) => {
        // Pause any currently playing audio
        if (currentPlayingIndexRes !== null && currentPlayingIndexRes !== index) {
            const previousPlayer = swiperPlayersRef.current[currentPlayingIndexRes];
            if (previousPlayer && previousPlayer.audio.current) {
                previousPlayer.audio.current.pause();
            }
        }

        // Set the current playing index
        setCurrentPlayingIndexRes(index);
    };

    const handlePrevRes = (index) => {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            swiperPlayersRef.current[prevIndex].audio.current.play();
            handlePlay(prevIndex);
        }
    };

    const handleNextRes = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < categorySoundDataSearch[0].value.length) { // Ensure it doesn't exceed the length
            swiperPlayersRef.current[nextIndex].audio.current.play();
            handlePlay(nextIndex);
        }
    }

  return (
    <div className='searchContainer'>
           <div className='Container'>
           <CategoryFilter title={localStorage.getItem("search")&&localStorage.getItem("search")} />
            <p className='countResult'>عدد نتائج البحث :<span>{count?count:0}</span></p>
            {
                categorySayingsDataSearch[0]?
                            <>
                                <CategoryHeader cate={categorySayingsDataSearch[0]&&categorySayingsDataSearch[0].key}  count={categorySayingsDataSearch[0].media_count} src={`/search/more/${categorySayingsDataSearch[0].media_type_id}`}/>
                                <div className="categoryRow">
                                    <div className=' sayingsWriteItems'>
                                {
                                    categorySayingsDataSearch[0].value&&categorySayingsDataSearch[0].value.slice(0,2).map((item)=>{
                                        return(
                                            <SayingWriteCard img={item.image!=null?item.image:`/assets/media/writings/Sayings/img1.png`}  desc={item.quote} name={item.ref} cate={item.name}  key={item.id} checked={item.sharepoint_image}/>
                                          )
                                    })
                                }
                                
                                    </div>
                                    <div className='sayingsWriteItemsCateRes' style={{display:"none"}} dir="rtl">
                                    <Swiper
                                            spaceBetween={20}
                                            slidesPerView={categorySayingsDataSearch[0].value.length > 1 ? 1.3:1}

                                            pagination={{
                                                clickable: true,
                                                el: '.swiper-pagination',
                                            }}
                                            modules={[ Autoplay, Pagination]}
                                            speed={2000}
                                            >
                                            {
                                                  categorySayingsDataSearch[0].value ? categorySayingsDataSearch[0].value.slice(0, 2).map((item,index)=>{
                                                    return(
                                                        <SwiperSlide   key={index}>
                                     
                                                            <div className="sayingWriteCard" ref={(el) => {
                                                                if (el) {
                                                                cardRefs.current.set(item.name, el); 
                                                                } else {
                                                                cardRefs.current.delete(item.name);
                                                                }
                                                            }}   >
                                                                                                                
                                                            <div className="sayingWriteCardImg">
                                                            {convertOneDriveLink(item.image) ? (
                                                            <img src={convertOneDriveLink(item.image)} alt="Image from OneDrive" />
                                                        ) : item.image ? (
                                                            <img src={item.image} alt="Image" />
                                                        ) : (
                                                            <img src="/assets/media/writings/Sayings/img1.png" alt="Placeholder Image" />
                                                        )}
                                                        </div>
                                                            <div className="sayingWriteCardContent">
                                                                <img
                                                                className="signQuote saywImg"
                                                                src="/assets/media/writings/Sayings/q1.png"
                                                                alt="img"
                                                                />
                                                            <div>
                                                            <p
                                                            ref={descRef}
                                                            className={`sayingWriteCardDesc ${isExpanded ? "expanded" : ""}`}
                                                            dangerouslySetInnerHTML={{ __html: item.quote }}
                                                            style={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: isExpanded ? "unset" : 3,
                                                                WebkitBoxOrient: "vertical",
                                                                overflow: "hidden",
                                                            }}
                                                            ></p>                                        
                                                       
                                                            <div className="moreWriteCard">
                                                                <img
                                                                src="/assets/moreWC.png"
                                                                alt="writeCard"
                                                                onClick={() => handleCardOverlay(item)}
                                                                />
                                                            </div>
                                                            
                                                            </div>
                                                                <div style={{ clear: "both" }}></div>
                                                                <div className="sayingWriteCardSign">
                                                                    <div>
                                                                    <h3 className="sayingWriteCardSignName"> {item.ref?item.ref:""} </h3>
                                                                    <span className="sayingWriteCardCategory">{item.name}</span>
                                                                    </div>
                                                                    <img  src='/assets/sign.png' className='sayingWriteCardSignImg'/>
                                                                </div>
                                                                <img src="/assets/dots.png" alt=""   onClick={(e)=>handleOverlayToggle(e,item)} className='dotsWrite' />
                                                                <OverlayPanel ref={op}>
                                                                <button className="shareButton btnAction" onClick={()=>openShareBlock(item)} >
                                                                <span>مشاركة</span>
                                                                <img src='/assets/Share.png'/>
                                                                </button> 
                                                                <img src='/assets/line.png' alt='line'/>
                                                                <button className="shareButton btnAction" onClick={()=>copyData(item)}>
                                                                <span>نسخ</span>
                                                                <img src='/assets/copy.png'/>
                                                                </button> 
                                                                <img src='/assets/line.png' alt='line'/>
                                                                {/* <button className="#downloadImage downloadButton btnAction" onClick={()=>generateImageWithoutButton(selectedItem.name)} ref={buttonDownload} style={{cursor:"pointer"}}>
                                                                <span>تحميل</span>
                                                                <img src='/assets/download.png'/>
                                                                </button>   */}
                                                                </OverlayPanel>
                                 


                                                            </div>
                                                            </div>

                                                        </SwiperSlide>
                                                    )
                                                }):""
                                            }

                                                <div className="swiper-pagination"></div>


                                            </Swiper>
                                        </div>
                                        {isOverlayDots&&<div className='dots overlay' onClick={(e) => handleOverlayToggle(e, selectedItem)} 
                                            ></div>}
                                            {isOverlayCard && selectedItem && (
                                                <SayingWriteCardOverlay
                                                    closeOverlay={handleCardOverlayClose}
                                                    img={selectedItem.image}
                                                    desc={selectedItem.quote}
                                                    name={selectedItem.ref}
                                                    cate={selectedItem.name}
                                                />
                                                )}

                                        {

                                            isOverlayBlockShare&& <SayingShareContainer closeOverlay={closeShareBlock} currentPath={currentPath}  desc={selectedItem.quote} name={selectedItem.name} cate={selectedItem.ref} />
                                         }
                                     <Link to={`/search/more/${categorySayingsDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                                </div>
                            </>


           
                        
                   :"" 
            }
             {
                categoryBookDataSearch[0]?
                        <>
                            <CategoryHeader cate={categoryBookDataSearch[0]&&categoryBookDataSearch[0].key}  count={categoryBookDataSearch[0].media_count} src={`/search/more/${categoryBookDataSearch[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='books-section-one-books categoryBooksData'>
                            {
                               categoryBookDataSearch[0].value&&categoryBookDataSearch[0].value.slice(0,3).map((item)=>{
                                    return(
                                        <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                      )
                                })
                            }
                             <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />

                                </div>
                                <div className=' categoryBooksDataResIpad' style={{display:"none"}}>
                                            {
                                                categoryBookDataSearch[0].value && categoryBookDataSearch[0].value.slice(0, 2).map((item) => {
                                                    return (
                                                        <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                                    )
                                                })
                                            }
                                            <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />

                                        </div>
                                <div className='categoryBooksDataCateRes' style={{display:"none"}} dir="rtl">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={categoryBookDataSearch[0].value.length > 1 ? 1.3:1}

                                        pagination={{
                                            clickable: true,
                                            el: '.swiper-pagination',
                                        }}
                                        modules={[ Autoplay, Pagination]}
                                        speed={2000}
                                        >
                                        {
                                                categoryBookDataSearch[0].value ? categoryBookDataSearch[0].value.slice(0, 3).map((item)=>{
                                                return(
                                                    <SwiperSlide >
                                                 <OneBook key={item.id} src={item.sharepoint_image?item.image:"/assets/default/books/Book - inside.png"} info={item.name} book={item.url} book_id={item.id} />
                                                        <img className='books-head-stand2' src="/assets/books-5.png" alt="stand" />
                                                    </SwiperSlide>
                                                )
                                            }):""
                                        }
                                        <div className="swiper-pagination"></div>


                                    </Swiper>
                                </div>
                                <Link to={`/search/more/${categoryBookDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


                    
               
                
                :""
            }
            
            {
                categoryPhotoSayingsDataSearch[0]?
                        <>
                            <CategoryHeader cate={categoryPhotoSayingsDataSearch[0]&&categoryPhotoSayingsDataSearch[0].key}  count={categoryPhotoSayingsDataSearch[0].media_count} src={`/search/more/${categoryPhotoSayingsDataSearch[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='sayingsPhotoItems categoryPhotoSayingsData'>
                            {
                                 categoryPhotoSayingsDataSearch[0].value&&categoryPhotoSayingsDataSearch[0].value.slice(0,4).map((item)=>{
                                    return(
                                <SayingsPhotoCard src={item.image} key={item.id}/>                                      )
                                })
                            }
                                </div>
                                <div className='sayingsPhotoItemsCateRes' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryPhotoSayingsDataSearch[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryPhotoSayingsDataSearch[0].value ? categoryPhotoSayingsDataSearch[0].value.slice(0, 3).map((item)=>{
                                                        return(
                                                            item.sharepoint_image&&
                                                            <SwiperSlide >
                                                                <SayingsPhotoCard src={item.image != null ? item.image : `/assets/media/writings/Sayings/img1.png`} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>


                                                </Swiper>
                                    </div>
                                <Link to={`/search/more/${categoryPhotoSayingsDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


          
                
                :""
            }
            {
                categoryGalleryDataSearch[0]?
                   
                        <>
                            <CategoryHeader cate={categoryGalleryDataSearch[0]&&categoryGalleryDataSearch[0].key}  count={categoryGalleryDataSearch[0].media_count} src={`/search/more/${categoryGalleryDataSearch[0].media_type_id}`} />
                            <div className="categoryRow">
                                <div className='visits-imgs-cont categoryGalleryData'>
                            {
                                 categoryGalleryDataSearch[0].value&&categoryGalleryDataSearch[0].value.slice(0,3).map((item,i)=>{
                                    return(
    
                                        <VisitsImg info={item.description} src={item.image!=null?item.image:`/assets/gallery-1.png`} key={item.id} handleImageClick={() => handleImageClicked(i)} 
                                        />                                              
                                        )
                                })
                            }
                                </div>
                                <div className='categoryGalleryDataResCate' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryGalleryDataSearch[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryGalleryDataSearch[0].value ? categoryGalleryDataSearch[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                           <VisitsImg 
                                                                info={item.description} 
                                                                src={item.url != null ? item.url : '/assets/gallery-1.png'} 
                                                                key={item.id} 
                                                                handleImageClick={() => handleImageClicked(i)} 
                                                            />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                </div>
                                {isOverlayVisible && (
                                            <CategoryVisitOverlay closeOverlay={closeOverlay} visitsImgs={categoryGalleryDataSearch[0]} currentIndex={currentIndex}  />
                                            )}
                                <Link to={`/search/more/${categoryGalleryDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>

                    :""
                    
     
            }

            {
                categoryArticleDataSearch[0]?
                        <>
                           <CategoryHeader cate={categoryArticleDataSearch[0]&&categoryArticleDataSearch[0].key}  count={categoryArticleDataSearch[0].media_count} src={`/search/more/${categoryArticleDataSearch[0].media_type_id}`} />
                            <div className="categoryRow">
                                <div className='articleItems categoryArticleData'>
                                    
                            {
                                 categoryArticleDataSearch[0].value&&categoryArticleDataSearch[0].value.slice(0,2).map((item)=>{
                                    return(
                                        <ArticlesCard  img={item.image!=null?item.image:`/assets/media/writings/article/articleItem.png`} title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />                   
                                          
                                        )
                                })
                            }
                                </div>
                                <div className='categoryArticleDataResCate' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryArticleDataSearch[0].value.length > 1 ? 1.3:1}
                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryArticleDataSearch[0].value ? categoryArticleDataSearch[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                <ArticlesCard img={item.image != null ? item.image : `/assets/media/writings/article/articleItem.png`} title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                </div>
                                <Link to={`/search/more/${categoryArticleDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


                :""
     
            }

            {
                categoryHappenDataSearch[0]?
              
                        <>
                            <CategoryHeader cate={categoryHappenDataSearch[0]&&categoryHappenDataSearch[0].key}  count={categoryHappenDataSearch[0].media_count} src={`/search/more/${categoryHappenDataSearch[0].media_type_id}`} />
                            <div className="categoryRow">
                                <div className='eventsItems categoryHappenData'>
                                    
                            {
                              categoryHappenDataSearch[0].value&&categoryHappenDataSearch[0].value.slice(0,2).map((item)=>{
                                    return(
    
                                        <EventCard date={item.date} title={item.name} desc={item.event} img={item.image!=null?item.image:`/assets/media/writings/article.png`}  key={item.id}/>            
                                          
                                        )
                                })
                            }
                                </div>
                                <Link to={`/search/more/${categoryHappenDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


         
                :"" 
            }

            {
                categoryPoemsDataSearch[0]?
            
                        <>
                             <CategoryHeader cate={categoryPoemsDataSearch[0]&&categoryPoemsDataSearch[0].key}  count={categoryPoemsDataSearch[0].media_count} src={`/search/more/${categoryPoemsDataSearch[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='PoemsItems categoryPoemsData'>
                                    
                            {
                                categoryPoemsDataSearch[0].value&&categoryPoemsDataSearch[0].value.slice(0,3).map((item)=>{
                                    return(
    
                                        <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />       
                                          
                                        )
                                })
                            }
                                </div>
                                <div className='categoryPoemsDataResCate PoemsItems' style={{display:"none"}} dir="rtl">
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryPoemsDataSearch[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryPoemsDataSearch[0].value ? categoryPoemsDataSearch[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                    <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                    </div>
                                <Link to={`/search/more/${categoryPoemsDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


           
                
                :"" 
            }
            {
                categoryDocsDataSearch[0]?
                    
                        <>
                            <CategoryHeader cate={categoryDocsDataSearch[0]&&categoryDocsDataSearch[0].key}  count={categoryDocsDataSearch[0].media_count} src={`/search/more/${categoryDocsDataSearch[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='docsItems categoryDocsData'>
                                    
                            {
                                categoryDocsDataSearch[0].value&&categoryDocsDataSearch[0].value.slice(0,4).map((item,index)=>{
                                    return(
    
                                        <React.Fragment key={item.id}>
                                          <DocsCard src={item.image != null ? item.image : `/assets/media/writings/docs/docs.png`} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index,categoryDocsDataSearch[0].value , localStorage.getItem("search"))} />
                                
                                      </React.Fragment>                                              
                                        )
                                })
                            }
                                    {isOverlayVisibleDocs && (
                                         <DocOverlay
                                         closeOverlay={closeOverlayDocs}
                                         img={clickedImage}
                                         cate={clickedCategory}
                                         imgs={overlayImgs}
                                         initialSlide={clickedImageIndex}
                                     />                                     
                                     )}
                                </div>
                                <div className='categoryDocsDataRes' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryDocsDataSearch[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryDocsDataSearch[0].value ? categoryDocsDataSearch[0].value.slice(0, 3).map((item,index)=>{
                                                        return(
                                                            <React.Fragment key={item.id}>
                                                            <SwiperSlide >
                                                 
                                                                <DocsCard src={item.image != null ? item.image : `/assets/media/writings/docs/docs.png`} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index,categoryDocsDataSearch[0].value , localStorage.getItem("search"))} />
                                                                <p style={{ color:"#F8F3ED" }}> doc</p>

                                                            </SwiperSlide>
                                                            
                                                            </React.Fragment>

                                                        )
                                                    }):""
                                                }
  
                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                    </div>
                                    {isOverlayVisibleDocs && (
                                                  <DocOverlay
                                                  closeOverlay={closeOverlayDocs}
                                                  img={clickedImage}
                                                  cate={clickedCategory}
                                                  imgs={overlayImgs}
                                                  initialSlide={clickedImageIndex}
                                              /> 
                                    )}
                                <Link to={`/search/more/${categoryDocsDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


                
                
                :"" 
            } 

            {
                  categoryVideoDataSearch[0]?
                        <>
                             <CategoryHeader cate={categoryVideoDataSearch[0]&&categoryVideoDataSearch[0].key}  count={categoryVideoDataSearch[0].media_count} src={`/search/more/${categoryVideoDataSearch[0].media_type_id}`}/>
                            <div className="categoryRow">
                                <div className='categoryVideoData'>
                                    
                            {
                               categoryVideoDataSearch[0].value&&categoryVideoDataSearch[0].value.slice(0,3).map((item)=>{
                                    return(
    
                                        <CategoryVideoCard  url={item.url} desc={item.name} src={item.id} key={item.id} />         
                                          
                                        )
                                })
                            }
                                </div>
                                <div className='categoryVideoDataRes' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categoryVideoDataSearch[0].value.length > 1 ? 1.3:1}

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                                    categoryVideoDataSearch[0].value ? categoryVideoDataSearch[0].value.slice(0, 3).map((item,i)=>{
                                                        return(
                                                            <SwiperSlide >
                                                                <CategoryVideoCard url={item.url} desc={item.name} src={item.id} key={item.id} />

                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                    </div>
                                <Link to={`/search/more/${categoryVideoDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>

                :""           
                    
          
            }
                                   

            {
                  categoryQuestionsDataSearch[0]?
                
                        <>
                             <CategoryHeader cate={categoryQuestionsDataSearch[0]&&categoryQuestionsDataSearch[0].key}  count={categoryQuestionsDataSearch[0].media_count} src={`/search/more/${categoryQuestionsDataSearch[0].media_type_id}`} />
                            <div className="categoryRow">
                                <div className='questionsItems categoryQuestionsData'>
                                    
                            {
                               categoryQuestionsDataSearch[0].value&&categoryQuestionsDataSearch[0].value.slice(0,3).map((item)=>{
                                    return(
    
                                        <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id}  checkAudio={item.sharepoint_uodated}/>     
                                          
                                        )
                                })
                            }
                                </div>
                                <div className='categoryQuestionsDataRes' style={{display:"none"}} dir="rtl" >
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={categoryQuestionsDataSearch[0].value.length > 1 ? 1.3:1}
                                        pagination={{
                                            clickable: true,
                                            el: '.swiper-pagination',
                                        }}
                                        modules={[ Autoplay, Pagination]}
                                        speed={2000}
                                        >
                                        {
                                            categoryQuestionsDataSearch[0].value ? categoryQuestionsDataSearch[0].value.slice(0, 3).map((item,i)=>{
                                                return(
                                                    <SwiperSlide >
                                                        <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id} />
                                                    </SwiperSlide>
                                                )
                                            }):""
                                        }

                                            <div className="swiper-pagination"></div>
                                        </Swiper>
                                    </div>
                                <Link to={`/search/more/${categoryQuestionsDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>


                    
          
                
                :"" 
            }

            
                            
                    
            {
                categorySoundDataSearch[0]?
                
                        <>
                             <CategoryHeader cate={categorySoundDataSearch[0]&&categorySoundDataSearch[0].key}  count={categorySoundDataSearch[0].media_count} src={`/search/more/${categorySoundDataSearch[0].media_type_id}`} />
                            <div className="categoryRow">
                                <div className=' categorySoundData'>
                                    
                            {
                               categorySoundDataSearch[0].value&&categorySoundDataSearch[0].value.slice(0,3).map((item,index)=>{
                                    return(
                                    
                                        <CategorySoundCard
                                        key={item.id}
                                        index={index}
                                        audio={item.url}
                                        title={item.name}
                                        isPlaying={currentPlayingIndex === index}
                                        hasPrev={index > 0} // Only show prev if there's a previous item
                                        hasNext={index < categorySoundDataSearch[0].value.slice(0, 3).length - 1} 
                                        onPlay={handlePlay}
                                        onPrev={handlePrev}
                                        onNext={handleNext}
                                        ref={(el) => (playersRef .current[index] = el)} 
                                    />  
                                                      
                                        )
                                })
                            }
                                </div>
                                <div className='categorySoundDataRes' style={{display:"none"}} dir="rtl" >
                                            <Swiper
                                                spaceBetween={20}
                                                slidesPerView={categorySoundDataSearch[0].value.length > 1 ? 1.3:1}       

                                                pagination={{
                                                    clickable: true,
                                                    el: '.swiper-pagination',
                                                }}
                                                modules={[ Autoplay, Pagination]}
                                                speed={2000}
                                                >
                                                {
                                          categorySoundDataSearch[0].value
                                          ? [...categorySoundDataSearch[0].value.slice(0, 3)].reverse().map((item, index) => {
                                                    return (
                                                            <SwiperSlide >
                                                                <CategorySoundCard
                                                                key={item.id}
                                                                index={index}
                                                                audio={item.url}
                                                                title={item.name}
                                                                isPlaying={currentPlayingIndexRes === index}
                                                                hasPrev={index > 0} // Only show prev if there's a previous item
                                                                hasNext={index < categorySoundDataSearch[0].value.slice(0, 3).length - 1} // Ensure next is calculated after slice
                                                                onPlay={handlePlayRes}
                                                                onPrev={handlePrevRes}
                                                                onNext={handleNextRes}
                                                                ref={(el) => (swiperPlayersRef.current[index] = el)} // Store refs
                                                            />
                                                            </SwiperSlide>
                                                        )
                                                    }):""
                                                }

                                                    <div className="swiper-pagination"></div>
                                                </Swiper>
                                    </div>
                                <Link to={`/search/more/${categorySoundDataSearch[0].media_type_id}`} className='more_btn'>المزيد</Link>
                            </div>
                        </>
                    :""

                    
             
            } 
            
                    
                    {
                        
                        check==false&& <p style={{textAlign:"center"}}  ><Spinner /></p>
                    
                    }

                         
                   
                    <p className='noResult' ref={ele} style={{display:"none"}}>لا يوجد نتائج</p>

                

        </div>
        <ToastContainer />

    </div>
  )
}
