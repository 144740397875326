
import React, { useEffect, useRef } from 'react'
import './QuestionContainer.css'
import '../../Responsive/QuestionsRes.css'
import MediaHeader from '../../MediaHeader'
import QuestionCard from './QuestionCard'
import Paginate from '../../../Utility/Paginate';
import Spinner from '../../../Utility/Spinner';
export default function QuestionContainer({data,handleChangePage,pageCount,notFound,activePage}) {
  const qs = useRef();

  //   if (qs.current) {

  //     const allCards = Array.from(qs.current.children);
  //     allCards.map((a)=>{
  //       console.log(a);
  //     })
  //     console.log(allCards);
  //   }

  
  return (
    <div>
      <div className='questionContainer questionLoveContainer' ref={qs}>

    {   
         data && Array.isArray(data[0])?data[0].map((item)=>{
          return(
            <>
              <div className='Container'>
               <MediaHeader  title={item.value} />
                <div className='questionsItems ' >

                {
                    item.media?item.media.map((qus)=>{
                      return(
                        <QuestionCard ques={qus.question} answer={qus.answer} audio={qus.audio} key={Math.random()*10} id={qus.id} checkAudio={qus.sharepoint_uodated} />
                      )
                    }):""
                  } 
                  
                </div>
      
              </div>
            </>
            
        
          )
        }):<Spinner />
      }
    </div>
    {
     
      pageCount >0&&<Paginate pageCount={pageCount} onPress={handleChangePage} activePage={activePage} />
     
    }

<p  className='noResult' ref={notFound} style={{display:"none"}}>لا يوجد نتائج</p>
  </div>

  )
}
