
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllSayingsPhotoAction, getAllSayingsWrittenAction } from '../../../../redux/actions/Media/Writings/SayingsActions';
export default function SayingsHook() {
    const dispatch=useDispatch();

    const getData = async () => {
        await dispatch(getAllSayingsWrittenAction(4, 1));
        await dispatch(getAllSayingsPhotoAction(8, 1));
    };
    
    useEffect(() => {
        console.log('useEffect called');
        getData();
    }, []);

const resX=useSelector(state=> state.SayingsReducer.sayingsWritenData);
const resY=useSelector(state=> state.SayingsReducer.sayingsPhotoData);

let sayingsWrittenDataLimited=[];
try {
    if(resX){
        if(resX.data){
            sayingsWrittenDataLimited.push(resX.data)

        }
    }
} catch (e) {
    console.log(e);
}
let sayingsPhotoDataLimited=[];
try {
    if(resY){
        if(resY.data){
            sayingsPhotoDataLimited.push(resY.data)


        }
    }
} catch (e) {
    console.log(e);
}


return [sayingsWrittenDataLimited,sayingsPhotoDataLimited]
}


