import { GET_DATA_BY_CATEGORY, GET_SEARCH_DATA, GET_SEARCH_Filter_DATA, GET_SEARCH_MORE, GET_SEARCH_RESULT } from "../../type";


const initial ={
    searchResult:[],
    searchResultMore:[],
    searchResultMoreFilter:[]

}

export const SearchReducer=(state=initial,action)=>{
    switch(action.type){
        case GET_SEARCH_RESULT:
            return{
                    ...state,
                    searchResult:action.payload,
                }
        case GET_SEARCH_MORE:
            return{
                    ...state,
                    searchResultMore:action.payload,
                }
        case GET_SEARCH_Filter_DATA:
            return{
                ...state,
                searchResultMoreFilter:action.payload,
            }
        default:
            return state;
    }
}
