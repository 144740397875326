
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDataByCategoryMoreAction } from '../../redux/actions/Categories/CategoryActions';
export default function DataByCategoryMoreHook(id,mediaID) {
    const dispatch=useDispatch();

//authReducer
const getData=async()=>{
    console.log("id:",id);
    if(mediaID == 10 || mediaID ==16){
        await dispatch(getDataByCategoryMoreAction(id,mediaID,8,1))

    }else{
        await dispatch(getDataByCategoryMoreAction(id,mediaID,6,1))

    }

}
useEffect(()=>{
    getData();
},[])
const res=useSelector(state=> state.CategoryReducer.categoryDataMore);


let categoryMoreData=[];
let title=[];
let path=[];
try {
    if(res){
        if(res.data){
            categoryMoreData.push(res.data.media)
            path=res.data.path
            title=res.data.category_title.name
        }
    }
} catch (e) {
    console.log(e);
}

let pageCount=0;

try {
    if(res){
        if(res.pagination){
            pageCount=Math.ceil(res.pagination.total/res.pagination.per_page)
        }
    }
} catch (error) {}


const handleChangePage= async(count)=>{
    if(mediaID == 10 || mediaID ==16){
        await dispatch(getDataByCategoryMoreAction(id,mediaID,8,count))

    }else{
        await dispatch(getDataByCategoryMoreAction(id,mediaID,6,count))

    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 
}

return [categoryMoreData,pageCount,handleChangePage,title,path]
}


