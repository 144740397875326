import React, { useEffect, useState } from 'react'
import { ReactReader } from 'react-reader';
import { useNavigate } from 'react-router-dom';

export default function DisplayBook() {
    const [book, setBook] = useState(null);
    const [info, setInfo] = useState(null);
    const [bookID, setBookId] = useState(null);

    const [showReader, setShowReader] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const bookData = localStorage.getItem("book");
  

        if (bookData) {
            try {
                const bookItem = JSON.parse(bookData);
                console.log("Parsed book item:", bookItem);
    
                setBook(bookItem.book);
                setInfo(bookItem.info);
                setBookId(bookItem.book_id)
                setShowReader(true); 
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        } else {
            alert("No book URL provided.");
            console.log("Navigating to home...");
            navigate("/"); 
        }
    }, []);
    console.log("cat",book);

  return (
    <div>
       {/* {showReader && (
                <div className="reader-container">
                    <button       onClick={() => {
                            setShowReader(false);
                            navigate(-1);
                        }}>Close Reader</button>
                    <ReactReader
                        url={"https://popeshenoudatest.msol.dev/Fundamental-Accessibility-Tests-Basic-Functionality-v2.0.0.epub"} 
                        title={
                          <div>
                           ss
                          </div>
                        }
                        showToc={false}
                        getRendition={(rendition) => {
                            rendition.themes.default({
                                '::selection': {
                                    background: 'rgba(255,255,0, 0.3)'
                                }
                            });
                            rendition.flow('scrolled-doc'); 

                        }}
                        
                        onClose={() => {
                            setShowReader(false);
                            navigate(-1)();
                        }}
                    />
                </div>
            )} */}
        <div className='display_book_iframe_block' >
        <iframe className='display_book_iframe'
            src={`https://popeshenoudatest.msol.dev/api/open-epub?media_id=${bookID}`}
            
            allowFullScreen
        ></iframe>
        </div>
</div>
  )
}
