import React from 'react'
import SectionHeader from '../Utility/SectionHeader'
import './Meditations.css'
import './Responsive/HomeRes.css'

import HomeHook from '../../Logic/Home/HomeHook';
import { Link } from 'react-router-dom';
export default function Meditations() {
  const [mediaData,meditationsData,sayingsData,eventsData]=HomeHook();
  const convertVideo = (video) => {
    const videoID = video.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];

    // Ensure meditationsData[0] exists and is an array
    let playlistIDs = [videoID]; // Start with the main video

    if (meditationsData[0] && Array.isArray(meditationsData[0])) {
        meditationsData[0].forEach((item) => {
            let listID = item.url.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];
            playlistIDs.push(listID); // Add each video ID to the playlist
        });
    }

    // Create playlist parameter
    const playlistParam = playlistIDs.join(",");

    // Construct the final YouTube embed URL
    const videoSrc = `https://www.youtube.com/embed/${videoID}?autoplay=0&loop=1&playlist=${playlistParam}&controls=1&modestbranding=1&rel=0&showinfo=0&fs=0&disablekb=1`;


    return videoSrc;
};
  return (
    <div className='meditations'>
        <SectionHeader title="عظات" subtitle="وتأمــــــــــــــــــــــــــــــــــــلات"/>
        <div className='Container'>
          <div className='meditations_items'>
            {
              meditationsData[0]?meditationsData[0].map((video)=>{
                return(
                  <Link to={`/displayvideo/${video.id}`} style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
                  <iframe
                    src={convertVideo(video.url)}
                    title={video.name}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ pointerEvents: 'none' }} // disable interaction
                  ></iframe>
                
                  {/* Optional overlay div (if you need full coverage) */}
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                  }} />
                </Link>
                )
              })

              :""
            }
         </div>
        </div>
 
        <div className='meditations_items_res' style={{display:"none"}}>
          {
            meditationsData[0]?meditationsData[0].slice(0,1).map((video)=>{
              return(
                <Link to={`/displayvideo/${video.id}`} style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
                <iframe
                  src={convertVideo(video.url)}
                  title={video.name}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  style={{ pointerEvents: 'none' }} // disable interaction
                ></iframe>
              
                {/* Optional overlay div (if you need full coverage) */}
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                }} />
              </Link>
              )
            })

            :""
          }
        </div>
        <Link className='more_btn' to={'/media/3'}>المزيد</Link>
    </div>
  )
}
