import React, { useState, useEffect, useContext } from 'react'
import './Visits.scss'
import './Visits-res.scss'

import axios from 'axios'
import { UserContext } from '../Context/UserContext';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import VisitsPhotos from './VisitsPhotos';
import VisitsOverlay from './VisitsOverlay';
import { useParams } from 'react-router-dom';
import Paginate from '../Utility/Paginate';



export default function Visits() {
    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });

    window.onload = function () {
        window.history.replaceState(null, null, ' '); // Clear hash if any
    };
    useEffect(() => {
        window.scrollTo(0, 0); // Ensure scroll starts at the top
    }, []);
    const [date, setDate] = useState(null);
    const { baseURL } = useContext(UserContext);
    const [visitsImgs, setVisitsImgs] = useState(null)
    const [page, setPage] = useState(1);
    const [pag, setPag] = useState(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };
    const [selectedCity, setSelectedCity] = useState(null);
    console.log(visitsImgs);

    const cities = [
        { name: 'الكل', code: 'NY' },
        { name: 'الكل', code: 'RM' },
        { name: 'الكل', code: 'LDN' }
    ];
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setOverlayVisible(true);

    };

    const closeOverlay = () => {
        setOverlayVisible(false);
    };

    const { id } = useParams();

    const fetchVisits = () => {
        axios.get(`${baseURL}/get-images/${id}?lang=ar&page=${page}`)
            .then((response) => {
                setVisitsImgs(response.data.data);
                setPag(response.data.pagination);
            })
            .catch((error) => {
                console.log(error);
            })

    }

    useEffect(() => {
        fetchVisits();
    }, [page])

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage);
    };
    const [shouldContain, setShouldContain] = useState(false);
    useEffect(() => {
        if(visitsImgs){
            if (visitsImgs?.category_image) {
                const img = new Image();
                img.src = visitsImgs.category_image;
                img.onload = () => {
                    if (img.width < 900) {
                        setShouldContain(true);
                    }
                };
            }
            

        }
    }, [visitsImgs]);
    return (
        <div className='visits'>
            <div className="visits-wrapper">
                {isOverlayVisible && visitsImgs && (
                    <VisitsOverlay closeOverlay={closeOverlay} visitsImgs={visitsImgs} currentIndex={currentIndex} />
                )}


                {visitsImgs && Array.isArray(visitsImgs?.images) && visitsImgs.images.length === 0 ? (
                    <>
                        <div className="visits-head-img-rel">
                            <img
                                src={visitsImgs.category_image}
                                alt=""
                                className={`visits-head-img ${shouldContain ? 'vistis-object-fit-contain' : ''}`}
                            />
                            {shouldContain && (
                                <img className="visits-head-img-blur" src={visitsImgs.category_image} />
                            )}
                        </div>
                            <div className="visits-videos-big-cont">
                            <p className="videos-about ">{visitsImgs.category_name}</p>
                            <p className='noResult' style={{marginTop:"5%"}} >لا يوجد نتائج</p>
                        
                        </div>
                    </>

                ) : visitsImgs && (
                    <>
                        <div className="visits-head-img-rel">
                            <img
                                src={visitsImgs.category_image}
                                alt=""
                                className={`visits-head-img ${shouldContain ? 'vistis-object-fit-contain' : ''}`}
                            />
                            {shouldContain && (
                                <img className="visits-head-img-blur" src={visitsImgs.category_image} />
                            )}
                        </div>
                        <div className="visits-videos-big-cont">
                            <p className="videos-about">{visitsImgs.category_name}</p>
                            <VisitsPhotos handleImageClick={handleImageClick} visitsImgs={visitsImgs} />
                            {pag.total !== 0 && (
                                <Paginate pageCount={pag.total_pages} onPress={handlePageChange} />
                            )}
                        </div>
                    </>
                )}


            </div>
        </div>
    )
}
