import React, { useContext, useEffect, useState } from 'react'
import './Gallery.scss'
import './Gallery-res.scss'

import GalleryImg from './GalleryImg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import axios from 'axios';
import { UserContext } from '../Context/UserContext';



export default function Gallery() {
    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
    });

    window.onload = function () {
        window.history.replaceState(null, null, ' '); // Clear hash if any
    };
    useEffect(() => {
        window.scrollTo(0, 0); // Ensure scroll starts at the top
    }, []);
    const [gallery, setGallery] = useState([]);
    const { baseURL } = useContext(UserContext);

    useEffect(() => {

        axios.get(`${baseURL}/category-images?lang=ar`)
            .then((response) => {
                console.log(response.data.data);
                setGallery(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })

    }, [])
    return (
        <div className='gallery'>
            <div className="gallery-wrapper">
                <div className="gallery-top-head">
                    <img src="/assets/gallery-1.png" alt="" className='gallery-top-img' />
                    <p className='gallery-top-p'>معرض صور مركز معلم الأجيال   </p>
                    <img src="/assets/gallery-right.png" alt="" className='gallery-top-right' />

                </div>

                <div className="gallery-imgs-cont">


                    <div className="gallery-img-responsive">
                        {gallery && gallery.map((item) => (
                            <GalleryImg src={item.image} info={item.name} children={item.children} />

                        ))}
                    </div>


                    <div className="gallery-swiper-relative" >
                        <Swiper

                            spaceBetween={0}
                            slidesPerView={3}
                            modules={[Navigation, Autoplay, Pagination]}
                            speed={2000}
                        >

                            {gallery && gallery.map((item) => (
                                <SwiperSlide >

                                    <GalleryImg src={item.image} info={item.name} children={item.children} />

                                </SwiperSlide>
                            ))}



                        </Swiper>
                    </div>


                </div>
            </div>
        </div>
    )
}
