import { toPng } from 'html-to-image';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SayingShareContainer from './SayingShareContainer';
export default function SayingWriteCardOverlay({ closeOverlay,img, desc, name, cate}) {
    const [isOverlayDots, setIsOverlayDots] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    // get current path
    const location = useLocation();
    const currentPath = `${window.location.origin}${location.pathname}${location.search}`;
    const op=useRef();

    // get image from on Drive
    function convertOneDriveLink(onedriveUrl) {
      try {
        const url = new URL(onedriveUrl);
        const fileId = url.searchParams.get('id');
        if (!fileId) {
          console.error("Invalid OneDrive URL or 'id' parameter missing.");
          return null;
        }
        const directLink = `https://popeshenoudaacademy-my.sharepoint.com/personal/msoul_zvf-eg_org/_layouts/15/download.aspx?SourceUrl=${encodeURIComponent(
          fileId
        )}`;
        return directLink;
      } catch (error) {
        console.error("Error processing OneDrive URL:", error.message);
        return null;
      }
    }
    const directLink = img ? convertOneDriveLink(img) : null;
  
    // Shared data with WhatsApp
    function shareOnWhatsApp() {
      const plainTextDesc = desc.replace(/<[^>]+>/g, "");
      const message = `\n\n"${plainTextDesc}"\n\n- ${name} (${cate})`;
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, "_blank");
  
    }
  
    // Download Card
    const cardRef = useRef(null);
    const buttonDownload = useRef();
    const generateImageWithoutButton = () => {
      if (cardRef.current) {
        // Hide the button
        buttonDownload.current.style.display = "none";
    
        // Generate the image
        toPng(cardRef.current)
          .then((dataUrl) => {
            // Create a link element to download the image
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `${name || "card"}.png`;
            link.click();
          })
          .catch((error) => {
            console.error("Error generating image:", error);
          })
          .finally(() => {
            // Ensure the button is re-displayed
            buttonDownload.current.style.display = "block";
          });
      }
    };
  
    // Share
    const descRef = useRef(null);
    const [isOverlayCard, setOverlayCard] = useState(false);
    const [isOverlayBlockShare, setOverlayBlockShare] = useState(false);
    const handleOverlayToggle = (e) => {
      const event = e || { currentTarget: null }; // Fallback if no event is passed
      op.current.toggle(event); // Execute the toggle logic
      setIsOverlayDots((prev) => !prev); // Toggle overlay state
      // const html = document.documentElement; 
      // if (!isOverlayDots) {
      //     html.style.overflow = 'hidden'; 
      // } else {
      //     html.style.overflow = ''; 
      // }
  };
  
  const openShareBlock = () => {
      document.body.classList.add("no-scroll");
      setOverlayBlockShare(true);
  
      // Ensure the overlay is closed if it is currently open
      if (isOverlayDots) {
          handleOverlayToggle(); // Close the overlay
      }
   

  };
    const closeShareBlock=()=>{
      document.body.classList.remove("no-scroll");
      setOverlayBlockShare(false)
      setIsOverlayDots(false)

    }
    // Copy Data
    const copyData = async () => {
      try {
          const plainTextDesc = desc.replace(/<[^>]+>/g, ""); 
          const message = `\n\n"${plainTextDesc}"\n\n- ${name} (${cate})`;
          await navigator.clipboard.writeText(message);
          toast.success("تم النسخ بنجاح");
      } catch (error) {
          toast.error("عذرا ..قم بالمحاولة لاحقا");
          console.error("Failed to copy:", error);
      }
  };

    useEffect(() => {
      // Check if the description is overflowing
      if (descRef.current) {
        setIsOverflowing(
          descRef.current.scrollHeight > descRef.current.clientHeight
        );
      }
    }, [desc]);

  return (
    <div className="saying overlay" >
          {isOverlayDots&&<div className='dots overlay' onClick={handleOverlayToggle} ></div>}
    <div className="overlay-wrapper">
        <div className="overlayCard" ref={cardRef}  >
            <div className="overlayCardImg">
                {directLink ? (
                <img src={directLink} alt="Image from OneDrive" />
                ) : (
                <img src={img} alt="Image" />
                )}
            </div>
            <div className="overlayCardCardContent">

            <div>
            <img
                className="quoteOverlay quoteOverlayTop"
                src="/assets/overlayQStart.png"
                alt="img"
                />
            <p dangerouslySetInnerHTML={{ __html: desc }} className='overlayCardDesc scrolled' ></p>
            <img
                className="quoteOverlay quoteOverlayBottom"
                src="/assets/overlayQEnd.png"
                alt="img"
                />
                <div style={{clear:"both"}}></div>
            </div>
                <div style={{ clear: "both" }}></div>
                <div className="overlayCardSign">
                    <div className='overlayCardSignRight'>
                        <h3 className="overlayCardSignName"> {name} </h3>
                        <span className="overlayCardCategory">{cate}</span>
                    </div>
                    <img  src='/assets/sign.png' className='overlayCardSignImg'/>
                </div>
                <img src="/assets/dots.png" alt=""   onClick={handleOverlayToggle} className='dotsWrite' />
                <OverlayPanel ref={op}>
                  <button className="shareButton btnAction" onClick={openShareBlock} >
                    <span>مشاركة</span>
                    <img src='/assets/Share.png'/>
                  </button> 
                  <img src='/assets/line.png' alt='line'/>
                  <button className="shareButton btnAction" onClick={copyData}>
                    <span>نسخ</span>
                    <img src='/assets/copy.png'/>
                  </button> 
       
                </OverlayPanel>
                {
                  isOverlayBlockShare&& <SayingShareContainer closeOverlay={closeShareBlock} currentPath={currentPath} img={img} desc={desc} name={name} cate={cate} height="100%" />
                }
            </div>
            <img src="/assets/closing.png" alt="" className="overlay-cancel" onClick={closeOverlay} />

        </div>
    </div>
    </div>
  )
}
