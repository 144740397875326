import React from 'react'
import './Background.css'
export default function Background() {

  
  
   
  return (

    <div className='bg'>
        <video   autoPlay muted>
             <source src='https://popeshenoudatest.msol.dev/bg.mp4' type="video/mp4" />
        </video>

    </div>
  )
}
