import React from 'react'

export default function SayingsPhotoCard({src}) {
  return (
    <div className='sayingsPhotoCard'>
        <div className='sayingsPhotoCardImg'>
          <img src={src} alt='img'/>
        </div>
    </div>
  )
}
