import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../Context/UserContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-flip';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';
import HomeHook from '../../Logic/Home/HomeHook';
export default function HeaderDropdownTwo({ openHeaderOne,setOpenHeaderOne,closeDp }) {
    const[mediaData,meditationsData,sayingsData,eventsData,total]=HomeHook();


    const [info, setInfo] = useState([]);
    const { baseURL } = useContext(UserContext);

    useEffect(() => {

        axios.get(`${baseURL}/show_header/ar`)
            .then((response) => {
                setInfo(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])


    const closeWrapper = () =>{
        setOpenHeaderOne(false);
        document.querySelector(".overlayLight").style.display = "none";

    }
    return (
        <>
            {openHeaderOne && (
                <div className="header-bottom-right-dropInfo2" onMouseLeave={()=>closeDp()}>
                    <div className="header-bottom-right-dropInfo2-imgs">




                        <div className="header-swiper-relative " >
                            <Swiper

                                spaceBetween={20}
                                slidesPerView={4}
                                modules={[Navigation, Autoplay, Pagination]}
                                speed={2000}
                                breakpoints={{
                                   
                                    662: {
                                        slidesPerView: 4, 
                                    },
                                    1050:{
                                        slidesPerView: 4,
                                    }
                                }}
                            >


                                    <SwiperSlide>
                                            <Link to={`/media/7`} style={{cursor:"pointer",boxShadow:''}}onClick={()=>closeWrapper()}> 
                                                <div className="header-bottom-right-dropInfo2-imgInfo">
                                                    <img className='header-bottom-right-dropInfo2-img' src="/assets/MediaGallery/1.png" alt="" style={{objectFit:"cover"}} />
                                                    <div className="header-bottom-right-dropInfo2-img-abs">
                                                        <img className='header-bottom-right-dropInfo2-img-abs-img' src="/assets/MediaGallery/icon5.png" alt="" />
                                                    </div>
                                                    <div className="header-bottom-right-dropInfo2-img-info">
                                                        <p className='header-bottom-right-dropInfo2-img-p2'>({total})</p>
                                                        <p className='header-bottom-right-dropInfo2-img-p'>مقالات وكتابات اخرى</p>
                                                    </div>
                                                </div>
                                            </Link>

                                        </SwiperSlide>

                                {info && info.mediaTypes.map((mediatype) => (

                                    <SwiperSlide >
                                        <Link to={mediatype.id==4?`/media/7`:`/media/${mediatype.id}`} style={{cursor:"pointer",boxShadow:''}}onClick={()=>closeWrapper()}> 
                                            <div className="header-bottom-right-dropInfo2-imgInfo">
                                                <img className='header-bottom-right-dropInfo2-img' src={mediatype.image} alt="" />
                                                <div className="header-bottom-right-dropInfo2-img-abs">
                                                    <img className='header-bottom-right-dropInfo2-img-abs-img' src={mediatype.image_icon} alt="" />
                                                </div>
                                                <div className="header-bottom-right-dropInfo2-img-info">
                                                    <p className='header-bottom-right-dropInfo2-img-p2'>({mediatype.count})</p>
                                                    <p className='header-bottom-right-dropInfo2-img-p'>{mediatype.name} </p>
                                                </div>
                                            </div>
                                        </Link>
                                      
                                    </SwiperSlide>
                                ))}




                            </Swiper>
                        </div>














                    </div>




                    <div className="header-bottom-right-dropInfo2-lines-topics">
                        <div className="header-bottom-right-dropInfo2-line"></div>
                        <p className="header-bottom-right-dropInfo2-topics">
                            مواضيع قد تهمك
                        </p>
                        <div className="header-bottom-right-dropInfo2-line"></div>
                    </div>
                    <div className="header-bottom-right-dropInfo2-topicsInfo">

                        {info && info.categories.map((category) => (
                            <Link onClick={() => {
                                closeWrapper(); 
                                document.querySelector(".overlayLight").style.display = "none";
                            }} to={`/category/${category.id}`} className='header-bottom-right-dropInfo2-topic'>  {category.name} </Link>

                        ))}

                    </div>

                </div>
            )}
        </>
    )
}
