
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllDocsAction, getDocsAttrAction, getDocsFilterAction } from '../../../../redux/actions/Media/Writings/DocsActions';

export default function DocsHook() {

    const dispatch=useDispatch();
    const notFound=useRef()
    const [activePage,setActivePage]=useState(1);

const getData=async()=>{
    setActivePage(1)

    if(localStorage.getItem("docsAttr")){
        let catID=JSON.parse(localStorage.getItem("docsAttr"));
        await dispatch(getDocsAttrAction(catID,1,12))
    }else{
        await dispatch(getAllDocsAction(1,12))

    }
}

useEffect(()=>{
    getData();
},[localStorage.getItem("docsAttr")])
useEffect(()=>{
    let allPages=document.querySelectorAll(".paginateGeneral .page-item")
    if(allPages[1]){
        allPages[1].classList.add("active");

    }

},[localStorage.getItem("articlesNewsAttr")])
const res=useSelector(state=> state.DocsReducer.docsData);
const resFilter=useSelector(state=> state.DocsReducer.docsAttrData);

let docsData=[];
let filterAttrs=[];
let filterCates=[]
try {
    if(localStorage.getItem("docsAttr")){
        if(resFilter){
            if(resFilter.data){
                docsData.push(resFilter.data)
                if(resFilter.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
                
            }
            if(resFilter.addetionals){
                 filterAttrs=[...resFilter.addetionals.filterable_attrebutes]
                 filterCates=[...resFilter.addetionals.filterable_categories]
                 
            }
        }
    }else{
        if(res){
            if(res.data){
                console.log(res);
                docsData.push(res.data)
                if(res.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(res.addetionals){
                 filterAttrs=[...res.addetionals.filterable_attrebutes]
                 filterCates=[...res.addetionals.filterable_categories]
                 
            }
        }
    }
 
} catch (e) {
    console.log(e);
}

let pageCount=0;

try {
    if(localStorage.getItem("docsAttr")){
        if(resFilter){
            if(resFilter.pagination){
                pageCount=Math.ceil(resFilter.pagination.total_pages)
            }
        }
    }else{
        if(res){
            if(res.pagination){
                pageCount=Math.ceil(res.pagination.total_pages)
            }
        }
    }

} catch (error) {}
const handleChangePage= async(count)=>{
        setActivePage(count)
    if(localStorage.getItem("docsAttr")){
        let cateID=JSON.parse(localStorage.getItem("docsAttr"))
        await dispatch(getDocsAttrAction(cateID,count,12))
    }else{
        await dispatch(getAllDocsAction(count,12))

    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 
}

return [docsData,filterAttrs,filterCates,getData,notFound,handleChangePage,pageCount,activePage]
}


