
import { useGetData } from "../../../hooks/useGetData";
import { GET_SEARCH_Filter_DATA, GET_SEARCH_MORE, GET_SEARCH_RESULT } from "../../type";
const getSearchResultAction =  (word)=> async (dispatch)=>{
    try{
        const response=await useGetData(`/public-search?search=${word}&lang=ar`);
        dispatch({
            type:GET_SEARCH_RESULT,
            payload:response,
        })
    }
    catch(e){
        dispatch({
            type:GET_SEARCH_RESULT,
            payload:e.response
        })
    }


}

const getSearchResultMoreAction =  (word,limit,page,id)=> async (dispatch)=>{
    try{
        const response=await useGetData(`/public-search?search=${word}&lang=ar&per_page=${limit}&page=${page}&media_type_id=${id}`);
        dispatch({
            type:GET_SEARCH_MORE,
            payload:response,
        })
    }
    catch(e){
        dispatch({
            type:GET_SEARCH_MORE,
            payload:e.response
        })
    }


}

const getSearchMoreFilterAction =  (word,mediaID,cateID,limit,page)=> async (dispatch)=>{
    try{
        const response=await useGetData(`/public-search?search=${word}&lang=ar&media_type_id=${mediaID}&category_id=${cateID}&per_page=${limit}&page=${page}`);
        dispatch({
            type:GET_SEARCH_Filter_DATA,
            payload:response,
        })
    }
    catch(e){
        dispatch({
            type:GET_SEARCH_Filter_DATA,
            payload:e.response
        })
    }


}

export {getSearchResultAction,getSearchResultMoreAction,getSearchMoreFilterAction}