import React, { useEffect, useRef } from 'react'
import './OnePoemContainer.css'
import '../../Responsive/PoemsRes.css'
import MediaHeader from '../../MediaHeader'
import OnePoemHook from '../../../../Logic/Media/Writings/Poems/OnePoemHook'
import { useParams } from 'react-router-dom'
import Spinner from './../../../Utility/Spinner';
export default function OnePoemContainer() {
  const convertVideo=(video)=>{
    console.log(video);
    try {
      const videoID = video.match(/(?:youtu\.be\/|v=)([^&/]+)/)[0];
      const videoSrc = `https://www.youtube.com/embed/${videoID}`;
      return videoSrc
    } catch (error) {
      return video
    }
  }
  const { id } = useParams();
  const [onePoemData,loading] = OnePoemHook(id);
  let input, output;
  if (onePoemData[0] && onePoemData[0].poem) {
    input = onePoemData[0].poem;
    // input="كن هادف /r كن هتا /l علي هدي /r مين هنا /l /b كانا معانا /r عادي جدا /l"
  
    // Replace custom breaks with real ones (new lines)
    // input = input
    //   .replace(/\/n|n\//g, '\n')
    //   .replace(/\/b|b\//g, '\n\n')
    //   .replace(/n\\/g, '\n')
    //   .replace(/b\\/g, '\n\n')
    //   .replace(/\\n/g, '\n')
    //   .replace(/\\b/g, '\n\n');
  
    const lines = input.split(/\n+/);
    console.log(lines);
    let rightLines = [];
    let leftLines = [];
  
    // Match all marker variations, including break markers
    const markerRegex = /\/r|r\/|\\r|r\\|\/l|l\/|\\l|l\\|\/b|b\/|\\b|b\\/g;
  
    lines.forEach((line) => {
      const trimmed = line.trim();
      if (!trimmed) {
        leftLines.push(`<br><br>`);
        rightLines.push(`<br><br>`);
        return;
      }
  
      let lastIndex = 0;
      let match;
  
      while ((match = markerRegex.exec(trimmed)) !== null) {
        const marker = match[0];
        const index = match.index;
  
        const chunk = trimmed.slice(lastIndex, index).trim();
  
        // Handle text chunk before the marker
        if (chunk) {
          if (/r/.test(marker)) {
            rightLines.push(`<div class="poem-line">${chunk}</div>`);
          } else if (/l/.test(marker)) {
            leftLines.push(`<div class="poem-line">${chunk}</div>`);
          }
        }
  
        // Handle break marker
        if (/b/.test(marker)) {
          leftLines.push(`<br><br>`);
          rightLines.push(`<br><br>`);
        }
  
        lastIndex = index + marker.length;
      }
  
      // Handle remaining text after the last marker
      const remaining = trimmed.slice(lastIndex).trim();
      if (remaining) {
        if (/r/.test(match?.[0])) {
          rightLines.push(`<div class="poem-line">${remaining}</div>`);
        } else if (/l/.test(match?.[0])) {
          leftLines.push(`<div class="poem-line">${remaining}</div>`);
        } else {
          rightLines.push(`<div class="poem-line">${remaining}</div>`);
        }
      }
    });
  
    output = `
      <div class="poem-flex-container">
        <div class="poem-left-column poem-column">
          ${leftLines.join('\n')}
        </div>
        <div class="poem-right-column poem-column">
          ${rightLines.join('\n')}
        </div>
     
      </div>
    `;
  }
  return (
    
     loading ? 
     <div style={{ marginTop:"300px" }}>
          <Spinner />
     </div>

     :

      <div className='onePoemContainer'>
      <div className='Container'>
        
      <MediaHeader  title={`قصيدة ${onePoemData[0]&&onePoemData[0].name}`} />
          <div className='onPoemItems'>
              <div className='onPoemContent'>
                      {
                          onePoemData[0]&&<p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: output }}></p>
                      }
              </div>
              <div className="onPoemContentImg">
                  <img src={onePoemData[0]&&onePoemData[0].sharepoint_image?onePoemData[0].image:"/assets/default/poems/Poem -inside.png"}  />
              </div>
          </div>
      </div>
      {onePoemData[0] && onePoemData[0].url && (
        <div className='onPoemVideo' id='videoPlay'>
          {/* <iframe
            src={onePoemData[0] &&onePoemData[0].url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
            referrerPolicy="no-referrer"
          ></iframe> */}
          <video controls>
            <source src={onePoemData[0] && onePoemData[0].url} type="video/mp4" />
          </video>
        </div>
      )}
      </div>
    
        
   
  )
}