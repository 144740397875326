import React, { useEffect, useState } from 'react'
import './OneArticleContainer.css'
import MediaHeader from '../../MediaHeader'
import { useNavigate, useParams } from 'react-router-dom';
import ArticlesDetailsHook from '../../../../Logic/Media/Writings/Articles/ArticlesDetailsHook';
import Spinner from '../../../Utility/Spinner';
import { ReactReader } from 'react-reader';


export default function OneArticleContainer() {
  const navigate=useNavigate();
  const {id}=useParams();
  const [oneArticleData,check,ele,getData]=ArticlesDetailsHook(id)
  const [book, setBook] = useState("/assets/book.epub");
  const [info, setInfo] = useState(null);
  const [bookId, setBookID] = useState(null);
  const [showReader, setShowReader] = useState(false);
  const bookItem ="/assets/book.epub";
  useEffect(() => {
    if (bookItem) {
      console.log("boo",bookItem);
        setBook(bookItem);
        setInfo(info);
        setBookID(id)
        setShowReader(true); 
    } 
}, []); 
console.log("data",oneArticleData[0]);
  return (
    // <div className='oneArticleContainer'>
    //    {showReader && (
    //             <div className="reader-container">
          
    //                 <ReactReader
    //                     url={bookItem} 
    //                     title={
    //                       <div>
    //                         <h3>{oneArticleData[0]?oneArticleData[0].newspaper:""} </h3>
    //                         <p >{oneArticleData[0]?oneArticleData[0].name:""}</p>
    //                       </div>
    //                     }
    //                     showToc={true}
    //                     getRendition={(rendition) => {
    //                         rendition.themes.default({
    //                             '::selection': {
    //                                 background: 'rgba(255,255,0, 0.3)'
    //                             }
    //                         });
    //                         rendition.flow('scrolled-doc'); 

    //                     }}
                        
    //                     onClose={() => {
    //                         setShowReader(false);
    //                         navigate(-1)();
    //                     }}
    //                 />
    //             </div>
    //         )}
    // </div>
    <div>
      <div  className='display_article_iframe_block'>
      <iframe className='display_book_iframe'
          src={`https://popeshenoudatest.msol.dev/api/open-epub?media_id=${bookId}`}
          
          allowFullScreen
      ></iframe>
      </div>
    </div>
  )
}
