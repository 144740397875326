import React, { useState,useRef } from 'react'
import { useParams } from 'react-router-dom'
import CatagorySayingWriteCard from '../Categories/CatagorySayingWriteCard';
import CategoryHeader from '../CategoryHeader';
import DataByCategoryHook from '../../../Logic/Categories/DataByCategoryHook';
import './CategoryMoreContainer.css'
import '../../../Pages/Category/Responsive/CategoryRes.css'
import '../../../Pages/Category/Responsive/CategoryRes.css'
import CatagoryFilter from '../CategoryFilter'
import OneBook from '../../Books/OneBook';
import VisitsImg from '../../Visits/VisitsImg';
import CategoryVideoCard from '../Categories/CategoryVideoCard';
import CategorySoundCard from '../Categories/CategorySoundCard';
import SayingsPhotoCard from '../../Media/Writings/Sayings/SayingsPhotoCard';
import PoemsCard from '../../Media/Writings/Poems/PoemsCard';
import QuestionCard from '../../Media/Writings/Questions/QuestionCard';
import EventCard from '../../Home/EventCard';
import ArticlesCard from '../../Media/Writings/Articles/ArticlesCard';
import DocsCard from '../../Media/Writings/Docs/DocsCard';
import DataByCategoryMoreHook from '../../../Logic/Categories/DataByCategoryMoreHook';
import Paginate from '../../Utility/Paginate';
import SayingWriteCard from '../../Media/Writings/Sayings/SayingWriteCard';
import VisitsOverlay from '../../Visits/VisitsOverlay'
import DocOverlay from '../../Media/Writings/Docs/DocOverlay';
import CategoryVisitOverlay from '../CategoryVisitOverlay';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
export default function CategoryMoreContainer({title,data,pageCount,handleChangePage,cate}) {
    const {mediaID}=useParams();

    const [isOverlayVisible, setOverlayVisible] = useState(false);

     const handleImageClick = () => {
        setOverlayVisible(true);
    };

    const closeOverlay = () => {
        setOverlayVisible(false);
    };
    const [isOverlayVisibleDocs, setOverlayVisibleDocs] = useState(false);

    const [clickedImage, setClickedImage] = useState(null);
    const [clickedImageIndex, setClickedImageIndex] = useState(0);
    const [overlayImgs, setOverlayImgs] = useState([]);
    const [clickedCategory, setClickedCategory] = useState('');
    const handleImageClickDocs = (imgUrl, index, media, categoryValue) => {
        setClickedImage(imgUrl);
        setClickedImageIndex(index);
        setOverlayImgs(media);
        setClickedCategory(categoryValue);
        setOverlayVisibleDocs(true);
        document.body.style.overflow = "hidden";

    };
    

    const closeOverlayDocs = () => {
        setOverlayVisibleDocs(false);
        setClickedImage(null);
        document.body.style.overflow = "auto"; 

    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const handleImageClicked = (index) => {
        setCurrentIndex(index);
        console.log("ind",index);
        setOverlayVisible(true);
        
    };



const playersRef = useRef([]); // Array of refs for all players
const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);

const handlePlay = (index) => {
    // Pause any currently playing audio
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
        const previousPlayer = playersRef.current[currentPlayingIndex];
        if (previousPlayer && previousPlayer.audio.current) {
            previousPlayer.audio.current.pause();
        }
    }

    // Set the current playing index
    setCurrentPlayingIndex(index);
};

const handlePrev = (index) => {
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
        playersRef.current[prevIndex].audio.current.play();
        handlePlay(prevIndex);
    }
};

const handleNext = (index) => {
    const nextIndex = index + 1;
    if (nextIndex < data[0][0].value.length) { // Ensure it doesn't exceed the length
        playersRef.current[nextIndex].audio.current.play();
        handlePlay(nextIndex);
    }
};

  return (
    <div className='CategoryMoreContainer'>

<div className='Container'>
             {
                data[0]?
                mediaID=="8"?
                    <>
                        <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                        <div className="categoryRow">
                                <div className=' sayingsWriteItems'>
                                    {
                                        data[0][0].value&&data[0][0].value.map((item)=>{
                                            return(
                                                <SayingWriteCard img={  item.sharepoint_image? item.image:"/assets/default/written/insideWritten.png"}  desc={item.quote} name={item.ref} cate={item.name}  key={item.id} checked={item.sharepoint_image}/>
                                                )
                                        })
                                    }
                                </div>
                            <Paginate pageCount={pageCount} onPress={handleChangePage} />
                            
                        </div>
                    </>
            
                


                        
                
                    :mediaID=="1"?
                    <>
                        <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>

                        <div className="categoryBooksData">
                            <div key={`book-${Math.random() * 10}`} className="">

                            { data[0][0].value && 
                                Array.from({ length: Math.ceil( data[0][0].value.length / 3) }, (_, i) => (
                                <div key={`book-group-${i}`} style={{position:"relative"}}>
                                    <div>               
                                    { data[0][0].value.slice(i * 3, i * 3 + 3).map((subbook, subIndex) => (
                                    <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                                    ))}

                                    <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                                    </div>

                     


                                </div>
                                ))
                            }

                        </div>
                        </div>
                        <div className=' categoryBooksDataMoreResIpad' style={{display:"none"}}>
                            <div key={`book-${Math.random() * 10}`} className="">
                                { data[0][0].value && 
                                    Array.from({ length: Math.ceil( data[0][0].value.length / 2) }, (_, i) => (
                                    <div key={`book-group-${i}`} style={{position:"relative"}}>
                                        <div className='items'>               
                                        { data[0][0].value.slice(i * 2, i * 2+ 2).map((subbook, subIndex) => (
                                        <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                                        ))}

                                <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                            </div>




    </div>
    ))
}

</div>

                        </div>
                        <div className=' categoryBooksDataMoreResPhone' style={{display:"none"}}>
                            <div key={`book-${Math.random() * 10}`} className="">
                                { data[0][0].value && 
                                    Array.from({ length: Math.ceil( data[0][0].value.length / 1) }, (_, i) => (
                                    <div key={`book-group-${i}`} style={{position:"relative"}}>
                                        <div className='items'>               
                                        { data[0][0].value.slice(i * 1, i * 1+ 1).map((subbook, subIndex) => (
                                         <OneBook key={subbook.id} src={subbook.sharepoint_image?subbook.image:"/assets/default/books/Book - inside.png"} info={subbook.name} book={subbook.url} book_id={subbook.id} />
                                        ))}

                                <img className="books-head-stand1" src="/assets/books-5.png" alt="stand" />

                            </div>




                                </div>
                                ))
                            }

                            </div>

                        </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} />
                        </>
                    
                    
                    :mediaID=="3"?
                        <>
                            <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                            <div className="categoryRow">
                                <div className='categoryVideoData'>
                            {
                                data[0][0].value&&data[0][0].value.map((item)=>{
                                    return(
                                        <CategoryVideoCard  url={item.url} desc={item.name} src={item.id} key={item.id}  />         
                                    )
                                })
                            }
                                </div>
                                <Paginate pageCount={pageCount} onPress={handleChangePage} />
                            </div>
                        </>
                    
                     :mediaID=="2"?
                        <>
                            <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                            <div className="categoryRow">
                                <div className='visits-imgs-cont categoryGalleryData'>
                            {
                                data[0][0].value&&data[0][0].value.map((item,i)=>{
                                    return(
                                        <VisitsImg info={item.description} src={item.image!=null?item.image:`/assets/gallery-1.png`} key={item.id}   handleImageClick={() => handleImageClicked(i)} />               
                                        
                                        )
                                })
                            }
                                </div>
                                <Paginate pageCount={pageCount} onPress={handleChangePage} />
                                {isOverlayVisible && (
                                    <CategoryVisitOverlay closeOverlay={closeOverlay} visitsImgs={data[0][0]} currentIndex={currentIndex}  />
                                        )}

                            </div>
                        </>
                    
                    :mediaID=="4"?
                        <>
                            <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                            <div className="categoryRow">
                                <div className='articleItems categoryArticleData'>
                                    
                            {
                                data[0][0].value&&data[0][0].value.map((item)=>{
                                    return(
                                        <ArticlesCard  img={item.sharepoint_image?item.image:"/assets/default/articles/Articles - inside.png"}  title={item.name} desc={item.description} src={`/articles/${item.id}`} key={item.id} />                     
                                        
                                        )
                                })
                            }
                                </div>
                                <Paginate pageCount={pageCount} onPress={handleChangePage} />

                            </div>
                        </>
                    
                    
                    
                    :mediaID=="5"?
                        <>
                            <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                            <div className="categoryRow">
                                <div className=' categorySoundData'>
                                        
                                        {
                                        data[0][0].value&& [... data[0][0].value].reverse().map((item, index) => {
                                            return (
                                                
                                                        <CategorySoundCard  
                                                        key={item.id}
                                                        index={index}
                                                        audio={item.url}
                                                        title={item.name}
                                                        isPlaying={currentPlayingIndex === index}
                                                        hasPrev={index > 0} // Only show prev if there's a previous item
                                                        hasNext={index < data[0][0].value.slice(0, 6).length - 1} // Ensure next is calculated after slice
                                                        onPlay={handlePlay}
                                                        onPrev={handlePrev}
                                                        onNext={handleNext}
                                                        ref={(el) => (playersRef.current[index] = el)} />        
                                                                
                                                    )
                                            })
                                        }
                                    </div>
                                <Paginate pageCount={pageCount} onPress={handleChangePage} />

                            </div>
                         </>
                    :mediaID=="10"?
                    <>
                    <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                   <div className="categoryRow">
                       <div className='sayingsPhotoItems categoryPhotoSayingsData'>
                           
                   {
                       data[0][0].value&&data[0][0].value.map((item)=>{
                           return(
                            item.sharepoint_image&&
                            <SayingsPhotoCard src={item.image!=null?item.image:`/assets/media/writings/Sayings/img1.png`} key={item.id}/>
                               )
                       })
                   }
                       </div>
                       <Paginate pageCount={pageCount} onPress={handleChangePage} />

                   </div>
               </>
                    

                    :mediaID=="12"?
                        <>
                                <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                            <div className="categoryRow">
                                <div className=' PoemsItems categoryPoemsData'>
                                    
                            {
                                data[0][0].value&&data[0][0].value.map((item)=>{
                                    return(

                                        <PoemsCard src={`/poems/${item.id}`} title={item.name} desc={item.poem} key={item.id} />                                            
                                        )
                                })
                            }
                                </div>
                                <Paginate pageCount={pageCount} onPress={handleChangePage} />

                            </div>
                        </>
                    
                    :mediaID=="14"?
                    <>
                            <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                        <div className="categoryRow">
                            <div className='questionsItems categoryQuestionsData'>
                                
                        {
                            data[0][0].value&&data[0][0].value.map((item)=>{
                                return(

                                    <QuestionCard ques={item.question} answer={item.answer} audio={item.audio} key={item.id} checkAudio={item.sharepoint_uodated} />     
                                        
                                    )
                            })
                        }
                            </div>
                            <Paginate pageCount={pageCount} onPress={handleChangePage} />

                        </div>
                    </>
                    :mediaID=="15"?
                    <>
                        <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                        <div className="categoryRow">
                            <div className='eventsItems categoryHappenData'>
                                        
                                        {
                                        data[0][0].value&&data[0][0].value.map((item)=>{
                                                return(
                                                    <EventCard date={item.date} title={item.name} desc={item.event} img={item.image!=null?item.image:`/assets/media/writings/article.png`}  key={item.id}/>             
                                                    
                                                    )
                                            })
                                        }
                                </div>
              
                            <Paginate pageCount={pageCount} onPress={handleChangePage} />

                        </div>
                    </>
                    :mediaID=="16"?
                    <>
                        <CategoryHeader cate={data[0][0]&&data[0][0].key}  count={data[0][0].count}/>
                    <div className="categoryRow">
                        <div className='docsItems categoryDocsData'>
                                    
                                    {
                                        data[0][0].value&&data[0][0].value.map((item,index)=>{
                                            return(
            
    
                                                <React.Fragment key={item.id}>
                                                <DocsCard src={item.image!=null?item.image:`/assets/media/writings/docs/docs.png`} key={item.id} handleImageClick={() => handleImageClickDocs(item.image, index, data[0][0].value, cate)} />
                                                {isOverlayVisibleDocs && (
                                                        <DocOverlay
                                                        closeOverlay={closeOverlayDocs}
                                                        img={clickedImage}
                                                        cate={clickedCategory}
                                                        imgs={overlayImgs}
                                                        initialSlide={clickedImageIndex}
                                                    />                                                
                                                     )}
                                                </React.Fragment>                                                   
                                                )
                                        })
                                    }
                            </div>
                        <Paginate pageCount={pageCount} onPress={handleChangePage} />

                    </div>
                  </>
                    :""
                
                :""
              
             }
        </div>
        <ToastContainer />

    </div>
  )
}
