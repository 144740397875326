import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function CategoryVideoCard({url,desc,src}) {
  const navigate=useNavigate();
  const convertVideo=(video)=>{
    let videoID;
    let videoSrc;
    if(video){
     videoID = video.match(/(?:youtu\.be\/|v=)([^&/]+)/)[1];
     videoSrc = `https://www.youtube.com/embed/${videoID}`;
    }

    return videoSrc
  }
  return (
    <div className='categoryVideoCard'>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <iframe
            className="video-video-frame"
            src={convertVideo(url)}
            title={`YouTube video player`}
            allow="encrypted-media"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ pointerEvents: 'none' }} // Prevents iframe from intercepting 
           
            
        />
     <div
       onClick={() => navigate(`/displayvideo/${src}`)}
        style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0)' // Transparent overlay
        }}
    />
          <p dangerouslySetInnerHTML={{__html:desc}} className='categoryVideoCardDesc'></p>
      </div>
    </div>
  )
}
