
import React, { useEffect } from 'react'
import './SayingFilterPage.css'
import SayingsBG from '../../../../components/Media/Writings/Sayings/SayingsBG'
import SayingsWriteContainer from '../../../../components/Media/Writings/Sayings/SayingsWriteContainer'
import SayingsPhotoContainer from '../../../../components/Media/Writings/Sayings/SayingsPhotoContainer'
import SayingFilterContainer from '../../../../components/Media/Writings/Sayings/SayingFilter/SayingFilterContainer'
import SayingFilterion from '../../../../components/Media/Writings/Sayings/SayingFilter/SayingFilterion'
import SayingsDetailsHook from '../../../../Logic/Media/Writings/Sayings/SayingsDetailsHook'
import { useParams } from 'react-router-dom'
import SayingsPhotoFilterion from '../../../../components/Media/Writings/Sayings/SayingFilter/SayingsPhotoFilterion'

export default function SayingFilterPage() {
  const [sayingsWrittenData,sayingsPhotoData,sayingsWrittenFilter,sayingsPhotoFilter,pageCountSP,pageCountSW,sayingsPhotoAttrs,sayingsWrittenAttrs,handleChangePageSW,handleChangePageSP,getData,notFound,notFoundWrite]=SayingsDetailsHook();
  const {id}=useParams();
  window.addEventListener('hashchange', function (e) {
    e.preventDefault();
});

window.onload = function () {
    window.history.replaceState(null, null, ' '); // Clear hash if any
};
useEffect(() => {
  window.scrollTo(0, 0); // Ensure scroll starts at the top
}, []);
  return (
    <div className='sayingFilterPage'>
        <div className='Container'>
            {
              id==11?<SayingFilterion cates={sayingsWrittenFilter} attrs={sayingsWrittenAttrs} />
              :id==10?<SayingsPhotoFilterion cates={sayingsPhotoFilter} attrs={sayingsPhotoAttrs} />:""
            }
        </div>

            <SayingFilterContainer />
    </div>
  )
}
