import React from 'react'
import { Link } from 'react-router-dom'

export default function TopicLeft({data}) {
  return (
    <div className="topic-left-side">
    <p className='topic-left-p'>ايضاً في مثل هذا اليوم</p>

    {
        data[0]?data[0].map((item)=>{
            return(
                <Link to={`/happen/${item.id}`}>
                <div className="topic-left-headline-cont">
                    <div className="topic-left-headline">
                        <p className='topic-left-top-p'>{item.name}</p>
                        <p className='topic-left-mid-p' dangerouslySetInnerHTML={{__html:item.event}}></p>
                        <div className="visits-read-arrow">
                            <img src="/assets/redarrow.png" alt="" />
                            <p className='happen-bottom-read'>اقرأ المزيد</p>
                        </div>
                        <hr style={{color:"gray",opacity: 0.5}}/>
                        <p className='topic-left-bot-p'>{item.date}</p>
                    </div>
                    <div className="topic-right-headlineimg">
                        <img src={item.sharepoint_image?item.image:"/assets/default/happen/outSide.png"} alt="" />
                    </div>
                </div>
                <div className="topic-left-headline-cont-res" style={{display:"none"}}>
                    <p className='topic-left-top-p'>{item.name}</p>
                    <div style={{clear:"both"}}></div>
                    <div className='topicContent'>
                        <div className="topic-left-headline">
                            <p className='topic-left-mid-p' dangerouslySetInnerHTML={{__html:item.event}}></p>
                            <div className="visits-read-arrow">
                                <img src="/assets/redarrow.png" alt="" />
                                <p className='happen-bottom-read'>اقرأ المزيد</p>
                            </div>
                      
                        </div>
                        <div className="topic-right-headlineimg">
                            <img src={item.sharepoint_image?item.image:"/assets/default/happen/outside.png"} alt="" />
                        </div>
                    </div>
                    <hr style={{color:"gray",opacity: 0.1}}/>
                    <p className='topic-left-bot-p'>{item.date}</p>
                </div>
                </Link>
            )
        }):""
    }
</div>
  )
}
