import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, EffectFade, EffectCube, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import './DocsContainer.css'
export default function DocOverlay({closeOverlay,img,cate,imgs,initialSlide}) {
  const [activeIndex, setActiveIndex] = useState(initialSlide || 0);

  const ele=useRef()
  const handleZoom = (e) => {
    e.target.classList.toggle('zoomed');
    console.log('zoomed');
  };
  console.log(imgs);
  return (
    <div className="overlay" >
      <div className="overlay-wrapper">
          <img src="/assets/close.png" alt="" className="visits-overlay-cancel" onClick={closeOverlay} />
          <h2 className='overlayDocsImgCate'>{cate}</h2>
          <div className='overlayDocsContent'>
    
          <div className={`docs-front docs-arrow ${activeIndex === 0 ? "disabled" : ""}`}>
              <img src="/assets/happen-right.png" alt="" className="swiper-front" />
            </div>
          <Swiper

            spaceBetween={50}
            slidesPerView={1}
            navigation={{
                nextEl: ".docs-back",
                prevEl: ".docs-front",
            }}

            modules={[Navigation, Autoplay, Pagination, EffectCoverflow]}
            speed={2000}
            effect="coverflow"
            initialSlide={initialSlide}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // 👈 Track current index

            >
                {
                  imgs&&imgs.map((item,index)=>{
                    return(
                      <SwiperSlide key={index}>
                      <div className="overlayDocsImg"  >
                          <img src={item.image} class="zoomable"  ref={ele} onClick={handleZoom}
/>
                      </div>
                    </SwiperSlide>
                    )
                  })
                }
           
      
            </Swiper>

            <div className={`docs-back docs-arrow ${activeIndex === imgs.length - 1 ? "disabled" : ""}`}>
              <img src="/assets/happen-left.png" alt="" className="swiper-back" />
            </div>
    
       
          </div>


      </div>
</div>
  )
}
