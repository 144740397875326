import React from 'react'
import './SayingsPhoto.css'
import '../../Responsive/SayingsPhotoRes.css'
import MediaHeader from '../../MediaHeader'
import SayingsPhotoCard from './SayingsPhotoCard'
import SayingsHook from '../../../../Logic/Media/Writings/Sayings/SayingsHook';
import Spinner from '../../../Utility/Spinner';

export default function SayingsPhotoContainer() {
  const [sayingsWrittenDataLimited,sayingsPhotoDataLimited]=SayingsHook();

  return (
    <div className='sayingsPhoto' type="sayingPhoto" >
        <div className='Container'>
          <MediaHeader title="صور اقوال"  src="/media/10" />
          <div className='sayingsPhotoItems'>
            {
            sayingsPhotoDataLimited && Array.isArray(sayingsPhotoDataLimited[0])?sayingsPhotoDataLimited[0].map((item)=>{
              return(
                  
                    <SayingsPhotoCard src={item.image} key={item.id}/>
                  
               
              )
            }):<Spinner />
          }

          </div>
        </div>
    </div>
  )
}
