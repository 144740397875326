import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestionsAction, getQuestionsAttrAction, getQuestionsFilterAction } from '../../../redux/actions/Media/Writings/QuestionsActions';
export default function QuestionsHook() {
    const dispatch=useDispatch();
    const notFound=useRef()

    const [activePage,setActivePage]=useState(1);

//authReducer
const getData=async()=>{
    setActivePage(1)
    if (localStorage.getItem("qusAttr")) {
        const cateID = JSON.parse(localStorage.getItem("qusAttr"));
        await dispatch(getQuestionsAttrAction(10, 1,cateID))
        
    } else {       
           await dispatch(getAllQuestionsAction(10, 1));
    }
}

useEffect(()=>{
    getData();
},[localStorage.getItem("qusAttr")])
const res=useSelector(state=> state.QuestionReducer.questionsData);
const resFilter=useSelector(state=> state.QuestionReducer.questionsAttrData);


let questionsData=[];
let filterGroup=[]
let attrGroup=[]
try {
    if(localStorage.getItem("qusAttr")){
        if(resFilter){
            if(resFilter.data){
                questionsData.push(resFilter.data.media)
                if(resFilter.data.media.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(resFilter.addetionals){
                filterGroup=[...resFilter.addetionals.filterable_categories]
                attrGroup=[...resFilter.addetionals.filterable_attrebutes]
            }
        }
    }else{
        if(res){
            if(res.data){
                questionsData.push(res.data.media)
                if(res.data.media.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(res.addetionals){
                filterGroup=[...res.addetionals.filterable_categories]
                attrGroup=[...res.addetionals.filterable_attrebutes]
            }
        }
    }

} catch (e) {
    console.log(e);
}
let pageCount=0;

try {
    if(localStorage.getItem("qusAttr")){
        if(resFilter){
            if(resFilter.pagination){
                pageCount=Math.ceil(resFilter.pagination.total_items/10)
            }
        }
    }else{
        if(res){
            if(res.pagination){
                pageCount=Math.ceil(res.pagination.total_items/10)
            }
        }
    }

} catch (error) {}


const handleChangePage= async(count)=>{
    setActivePage(count)

    if(localStorage.getItem("qusAttr")){
        let cateID=JSON.parse(localStorage.getItem("qusAttr"))
        await dispatch(getQuestionsAttrAction(10,count,cateID))
    }else{
        await dispatch(getAllQuestionsAction(10,count))
    }
    window.history.replaceState(null, null, ' ')
    window.scrollTo(0, 0); 
}

return [questionsData,filterGroup,attrGroup,handleChangePage,pageCount,getData,notFound,activePage]
}


