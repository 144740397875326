import React from 'react'
import './BreadCrumb.css'
import { Link } from 'react-router-dom'
export default function BreadCrumb({data,value}) {
    let arr=[];
    if(data){
        data.map((item)=>{
            arr.push(item.id)
        })
        localStorage.setItem("headerRoute",JSON.stringify(arr.reverse()))
    }

  return (
    <div className='breadCrumbContainer'>
        <div className='Container'>
            <ul className="breadCrumb">
                 {
                    data?data.map((item)=>{
                        return(
                            <li><Link to={`/category/${item.id}`}>{item.name}</Link></li>
                        )
                    }):""
                }
            </ul>
        </div>
    </div>

  )

}
