import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function HappenTopic({img,date,desc,id,check}) {

  return (
    <div  className="happen-topic">
      <div className='happen-rel'>
        {
          check&&<p className='happen-happen-p-abs'>حدث في مثل هذا اليوم</p>
        }
     </div>
     <Link to={`/happen/${id}`}><img src={img} alt="" /></Link>
    <div className="happen-bottom-topic">
    <Link to={`/happen/${id}`}><p className='happen-bottom-topic-date'>{date}</p></Link>
    <Link to={`/happen/${id}`}><p className='happen-bottom-topic-info' dangerouslySetInnerHTML={{__html:desc}}></p></Link>
        <div className="happen-read-arrow">
            <img src="./assets/redarrow.png" alt="" />
            <Link to={`/happen/${id}`} className='happen-bottom-read'>اقرأ المزيد</Link>
        </div>
    </div>
</div>
  )
}
