
import React, { useEffect, useRef,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllArticlesAction, getArticlesAttrAction, getArticlesFilterAction } from '../../../../redux/actions/Media/Writings/ArticlesActions';
export default function ArticlesHook() {
    const dispatch=useDispatch();
    const notFound=useRef()
//authReducer
const getData=async()=>{
    if(localStorage.getItem("articlesAttr")){
        let cateID=JSON.parse(localStorage.getItem("articlesAttr"))
        await dispatch(getArticlesAttrAction(cateID))
    }else{
        await dispatch(getAllArticlesAction())
    }

}
useEffect(()=>{
    getData();
},[])
useEffect(()=>{
    getData();
},[localStorage.getItem("articlesAttr")])
const res=useSelector(state=> state.ArticlesReducer.articlesData);
const resFilter=useSelector(state=> state.ArticlesReducer.articlesAttr);

let articlesData=[];
let filterGroup=[];
let attrGroup=[];
try {
    if(localStorage.getItem("articlesAttr")){
        if(resFilter){
            if(resFilter.data){
                articlesData.push(resFilter.data)
                if(resFilter.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(resFilter.addetionals){
                filterGroup=[...resFilter.addetionals.filterable_categories]
                attrGroup=[...resFilter.addetionals.filterable_attrebutes]
            }
        }
    }else{
        if(res){
            if(res.data){
                articlesData.push(res.data)
                if(res.data.length==0){
                    if(notFound.current){
                        notFound.current.style.display="block"
                    }
                }else{
                    if(notFound.current){
                        notFound.current.style.display="none"
                    }
                 

                }
            }
            if(res.addetionals){
                filterGroup=[...res.addetionals.filterable_categories]
                attrGroup=[...res.addetionals.filterable_attrebutes]

            }
        }
    }

} catch (e) {
    console.log(e);
}


return [articlesData,filterGroup,attrGroup,notFound,getData]
}


