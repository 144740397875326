
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDataAction } from '../../redux/actions/AuthAction';
import { getUserInfoAction } from '../../redux/actions/Profile/ProfileActions';
export default function UserInfoHook() {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [loading,setLoading]=useState(true);

//authReducer
const getData=async()=>{
    setLoading(true)
    await dispatch(getUserInfoAction())
    setLoading(false)

}
useEffect(()=>{
    if(localStorage.getItem("token")){
        getData();

    }
},[])
const res=useSelector(state=> state.ProfileReducer.userInfo);


let userInfo=[];
try {
    if(res){
        if(res.data){
            userInfo.push(res.data)
            localStorage.setItem("user",JSON.stringify(res.data))
            
        }
    }
 
} catch (e) {
    console.log(e);
}

return [userInfo,loading]
}


