import React, { useEffect, useRef, useState } from 'react'
import SectionHeader from '../Utility/SectionHeader'
import 'swiper/css';
import 'swiper/css/navigation';
import './Responsive/HomeRes.css'
import './Sayings.css'
import HomeHook from '../../Logic/Home/HomeHook';
import { Link, useLocation } from 'react-router-dom';

import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import  { Navigation, EffectFade } from "swiper/modules";
import SayingWriteCardOverlay from '../Media/Writings/Sayings/SayingWriteCardOverlay';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SayingShareContainer from '../Media/Writings/Sayings/SayingShareContainer';


export default function Sayings() {
  const [mediaData,meditationsData,sayingsData,eventsData]=HomeHook();

  
  const [isEnd, setIsEnd] = useState(false); 
  const [isBeginning, setIsBeginning] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); 
  const handleSwiperEvents = (swiper) => {
    setCurrentIndex(swiper.activeIndex); 
    setIsEnd(swiper.isEnd);
    setIsBeginning(swiper.activeIndex <= 1); 
  };

  // for more read
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descRef = useRef(null);
  const [isOverlayCard, setOverlayCard] = useState(false);
  const [isOverlayDots, setIsOverlayDots] = useState(false);
  const [isOverlayBlockShare, setOverlayBlockShare] = useState(false);
  const [imageMore, setImgMore] = useState();
  const [descMore, setDescMore] = useState();
  const [nameMore, setNameMore] = useState();
  const op=useRef();

  const handleCardOverlay=()=>{
    setOverlayCard(true)
    const header = document.querySelector('.header');
    header.style.zIndex = '100';
    document.querySelector('.downloadApp').style.zIndex = '-1';
    // op.current.toggle(false);
    document.body.style.overflow = 'hidden';



  }
  const handleCardOverlayClose=()=>{
    setOverlayCard(false)
    const header = document.querySelector('.header');
    header.style.zIndex = '1000';
    document.body.style.overflow = 'visible';
    document.querySelector('.downloadApp').style.zIndex = '1';

  }

  // useEffect(() => {
  //   // Check if the description is overflowing
  //   if (descRef.current) {
  //     setIsOverflowing(
  //       descRef.current.scrollHeight > descRef.current.clientHeight
  //     );
  //   }
  // }, [desc]);

  
  const handleOverlayToggle = (e) => {
      op.current.toggle(e);
    setIsOverlayDots((prev) => !prev);
    const header = document.querySelector('.header');
    header.style.zIndex = '10000';

};

const openShareBlock = () => {
    document.body.classList.add("no-scroll");
    setOverlayBlockShare(true);
    setIsOverlayDots(false)
    op.current.toggle(false);
    const header = document.querySelector('.header');
    header.style.zIndex = '100';
    document.querySelector('.downloadApp').style.zIndex = '-1';
    document.body.style.overflow = 'hidden';
};
const closeShareBlock=()=>{
  document.body.classList.remove("no-scroll");
  setOverlayBlockShare(false)
  const header = document.querySelector('.header');
  header.style.zIndex = '1000';
  document.querySelector('.downloadApp').style.zIndex = '1';
  document.body.style.overflow = 'visible';
}
  // Copy Data
  const copyData = async (desc,name,cate) => {
    try {

        const plainTextDesc = desc.replace(/<[^>]+>/g, ""); 
        const message = `\n\n"${plainTextDesc}"\n\n- ${name} (${cate})`;
        await navigator.clipboard.writeText(message);
        toast.success("تم النسخ بنجاح");
        console.log(message);
    } catch (error) {
        toast.error("عذرا ..قم بالمحاولة لاحقا");
        console.error("Failed to copy:", error);
        console.log("no");

    }
};
  // get current path
  const location = useLocation();
  const currentPath = `${window.location.origin}${location.pathname}${location.search}`;
  return (
    
    <div className='sayings'>
        <SectionHeader title="اقوال" subtitle="البابا شنوده" />
        <Swiper
        navigation={{
          nextEl: ".arrow-front",
          prevEl: ".arrow-back",
        }}
        onSlideChange={(swiper) => handleSwiperEvents(swiper)}
        onInit={(swiper) => handleSwiperEvents(swiper)}
        modules={[Navigation, EffectFade]}
        effect="fade"
        className="mySwiper"
      >
        {sayingsData[0]
          ? sayingsData[0].map((item, index) => (
              <SwiperSlide className="SwiperSlide" key={index}>
                <div className="SwiperSlideItem one"></div>
                <div className="SwiperSlideItem two"></div>
                <div className="SwiperSlideItem three">
                  <div className="sliderItems">
                    <div className="sliderContent">
                      <h2>{item.name}</h2>
                      <img
                        src="/assets/Sayings/q1.png"
                        alt="quote"
                        className="q1 quote"
                      />
                      {/* <p
                        className="sliderContentDesc"
                        dangerouslySetInnerHTML={{
                          __html: item.quote,
                        }}
                      ></p> */}
                        <p
                          ref={descRef}
                          className={`sayingWriteCardDesc ${isExpanded ? "expanded" : ""}`}
                          dangerouslySetInnerHTML={{ __html: item.quote }}
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: isExpanded ? "unset" : 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        ></p>
                        <div style={{clear:"both"}}></div>

                        {
                            <div className='moreWriteCard' dir='rtl'>
                              <img src='/assets/moreWC.png' alt='writeCard' onClick={
                               ()=>{ handleCardOverlay()
                                setDescMore(item.quote);
                                setNameMore(item.name);
                                setImgMore(item.sharepoint_image?item.image:"/assets/default/written/insideWritten.png")
                              }
                                
                                } />
                           
                            </div>
                        }
                      <img
                        className="sign"
                        src="/assets/Sayings/sign.png"
                        alt="sign"
                      />
                    </div>
                    <div className="sliderBg">
                      <img
                        alt="siderBackground"
                        src={
                          item.sharepoint_image != null
                            ? item.image_url
                            : "/assets/default/written/insideWritten.png"
                        }
                      />
                    </div>
                    <img src="/assets/dots.png" alt=""   onClick={(e)=>{
                      handleOverlayToggle(e);
                      setDescMore(item.quote);
                      setNameMore(item.name);
                      setImgMore(item.sharepoint_image?item.image:"/assets/default/written/insideWritten.png")

                      }} className='dotsWrite' />
                      <OverlayPanel ref={op}>
                      <button className="shareButton btnAction" onClick={openShareBlock} >
                        <span>مشاركة</span>
                        <img src='/assets/Share.png'/>
                      </button> 
                      <img src='/assets/line.png' alt='line'/>
                      <button className="shareButton btnAction" onClick={()=>copyData(descMore,nameMore,"ااقوال بابا شنوده الثالث")}>
                        <span>نسخ</span>
                        <img src='/assets/copy.png'/>
                      </button> 
                      </OverlayPanel>
                  </div>
                </div>
              </SwiperSlide>
            ))
          : ""
          
          }
      </Swiper>

      <div
  className={`arrow-back arrows ${currentIndex === 0 ? "disabled" : ""}`}
  style={{
    opacity: currentIndex === 0 ? 0.5 : 1, 
    pointerEvents: currentIndex === 0 ? "none" : "auto",
  }}
>
  <img src="/assets/arrow-prev.png" alt="Previous" />
</div>

<div
  className={`arrow-front arrows ${
    currentIndex === sayingsData[0]?.length - 1 ? "disabled" : ""
  }`}
  style={{
    opacity: currentIndex === sayingsData[0]?.length - 1 ? 0.5 : 1,
    pointerEvents:
      currentIndex === sayingsData[0]?.length - 1 ? "none" : "auto",
  }}
>
  <img src="/assets/arrow-next.png" alt="Next" />
</div>
        <Link to="/media/11" className='more_btn '>المزيد</Link>
        <div dir='rtl' className='moreSayingHome'>
        {
          isOverlayCard&& <SayingWriteCardOverlay closeOverlay={handleCardOverlayClose} img={imageMore} desc={descMore} name={nameMore} cate={"اقوال بابا شنوده الثالث"} />
        }
           {
          isOverlayBlockShare&& <SayingShareContainer closeOverlay={closeShareBlock} currentPath={currentPath} img={imageMore} desc={descMore} name={nameMore} cate={"اقوال بابا شنوده الثالث"} height="70%" />
           }

        </div>
      
      <ToastContainer />
    </div>
  )
}
