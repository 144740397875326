
import React, { useState } from 'react'
import MediaHeader from '../../../MediaHeader'
import './DocsFilterContainer.css'
import { useParams } from 'react-router-dom'
import DocsCard from '../DocsCard'
import DocsDetailsHook from '../../../../../Logic/Media/Writings/Docs/DocsDetailsHook'
import Paginate from '../../../../Utility/Paginate'
import DocsFilter from '../DocsFilter'
import DocsHook from '../../../../../Logic/Media/Writings/Docs/DocsHook'
import DocOverlay from '../DocOverlay'
export default function DocsFilterContainer() {
  const {id}=useParams();
  const  [docsDetailsData,title,pageCount,handleChangePage]=DocsDetailsHook(id)
  const  [docsData,filterAttrs,filterCates,getData]=DocsHook();
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const [clickedImage, setClickedImage] = useState(null);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [overlayImgs, setOverlayImgs] = useState([]);
  const [clickedCategory, setClickedCategory] = useState('');
  const handleImageClick = (imgUrl, index, media, categoryValue) => {
    setClickedImage(imgUrl);
    setClickedImageIndex(index);
    setOverlayImgs(media); // <- set the list of images for the Swiper
    setClickedCategory(categoryValue); // <- if you need to show category
    setOverlayVisible(true);
  }

  const closeOverlay = () => {
    setOverlayVisible(false); 
    setClickedImage(null); 
  };
  return (
    <div className='docsFilterContainer'>

          <div className='Container'>  
           <MediaHeader title={title} />
            <div className='docsItems'>
            {
                docsDetailsData && Array.isArray(docsDetailsData[0])?docsDetailsData[0].map((item,index)=>{
                return(
                  <React.Fragment key={item.id}>
                  <DocsCard src={item.image}  handleImageClick={() => handleImageClick(item.image, index,docsDetailsData[0], item.value)} />
                  {isOverlayVisible && (
                         <DocOverlay
                         closeOverlay={closeOverlay}
                         img={clickedImage}
                         cate={title}
                         imgs={overlayImgs}
                         initialSlide={clickedImageIndex}
                       />  
                  )}
                </React.Fragment>
                )
              }):""
            }

            </div>
          </div>
        {
          <Paginate  pageCount={pageCount} onPress={handleChangePage}/>
        }

      </div>

  )
}
